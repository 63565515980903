import './App.css';
import React, {Component} from 'react';
import LeftSideBar from "./components/app/LeftSideBar/LeftSideBar";
import {TopMenu} from "./components/app/TopMenu/TopMenu";
import {Layout} from "antd";
import MainWindow from "./components/app/MainWindow";

const { Content } = Layout;

export default class App extends Component {

  render() {
    return (
        <Layout style={{height:'100vh'}}>
          <LeftSideBar location={this.props.location} history={this.props.history}/>
          <Layout className="site-layout">
            <TopMenu/>

            <Content
                className="site-layout-background"
                style={{
                  margin: '16px 16px 0px 16px',
                  padding: '16px 16px 16px 16px',
                  minHeight: 280,
                }}
            >
              <MainWindow location={this.props.location} />
            </Content>
          </Layout>
        </Layout>
    )
  }
}
