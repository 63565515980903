export const apiLogin = "/auth/login";
export const routeHome = "/dashboard";

export const getRouteList = (title) => `/${title}/list`;
export const getApiList = (title, filter) => `/${title}/index?${filter}`;
export const getApiListExport = (title, filter) => `/${title}/export?${filter}`;

export const getRouteDetailsPath = (title) => `/${title}/:id`;
export const getRouteDetails = (title, id) => `/${title}/${id}`;
export const getApiDetails = (title, id) => `/${title}/${id}`;

export const getRouteCreate = (title) => `/${title}/create`;
export const getApiCreate = (title) => `/${title}/store`;

export const complaintHome  = "/home/:mobile";
export const getApiSearchByMobileCode = (title,mobile) => `/${title}/search_by_mobile/${mobile}`;


export const getRouteUpdatePath = (title) => `/${title}/update/:id`;
export const getRouteUpdate = (title, id) => `/${title}/update/${id}`;
export const getRouteDelete = (title, id) => `/${title}/delete/${id}`;

export const getRouteCopy = (title, id, code) => `/${title}/create?search=${code}&id=${id}`;
export const getApiUpdate = (title, id) => `/${title}/update/${id}`;
export const getComplaintsBy = (title, id) => `/${title}/${id}/complaint`;

/*_add_from_here*/