import React from "react";
import {Pie} from "@ant-design/charts";
import {Card, Col, Tag} from "antd";

export class PieChart extends React.Component {

    render() {
        const {title, counts} = this.props;
        let data = [
            {
                type: 'Pending',
                value: counts.pending,
            },
            {
                type: 'Escalated',
                value: counts.escalated,
            },
            {
                type: 'Exceeded',
                value: counts.exceeded,
            },
            {
                type: 'Resolved',
                value: counts.resolved,
            },
        ];
        let config = {
            appendPadding: 10,
            data: data,
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            color: ['#d6b027', '#c31e32', '#d90000', '#47c120'],
            label: {
                type: 'spider',
                labelHeight: 28,
                content: '{name}\n{percentage}',
            },
            interactions: [{type: 'element-selected'}, {type: 'element-active'}],
        };

        return <Card><Col>
            <Tag className={"chart-tag"} color="green">{title}</Tag>
            <Pie {...config} />
        </Col></Card>;
    }
}