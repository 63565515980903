import React from "react";

import {Tabs} from "antd";

import {SearchComplaint} from "./SearchComplaint";
import {OutletUpdatePage} from "./OutletUpdatePage";
import {ComplaintCreate} from "./ComplaintCreate";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";

const { TabPane } = Tabs;
export class ComplaintCreatePage extends React.Component{



    constructor(props) {
        super(props);
        this.state = {
            copy: false,
        };

        CrudBloc.instance.clearList(MenuNames.complaint.lower);
        CrudBloc.instance.clearList(MenuNames.outlet.lower);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            const outlet = new URLSearchParams(this.props.location.search).get("outlet");
            const complaint = new URLSearchParams(this.props.location.search).get("complaint");

            if(outlet || complaint){
                this.setState({copy: true});
            }else{
                this.setState({copy:false});
            }
        }
    }

    onTabChange = (key) =>{
        const {history } = this.props;

        let pathname = this.props.location.pathname;
        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set("key", key);
        history.push({
            pathname: pathname,
            search: searchParams.toString(),
        });
    }

    render() {

        let key = new URLSearchParams(this.props.location.search).get("key")
        if(!key){
            key = "1";
        }

        return (
            <Tabs type="card" defaultActiveKey={key} activeKey={key} onChange={this.onTabChange}>
                <TabPane tab="Search Complaint/Outlet" key="1">
                    <SearchComplaint {...this.props}/>
                </TabPane>
                <TabPane tab="Update Outlet" key="2">
                    <OutletUpdatePage {...this.props} />
                </TabPane>
                <TabPane tab="New Complaint" key="3">
                    <ComplaintCreate copy {...this.props} />
                </TabPane>
            </Tabs>

        );
    }
}
