import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import AuthBloc from "../../bloc/AuthBloc";
import {Col, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {FormSkeleton} from "../shared/FormSkeleton";
import {IsLoading} from "../shared/IsLoading";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {root_cause_typeFilterParams} from "../root_cause_type/RootCauseTypeConstants";
import {categoryFilterParams} from "../category/CategoryConstants";
import {call_typeFilterParams} from "../call_type/CallTypeConstants";
import LocationBloc from "../../bloc/LocationBloc";
import {complain_typeFilterParams} from "../complain_type/ComplainTypeConstants";
import {getIdCall} from "../../utils/Utils";

export class ProgramOwnerCreatePage extends React.Component{

    formRef = React.createRef();
    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            name: InputFields.name,
            email: InputFields.email,
            mobile: InputFields.mobile,
            role: InputFields.role,
            root_cause_type_id: InputFields.root_cause_type_id,
            call_type_id: InputFields.call_type_id_optional,
            complain_type_id: InputFields.complain_type_id_optional,
            category_ids: InputFields.category_ids,
            status: InputFields.status
        };

        if(this.props.edit){
            this.prepareEdit()
        }else{
            this.prepareCreate()
        }
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.root_cause_type.lower, root_cause_typeFilterParams)
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.category.lower, categoryFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.call_type.lower, call_typeFilterParams);
    }

    isEditOrCopy = () => {
        return (this.props.copy || this.props.edit);
    }
    isEdit = () => {
        return this.props.edit;
    }
    prepareEdit = () => {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("ProgramOwner Update");
        CrudBloc.instance.getDetails(id, MenuNames.program_owner.lower)
        const history = this.props.history;
        this.UpdateResponseSubscription = CrudBloc.instance.updateResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.program_owner.lower));
                    CrudBloc.instance.clearUpdateResponseData();
                }
            },
        });
    }
    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.program_owner.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new ProgramOwner");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.program_owner.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }

    componentDidMount() {
        this.selectCalltypeSubscription = LocationBloc.instance.selectedCallType.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.complain_type.lower);
                    CrudBloc.instance.getList({call_type_id: x, status: 1, limit: 100}, MenuNames.complain_type.lower, complain_typeFilterParams)
                }
            },
        });
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("ProgramOwner Update");
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new ProgramOwner");
            const history = this.props.history;
            CrudBloc.instance.createResponse.subscribe({
                next(x) {
                    console.log(x);
                    if(x) {
                        history.push(getRouteList(MenuNames.program_owner.lower));
                        CrudBloc.instance.clearCreateResponseData();
                    }
                },
                error(err) { console.error('something wrong occurred: ' + err); },
                complete() { console.log('done'); }
            });
        }
    }

    componentWillUnmount() {
        this.UpdateResponseSubscription?.unsubscribe();
        this.createResponseSubscription?.unsubscribe();
        this.selectCalltypeSubscription.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        values['category_ids'] = (values['category_ids'] ?? "" ).toString();
        if(this.props.edit) {
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.program_owner.lower);
        } else {
            CrudBloc.instance.createNew(values, MenuNames.program_owner.lower);
        }
    };

    render() {
         return (
            <IsLoading name={MenuNames.program_owner.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.program_owner}
                    builder = {(snapshot) => {
                        

                        return   !this.props.edit ||  snapshot.data ?
                            <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish}
                                  layout="vertical" className="create-form-full">
                                <Row gutter={16}>
                                    <Col span={12}>
                            <InputText
                                values={this.state.name}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : undefined}/>
                                    </Col>
                                    <Col span={12}>
                            <InputText
                                values={this.state.email}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.email.name] : undefined}/>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                            <InputText
                                values={this.state.mobile}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>
                                    </Col>
                                    <Col span={12}>
                            <InputText
                                values={this.state.role}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.role.name] : undefined}/>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.root_cause_typeList}
                                builder = {(snapshotRootCauseType) => {
                                    console.log(snapshotRootCauseType.data);
                                    return  <InputSelect
                                        values={this.state.root_cause_type_id}
                                        options={snapshotRootCauseType.data ? snapshotRootCauseType.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.root_cause_type_id.name] : undefined}
                                    />
                                }}/>
                                    </Col>
                                    <Col span={12}>
                                    <InputSelect
                                        values={this.state.status}
                                        options={InputFieldOptions.status}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                                    />
                                    </Col>
                                </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.call_typeList}
                                        builder={(snapshotCallType) => {
                                            return <InputSelect
                                                disabled={this.state.disableEdit}
                                                menuName={MenuNames.call_type.lower}
                                                onSelect={(id) => {
                                                    LocationBloc.instance.onSelectLocation(MenuNames.call_type.upper, id);
                                                    this.formRef.current?.setFieldsValue({complain_type_id: undefined});
                                                }}
                                                values={this.state.call_type_id}
                                                options={snapshotCallType.data ? snapshotCallType.data.list : []}
                                                value={this.isEditOrCopy() && snapshot.data ? getIdCall(snapshot.data, this.state.call_type_id.name) : undefined}
                                            />
                                        }}/>
                                </Col>
                                <Col span={12}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.complain_typeList}
                                        builder={(snapshotComplainType) => {
                                            return <InputSelect
                                                disabled={this.state.disableEdit}
                                                menuName={MenuNames.complain_type.lower}
                                                values={this.state.complain_type_id}
                                                options={snapshotComplainType.data ? snapshotComplainType.data.list : []}
                                                value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.complain_type_id.name] : undefined}
                                            />
                                        }}/>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <BlocBuilder
                                        subject={CrudBloc.instance.categoryList}
                                        builder={(snapshotCategory) => {
                                            return <InputSelect
                                                disabled={this.state.disableEdit}
                                                menuName={MenuNames.category.lower}
                                                multiple={true}
                                                onSelect={(id) => {

                                                    let value = this.formRef.current?.getFieldValue('category_ids');

                                                    if (value.length > 0)
                                                        LocationBloc.instance.onSelectLocation(MenuNames.category.upper, value);
                                                    //this.formRef.current?.setFieldsValue({brand_ids: undefined});
                                                }}
                                                values={this.state.category_ids}
                                                options={snapshotCategory.data ? snapshotCategory.data.list : []}
                                                value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.category_ids.name] : undefined}
                                            />
                                        }}/>
                                </Col>

                            </Row>

                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshot) => {
                                    
                                    return  <TextX text={snapshot.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}