import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const distributorQueryFilterParams = () => distributorFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const distributorFilterParams = [
  FilterInputItems.id,
  FilterInputItems.name,
  FilterInputItems.region_id,
  FilterInputItems.area_id,
  FilterInputItems.territory_id,
  FilterInputItems.town_id,
  FilterInputItems.town_dms_code,
  FilterInputItems.status
];

export const distributorListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'Contact Info',
    field: 'contact',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town DMS Code',
    field: 'town_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  // {
  //   title: 'Created At',
  //   field: 'created_at',
  //   type: tableContentType.DATE_TIME,
  // },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const distributorDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'Contact Info',
    field: 'contact',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

