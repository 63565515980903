import * as rxjs from "rxjs";
import axiosWrapper from "../utils/AxiosWrapper";
import {BASEURL, MenuNames} from "../utils/Constants";
import _ from 'lodash';
import Utils, {prepareFilterParamsWithDate, prepareUrlWithFilter} from "../utils/Utils";
import {
    getApiCreate,
    getApiDetails,
    getApiList,
    getApiUpdate,
    getComplaintsBy, getRouteDelete,
    getRouteDetails,
    getRouteUpdate
} from "../utils/RouterUtils";
import ErrorBloc from "./ErrorBloc";
import AuthBloc from "./AuthBloc";
import LoadingBloc from "./LoadingBloc";
import {getItem, isItemExist, saveItem} from "../utils/LocalStorage";
import moment from "moment";

export default class CrudBloc {

    static instance = new CrudBloc();

    constructor(){

        for (const key of Object.keys(MenuNames)) {
            //console.log_sms(key + " -> " + MenuNames[key])
            this[`${key}List`] = new rxjs.BehaviorSubject({list:[], total: 0});
            this[`${key}`] = new rxjs.BehaviorSubject(null);
        }

        this.createResponse = new rxjs.BehaviorSubject(null);
        this.updateResponse = new rxjs.BehaviorSubject(null);

    }

    getDetails(id, name){
        LoadingBloc.instance.start(name);
        this.clearDetails(name);
        axiosWrapper
            .get(BASEURL+ getApiDetails(name, id))
            .then((response) => {
                this.prepareDetails(response, name);
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    getComplaintsBy(id, name){
        LoadingBloc.instance.start(name);
        axiosWrapper
            .get(BASEURL+ getComplaintsBy(name, id))
            .then((response) => {
                let list = _.cloneDeep(response.data.data);
                this[`${MenuNames.complaint.lower}List`].next({list: this.prepareList(list, MenuNames.complaint.lower), total: response.data.message.split("#")[1]})
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    updateFields = (e, name) => {
        switch (name){
            case MenuNames.region.lower:
            case MenuNames.region_manager.lower:
                e.region_title = e.region?.title;
                break;
            case MenuNames.area.lower:
                e.region_title = e.region?.title
                break;
            case MenuNames.area_manager.lower:
                e.area_title = e.area?.title
                e.region_title = e.area?.region?.title;
                break;
            case MenuNames.program_owner.lower:
                e.root_cause_type_title = e.root_cause_type?.title;
                e.call_type_title = e.call_type?.title;
                e.complain_type_title = e.complain_type?.title;
                e.category_title = e.categories?.reduce((acm, cur) => [...acm, cur.title+ ", "], []);
                break;
            case MenuNames.territory.lower:
                e.region_title = e.area?.region?.title;
                e.area_title = e.area?.title;
                break;
            case MenuNames.territory_manager.lower:
                let territories = e.territories;
                if(territories != null && territories.length > 0) {
                    e.region_title = territories[0].territory?.area?.region?.title;
                    e.area_title = territories[0].territory?.area?.title;
                    e.territory_title = territories[0].territory.title;
                }
                break;
            case MenuNames.territory_manager_location.lower:
                e.region_title = e.territory?.area?.region?.title;
                e.area_title = e.territory?.area?.title;
                e.territory_title = e.territory?.title;
                break;
            case MenuNames.town.lower:
                e.region_title = e.territory?.area?.region?.title;
                e.area_title = e.territory?.area?.title;
                e.territory_title = e.territory?.title;
                break;
            case MenuNames.thana.lower:
                e.region_title = e.town?.territory?.area?.region?.title;
                e.area_title = e.town?.territory?.area?.title;
                e.territory_title = e.town?.territory?.title;
                e.town_title = e.town?.title;
                break;
            case MenuNames.user.lower:
                e.user_group_title = e.user_group?.title;
                break;
            case MenuNames.root_cause.lower:
                e.root_cause_type_title = e.root_cause_type?.title;
                break;
            case MenuNames.complain_type.lower:
                e.call_type_title = e.call_type?.title;
                break;
            case MenuNames.brand.lower:
                e.category_title = e.category?.title;
                break;
            case MenuNames.product_sku.lower:
                e.category_title = e.brand?.category?.title;
                e.brand_title = e.brand?.title;
                break;
            case MenuNames.program_top_gun.lower:
                // e.year = `20${Utils.getYear(e.time)}`;
                // e.percentage = `${Utils.calculatePercentage(e.target, e.achievement)}`;
                // e.data = JSON.parse(e.data)
                // e.data.map(e => e.percentage = `${Utils.calculatePercentage(e.Target, e.Achievement)}`)
                // break
            case MenuNames.program_dosti.lower:
                e.year = `20${Utils.getYear(e.time)}`;
                e.percentage = `${Utils.calculatePercentage(e.target, e.achievement)}`
                e.data = JSON.parse(e.data)
                e.data.map(e => e.percentage = `${Utils.calculatePercentage(e.Target, e.Achievement)}`)
                break
            case MenuNames.program_perfect_store.lower:
                e.year = `20${Utils.getYear(e.time)}`;
                e.payout = `${e.face + e.hair + e.oral + e.menz}`;
                break;
            case MenuNames.program_timeline.lower:
                e.id = e.value;
                break;
            case MenuNames.distributor.lower:
                e.region_title = e.town?.territory?.area?.region?.title;
                e.area_title = e.town?.territory?.area?.title;
                e.territory_title = e.town?.territory?.title;
                e.town_title = e.town?.title;
                e.town_dms_code = e.town?.town_dms_code;
                break;
            case MenuNames.outlet.lower:

                if(e.basic != null){
                    e = e.basic;
                }

                e.region_title = e.town?.territory?.area?.region?.title;
                e.area_title = e.town?.territory?.area?.title;
                e.territory_title = e.town?.territory?.title;
                e.town_title = e.town?.title;
                e.town_id = e.town?.id;
                e.name = e.details?.name;
                e.bangla_name = e.details?.bangla_name;
                e.owner_name = e.details?.owner_name;
                e.address = e.details?.address;
                e.channel_title = e.details?.channel?.title;
                e.channel_id = e.details?.channel?.id;
                e.mobile = e.details?.mobile;
                e.mobile_2 = e.details?.mobile_2;
                e.mobile_3 = e.details?.mobile_3;
                e.distributor_type_id = ["","UBL","UCL", "Common"][e.town?.distributor_type_id];
                e.top_gun = this.prepareList(e?.top_gun, MenuNames.program_top_gun.lower);
                e.perfect_store = this.prepareList(e?.perfect_store, MenuNames.program_perfect_store.lower);
                e.dosti = this.prepareList(e?.dosti, MenuNames.program_dosti.lower);

                break;
            case MenuNames.complaint.lower:
                e.region_title = e.town?.territory?.area?.region?.title;
                e.area_title = e.town?.territory?.area?.title;
                e.territory_title = e.town?.territory?.title;
                e.town_title = e.town?.title;
                e.thana_title = e.thana?.title;
                e.town_id = e.town?.id;
                e.outlet_name = e.caller_info?.outlet_name;
                e.name = e.caller_info?.name;
                e.address = e.caller_info?.address;
                e.remark = this.getRemark(e.remarks, 1);
                e.tm_remark = this.getRemark(e.remarks, 2);
                e.tm_name = e.territory_manager?.name;
                e.complain_type_title = e.complain_type?.title;
                e.call_type_title = e.complain_type?.call_type?.title;
                e.root_cause_type_title = e.root_cause?.root_cause_type?.title;
                e.root_cause_title = e.root_cause?.title;
                e.top_gun = this.prepareList(e?.top_gun, MenuNames.program_top_gun.lower);
                e.perfect_store = this.prepareList(e?.perfect_store, MenuNames.program_perfect_store.lower);
                e.dosti = this.prepareList(e?.dosti, MenuNames.program_dosti.lower);
                e.distributor_type = ["","UBL","UCL", "Common"][e.distributor_type_id];

                //e.town_dms_code = e.town?.town_dms_code;
                break;
            case MenuNames.outbound_call.lower:
                e.outbound_call_category_title = e.outbound_call_category?.title;
                e.outbound_call_mode_title = e.outbound_call_category?.outbound_call_mode?.title;
                e.outbound_call_status_title = e.outbound_call_status?.title;
                e.outbound_change_request_title = e.outbound_change_request?.title;
                e.agent = e.agent?.name;
                break;
            default:

                break;
        }
        return e;
    }

    prepareDetails = (response, name) => {
        let e = response.data.data;
        e = this.updateFields(e, name);
        this[name].next(e)
    }

    canSavedOnLocal = (name) => {
        switch (name){
            case MenuNames.region.lower:
            case MenuNames.market_channel.lower:
            case MenuNames.call_type.lower:
            case MenuNames.store_type.lower:
            case MenuNames.category.lower:
            case MenuNames.root_cause_type.lower:
                return true;
            default:
                return false;
        }
    }

    getList(search, name, filterParam, dateRange){
        LoadingBloc.instance.start(name);
        let localData = false;
        if(search?.limit === 100 && this.canSavedOnLocal(name)){
            if(isItemExist(name, 60)){
                let data = getItem(name);
                let list = _.cloneDeep(data.data);
                this[`${name}List`].next({list: this.prepareList(list, name), total: data.message.split("#")[1]});
                localData = true;
            }
        }

        if(localData) {
            LoadingBloc.instance.end(name);
            return;
        }

        let filter = prepareUrlWithFilter(search, filterParam)
        if(dateRange){
            filter = prepareFilterParamsWithDate(filter, dateRange);
        }
        axiosWrapper
            .get(BASEURL+ getApiList(name, filter))
            .then((response) => {
                saveItem(name, response.data, moment());
                let list = _.cloneDeep(response.data.data);
                this[`${name}List`].next({list: this.prepareList(list, name), total: response.data.message.split("#")[1]})
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    prepareList = (list, name, action = ["View", "Edit"]) => {
        return list && Array.isArray(list)? list.map((e) => {
            e.detailsPath = getRouteDetails(name, e.id);
            e = this.updateFields(e, name);
            e.actions = [];
            action.forEach(action =>{
                switch(action){
                    case "View":
                        e.actions.push({
                            url: getRouteDetails(name, e.id),
                            title: "View",
                        })
                        break;
                    case "Edit":
                        e.actions.push({
                            url: getRouteUpdate(name, e.id),
                            title: "Edit",
                        })
                        break;
                    case "NewComplaint":
                        CrudBloc.instance.complaint.next(null);
                        CrudBloc.instance.outlet.next(null);

                        e.actions.push({
                            url: "/complaint/create?key=3&complaint="+e.id,
                            title: "New Complaint",
                        })
                        break;
                    case "UpdateOutlet":
                        e.actions.push({
                            url: "/complaint/create?key=2&outlet="+e.id,
                            title: "Update Outlet",
                        })
                        break;
                    case "NewComplaintOutlet":
                        CrudBloc.instance.complaint.next(null);
                        CrudBloc.instance.outlet.next(null);
                        e.actions.push({
                            url: "/complaint/create?key=3&outlet="+e.id,
                            title: "New Complaint",
                        })

                        break;
                    case "OutboundCall":
                        CrudBloc.instance.complaint.next(null);
                        CrudBloc.instance.outlet.next(null);
                        e.actions.push({
                            url: "/outbound_call/create?outlet="+e.id,
                            title: "Outbound Call",
                        })

                        break;
                    case "Delete":
                        e.actions.push({
                            url: getRouteDelete(name, e.id),
                            title: "Delete",
                        })
                        break;
                }
            });
            if(AuthBloc.instance.isManager()){
                e.actions = e.actions.slice(0,2)
            }
            else if(AuthBloc.instance.isEditor() === false || this.isProgram(name)){
                e.actions = e.actions.slice(0,1)
            }
            return e;
        }) : [];
    }

    createNew(values, name) {
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .post(BASEURL+ getApiCreate(name), values)
            .then((response) => {
                this.createResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });

    }
    update(id, values, name) {
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .put(BASEURL+ getApiUpdate(name, id), values)
            .then((response) => {
                this[name].next(response.data.data);
                this.updateResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            },)
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    updatePassword(id, values, name) {
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .put(BASEURL+ `/user/update_password/${id}`, values)
            .then((response) => {
                this[name].next(response.data.data);
                this.updateResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            },)
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    updatePost(id, values, name) {
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .post(BASEURL+ getApiUpdate(name, id), values)
            .then((response) => {
                //this[name].next(response.data.data);
                this.updateResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            },)
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }


    clearDetails(name) {
        this[name].next(null);
    }

    clearCreateResponseData(){
        this.createResponse.next(null);
    }
    clearUpdateResponseData(){
        this.updateResponse.next(null);
    }

    clearList(name) {
        this[`${name}List`].next({list: [], total: 0})
    }

    clearLists(names) {
        names.forEach(name => this[`${name}List`].next({list: [], total: 0}))

    }

    isProgram(name) {
        let res = false;
        [MenuNames.program_dosti, MenuNames.program_perfect_store, MenuNames.program_top_gun].forEach(e => {
            if(e.lower === name){
                res = true;
            }
        })
        return res;
    }

    searchByMobileCode(name, url){
        //LoadingBloc.instance.start(name);
        axiosWrapper
            .get(BASEURL+ url)
            .then((response) => {
                let list = _.cloneDeep(response.data.data);
                this[`${name}List`].next({list: this.prepareList(list, name, name === MenuNames.complaint.lower ? ["View", "NewComplaint" ] :  ["View", "NewComplaintOutlet", "UpdateOutlet", "OutboundCall"]), total: list.length});
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }



    getRemark = (list, type) => {
        if(list){
            for(let i=0;i<list.length;i++){
                if(list[i].type === type) {
                    return list[i].remark;
                }
            }
        }
    }
}

function getTitlesFromList(list){
    let res = "";
    list && list.forEach(e =>{
        if(res.length === 0)
            res += e.title;
        else
            res += ", "+e.title;

    })
    return res;
}