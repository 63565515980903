import {prepareInput} from "./Utils";
import {FileTextOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";

export const InputFields = {
    email: prepareInput({
        name: 'email',
        label:  'Email Address',
        placeholder: 'Email Address',
        rules:  [{ required: true,  type: "email", message: 'Please input your email!' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    name: prepareInput({
        name: 'name',
        label:  'Name',
        placeholder: 'Name',
        rules:  [{ required: true, message: 'Please input your name!' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    dsr_name:prepareInput({
        name: 'dsr_name',
        label:  'DSR Name',
        placeholder: 'DSR Name',
        rules:  [{ required: false, message: 'Please input' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    bangla_name: prepareInput({
        name: 'bangla_name',
        label:  'Bangla Name',
        placeholder: 'Name',
        rules:  [{ required: true, message: 'Please input !' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    owner_name: prepareInput({
        name: 'owner_name',
        label:  'Owner Name',
        placeholder: 'Name',
        rules:  [{ required: true, message: 'Please input !' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    }),
    password: prepareInput({
        name: 'password',
        label: 'Password',
        rules: [{ required: true, message: 'Please input your Password!' }],
        placeholder: 'Enter Password',
        type: "password",
        prefixIcon: <LockOutlined className="site-form-item-icon" />}),
    title: {
        name: 'title',
        label:  'Title',
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    value:{
        name: 'value',
        label:  'Value',
        placeholder: 'Value',
        rules:  [{ required: true, message: 'Please input value' }],
        prefixIcon: <FileTextOutlined />
    },
    user_group_id: {
        name: 'user_group_id',
        label: 'User Group',
        placeholder: 'Select user group',
        rules:  [{ required: true, message: 'Please select User group' }],
        options: []
    },
    region_id: {
        name: 'region_id',
        label: 'Region',
        placeholder: 'Select region',
        rules:  [{ required: true, message: 'Please select region!' }],
        options: []
    },
    area_id: {
        name: 'area_id',
        label: 'Area',
        placeholder: 'Select area',
        rules:  [{ required: true, message: 'Please select area!' }],
        options: []
    },
    territory_id: {
        name: 'territory_id',
        label: 'Territory',
        placeholder: 'Select territory',
        rules:  [{ required: true, message: 'Please select territory!' }],
        options: []
    },
    town_id: {
        name: 'town_id',
        label: 'Town',
        placeholder: 'Select town',
        rules:  [{ required: true, message: 'Please select town!' }],
        options: []
    },
    thana_id: {
        name: 'thana_id',
        label: 'Thana',
        placeholder: 'Select thana',
        rules:  [{ required: false, message: 'Please select thana!' }],
        options: []
    },
    distributor_id: {
        name: 'distributor_id',
        label: 'Distributor',
        placeholder: 'Select distributor',
        rules:  [{ required: true, message: 'Please select distributor!' }],
        options: []
    },
    territory_manager_id: {
        name: 'territory_manager_id',
        label: 'Territory Manager',
        placeholder: 'Select Territory Manager',
        rules:  [{ required: true, message: 'Please select Territory Manager' }],
        options: []
    },
    channel_id: {
        name: 'channel_id',
        label: 'Channel',
        placeholder: 'Select channel',
        rules:  [{ required: false, message: 'Please select channel!' }],
        options: []
    },
    role: {
        name: 'role',
        label: 'Role',
        placeholder: 'enter role',
        rules:  [{ required: true, message: 'Please select User Type' }],
        prefixIcon: <UserOutlined className="site-form-item-icon" />
    },

    call_type_id: {
        name: 'call_type_id',
        label:  'Call Type',
        placeholder: 'Call Type',
        rules:  [{ required: true, message: 'Please input' }],
        options: []
    },
    complain_type_id: {
        name: 'complain_type_id',
        label:  'Complain Type',
        placeholder: 'Complain Type',
        rules:  [{ required: true, message: 'Please input' }],
        options: []
    },
    call_type_id_optional: {
        name: 'call_type_id',
        label:  'Call Type',
        placeholder: 'Call Type',
        rules:  [{ required: false, message: 'Please input' }],
        options: []
    },
    complain_type_id_optional: {
        name: 'complain_type_id',
        label:  'Complain Type',
        placeholder: 'Complain Type',
        rules:  [{ required: false, message: 'Please input' }],
        options: []
    },
    store_type_id: {
        name: 'store_type_id',
        label:  'Store Type',
        placeholder: 'Store Type',
        rules:  [{ required: true, message: 'Please input' }],
        options: []
    },
    brand_ids: {
        name: 'brand_ids',
        label:  'Brands',
        placeholder: 'Brands',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    brand_id: {
        name: 'brand_id',
        label:  'Brand',
        placeholder: 'Brand',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    sku_ids: {
        name: 'sku_ids',
        label:  'SKUs',
        placeholder: 'SKU',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    category_id: {
        name: 'category_id',
        label:  'Category',
        placeholder: 'Category',
        rules:  [{ required: true, message: 'Please select' }],
        options: []
    },
    category_ids: {
        name: 'category_ids',
        label:  'Category',
        placeholder: 'Category',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    root_cause_type_id: {
        name: 'root_cause_type_id',
        label:  'Root Cause type',
        placeholder: 'e.g. ',
        rules:  [{ required: false, message: 'Please input' }],
        options: []
    },
    root_cause_id: {
        name: 'root_cause_id',
        label:  'Root Cause',
        placeholder: 'e.g. ',
        rules:  [{ required: false, message: 'Please input' }],
        options: []
    },
    program_owner_id: {
        name: 'program_owner_id',
        label:  'Program Owner #1',
        placeholder: 'e.g. ',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    program_owner_2_id: {
        name: 'program_owner_2_id',
        label:  'Program Owner #2',
        placeholder: 'e.g. ',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    program_owner_3_id: {
        name: 'program_owner_3_id',
        label:  'Program Owner #3',
        placeholder: 'e.g. ',
        rules:  [{ required: false, message: 'Please select' }],
        options: []
    },
    mobile: {
        name: 'mobile',
        label:  'Mobile Number',
        placeholder: '01...',
        type:"number",
        rules:  [{ required: true,  message: 'Please input mobile number!' }],
        prefixIcon: <FileTextOutlined />
    },
    mobile_2: {
        name: 'mobile_2',
        label:  'Mobile Number #2',
        placeholder: '01...',
        type:"number",
        rules:  [{ required: false,  message: 'Please input mobile number!' }],
        prefixIcon: <FileTextOutlined />
    },
    mobile_3: {
        name: 'mobile_3',
        label:  'Mobile Number #3',
        placeholder: '01...',
        type:"number",
        rules:  [{ required: false,  message: 'Please input mobile number!' }],
        prefixIcon: <FileTextOutlined />
    },
    outlet_name: {
        name: 'outlet_name',
        label:  "Shop Name",
        placeholder: 'Shop Name',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    call_record_id: {
        name: 'call_record_id',
        label:  "Call Record ID",
        placeholder: 'Call record ID',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    outlet_dms_code: {
        name: 'outlet_dms_code',
        label:  "DMS Code",
        placeholder: 'Code',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    town_dms_code: {
        name: 'town_dms_code',
        label:  "Town DMS Code",
        placeholder: 'Code',
        rules:  [{ required: false, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    complaint_status: {
        name: 'status',
        label:  "Status",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    sticker_status: {
        name: 'sticker_status',
        label:  "TCL Sticker Status",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    expires_at: {
        name: 'expires_at',
        label:  "Expires At",
        placeholder: 'Date',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    gender: {
        name: 'gender',
        label:  "Gender",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    nid: {
        name: 'nid',
        label:  "National ID",
        placeholder: 'NID Number',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    religion: {
        name: 'religion',
        label:  "Religion",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    nationality: {
        name: 'nationality',
        label:  "Nationality",
        placeholder: 'e.g Bangladeshi',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    contact: {
        name: 'contact',
        label:  "Contact information",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    address: {
        name: 'address',
        label:  "Address",
        placeholder: 'Address',
        rules:  [{ required: true, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    remark: {
        name: 'remark',
        label:  "Complaint Description",
        placeholder: 'Complaint Description',
        rules:  [{ required: true, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    tm_remark: {
        name: 'tm_remark',
        label:  "TM Follow-up",
        placeholder: 'Enter Description',
        rules:  [{ required: false, message: 'Please input' }],
        prefixIcon: <FileTextOutlined />
    },
    photo: {
        name: 'photo',
        label:  "Photograph",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    photo_nid: {
        name: 'photo_nid',
        label:  "National ID/Birth Certificate",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    wholesaler: {
        name: 'wholesaler',
        label:  "Is he a retailer/wholesaler?",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    dealership: {
        name: 'dealership',
        label:  "Is he a retailer/wholesaler?",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    name_company: {
        name: 'name_company',
        label:  "Company Name",
        placeholder: 'Title',
        rules:  [{ required: true, message: 'Please input ' }],
        prefixIcon: <FileTextOutlined />
    },
    status: {
        name: 'status',
        label:  "Status",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select status' }],
        prefixIcon: <FileTextOutlined />
    },

    distributor_type_id: {
        name: 'distributor_type_id',
        label:  "Distributor Type",
        placeholder: 'Select',
        rules:  [{ required: false, message: 'Please select' }],
        prefixIcon: <FileTextOutlined />
    },
    is_verified: {
        name: 'is_verified',
        label:  "Is Verified",
        placeholder: 'Select',
        rules:  [{ required: false, message: 'Please select' }],
        prefixIcon: <FileTextOutlined />
    },
    program: {
        name: 'program',
        label:  "Program",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select program' }],
        prefixIcon: <FileTextOutlined />
    },
    year: {
        name: 'year',
        label:  "Year",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select year' }],
        prefixIcon: <FileTextOutlined />
    },
    timeline: {
        name: 'time',
        label:  "Timeline",
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select timeline' }],
        prefixIcon: <FileTextOutlined />
    },
    outbound_call_mode_id: {
        name: 'outbound_call_mode_id',
        label: 'Outbound Call Mode',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select!' }],
        options: []
    },
    outbound_call_status_id: {
        name: 'outbound_call_status_id',
        label: 'Outbound Call Status',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select!' }],
        options: []
    },
    outbound_call_category_id: {
        name: 'outbound_call_category_id',
        label: 'Outbound Call Category',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select!' }],
        options: []
    },
    outbound_change_request_id: {
        name: 'outbound_change_request_id',
        label: 'Outbound Change Request',
        placeholder: 'Select',
        rules:  [{ required: true, message: 'Please select!' }],
        options: []
    },
};


export const resetFields = () =>  {
    Object.keys(InputFields).forEach(function(key) {
        console.log(key);
        if(key === 'status' || key === 'verified'){

        }else if(InputFields[key].value) {
            InputFields[key].value = "";
        }
    });
};