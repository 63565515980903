import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const sms_logQueryFilterParams = () => sms_logFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const sms_logFilterParams = [
  FilterInputItems.id,
  FilterInputItems.title,
  FilterInputItems.status,
];

export const sms_logListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Content',
    field: 'content',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile Number',
    field: 'mobile',
    type: tableContentType.TEXT,
  },
  {
    title: 'Result',
    field: 'result',
    type: tableContentType.TEXT,
  },
  {
    title: 'Sent At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

export const sms_logDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Title',
    field: 'title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

