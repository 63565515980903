import moment from 'moment';
import ListUtils from "./ListUtils";
import _ from 'lodash';

export const prepareInput = ({ name, label, placeholder, rules, options, value, prefixIcon, type}) => {
    return {
        name: name,
        label: label,
        placeholder: placeholder,
        rules: rules,
        options: options,
        value: value,
        prefixIcon: prefixIcon
    };
};

export const prepareUrlWithFilter = (search, listSearchModalParams) => {


    let filters = new URLSearchParams(search);

    let page = ListUtils.currentPage(filters.toString());
    let limit = ListUtils.currentLimit(filters.toString());

    //console.log_sms("LIMIT:" + limit);
    const newFilters = new URLSearchParams("?");
    listSearchModalParams.forEach(e => {
        if(filters.has(e.name)){
            newFilters.set(e.name,filters.get(e.name) )
        }
    })

    return `skip=${limit * (page -1)}&limit=${limit}&${newFilters}`;
}
export const prepareFilterParamsWithDate = (filters, between) => {
    let current = new Date();
    let now = moment(
        new Date(
            current.getFullYear(),
            current.getMonth(),
            current.getDate(),
            current.getHours(),
            current.getMinutes(),
            0,
            0
        )
    );

    if (
        moment(between.from).isSame(moment(between.to)) &&
        moment(between.from).diff(moment(now), "minute") === 0
    ) {
        return filters;
    } else {
        return `${filters}&from=${between.from}&to=${between.to}`;
    }
}
export default{

    prepareDropdownOptions(list) {
        return list.map(e => ({
          key: e.id,
          title: e.title,
          value: e.id
        }));
      },
    prepareDropdownOptionsName(list) {
        return list.map(e => ({
            key: e.id,
            text: e.name,
            value: e.id
        }));
    },
    // e.town === null || e.town.title === null ? "": e.town.title + ", Territory: "+
    // e.territory === null || e.territory.title === null ? "":e.territory.title,
    prepareDropdownOptionsTerritory(list) {
        return list.map(e => ({
            title: `${e.title}, Area: ${e.area.title}, Region: ${e.area.region.title}`,
            id: e.id
        }));
    },
    //=== null ? "" : e.title + (e.area && e.area.title ? ", Area: "+ e.area.title : "")
    prepareDropdownOptionsThana(list) {
        return list.map(e => ({
            key: e.id,
            text: e.title === null ? "" : e.title + (e.town && e.town.title ? ", Town: "+ e.town.title : ""),
            value: e.id
        }));
    },
      // showToast(title, description){
      //   return toast({
      //       type: "success",
      //       icon: "envelope",
      //       title: title,
      //       description: description,
      //       time: 800
      //   })
      // },
    formatNumber (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    getDefaultDateRange(from, to){
        let now = new Date();
        const currentTime = moment(
            new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                23,
                59,
                0,
                0
            )
        );
        return {
            from: from ? from: moment(currentTime).subtract(30, "days")
                //.utc()
                .format("YYYY-MM-DDTHH:mm:ss") + "Z",
            to: to ? to: moment(currentTime)
                //.utc()
                .format("YYYY-MM-DDTHH:mm:ss") + "Z",
        };
    },

    getDateRangeDuration(from, to){
        let start = moment(to, 'YYYY-MM-DDTHH:mm:ssZ');
        let end = moment(from, 'YYYY-MM-DDTHH:mm:ssZ');
        return  start.diff(end, 'days');
    },
    getYear(time){
        //console.log_sms(time)
        return time.split('-')[1];
    },
    getNumber(x) {
        return isNaN(x) ? 0: x;
    },
    calculatePercentage(target, achievement){
        return ((achievement/target) * 100).toFixed(2);
    },
    prepareTerritoryForComplaint(snapshotTerritory) {
        return snapshotTerritory.data ? snapshotTerritory.data.list.map((e) =>
        {
            e.title = `${e.area.region.title}, area: ${e.area.title}`
            return e;
        }) : [];
    },
    prepareThanaForComplaint(snapshotThana) {
        return snapshotThana.data ? snapshotThana.data.list.map((e) =>
        {
            e.title = `${e.title}, town: ${e.town.title}`
            return e;
        }) : [];
    }
}

export const getId = (e, k) => {

    if(e){
        if(e[k]){
            return e[k];
        }else{
            if(e.area){
                return getId(e.area, k);
            }
            else if(e.territory){
                return getId(e.territory, k);
            }
            else if(e.town) {
                return getId(e.town, k);
            }
            else if(e.call_type) {
                return getId(e.call_type, k);
            }
            else return null;
        }
    }else {
        return null;
    }
}

export const getIdCall = (e, k) => {

    if(e){
        if(e[k]){
            return e[k];
        }else{

            if(e.call_type) {
                return getId(e.call_type, k);
            }
            if(e.complain_type) {
                return getId(e.complain_type, k);
            }
            else return null;
        }
    }else {
        return null;
    }
}

export const getIdRootCause = (e, k) => {

    if(e){
        if(e[k]){
            return e[k];
        }else{

            if(e.root_cause_type) {
                return getId(e.root_cause_type, k);
            }
            if(e.root_cause_id) {
                return getId(e.root_cause, k);
            }
            else return null;
        }
    }else {
        return null;
    }
}

export const getTownDMSCode = (id, data) => {
    //console.log_sms(data, id)

    const town = _.filter(data, element => `${element.id}` === `${id}`);
    //console.log_sms(town)
    return town[0]?.town_dms_code;
}

export const getTown = (id, data) => {
    //console.log_sms(data, id)

    const town = _.filter(data, element => `${element.id}` === `${id}`);
    //console.log_sms(town)
    return town[0];
}