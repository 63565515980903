import React from "react";
import {Card, Col, Row} from "antd";
import {TextX} from "../shared/TextX";
import Images from "../../utils/Images";

export class ComplaintCount extends React.Component{


    getIcon = (status) => {
        switch (status){
            case 1:
                return Images.pending;
            case 2:
                return Images.pending;
            case 3:
                return Images.exceeded;
            case 4:
                return Images.exceeded;
            case 5:
                return Images.solved;
            case 6:
                return Images.total;
        }
    }

    getText = (status) => {
        switch (status){
            case 1:
                return "Pending";
            case 2:
                return "Re-Open";
            case 3:
                return "Exceeded";
            case 4:
                return "Escalated";
            case 5:
                return "Solved";
            case 6:
                return "Total";
        }
    }

    render() {
        const {status, count} = this.props
        return  <Card style={{ width: 200 }}>
            <Row type="flex" justify={"space-between"} gutter={10}>
                <Col span={10}>
                    <img src={this.getIcon(status)} width={32}/>
                </Col>
                <Col span={14}>
                    <Row><TextX text={`${count}`} size={16}/></Row>
                    <Row><TextX text={this.getText(status)} size={16}/></Row>
                </Col>
            </Row>
        </Card>
    }
}