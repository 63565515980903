import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, DatePicker, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {MenuNames} from "../../utils/Constants";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import {outbound_call_modeFilterParams} from "../outbound_call_mode/OutboundCallModeConstants";
import {outbound_call_statusFilterParams} from "../outbound_call_status/OutboundCallStatusConstants";
import {outbound_call_categoryFilterParams} from "../outbound_call_category/OutboundCallCategoryConstants";
import {outbound_change_requestFilterParams} from "../outbound_change_request/OutboundChangeRequestsConstants";
import {InputTextArea} from "../shared/FormComponent/InputTextArea";
import {FileTextOutlined} from "@ant-design/icons";
import moment from "moment";

export class OutboundCallCreatePage extends React.Component{

    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            outlet_dms_code: InputFields.outlet_dms_code,
            outbound_call_mode_id: InputFields.outbound_call_mode_id,
            outbound_call_status_id: InputFields.outbound_call_status_id,
            outbound_call_category_id: InputFields.outbound_call_category_id,
            outbound_change_request_id: InputFields.outbound_change_request_id,
            remark: {
                name: 'remark',
                label:  "Remark",
                placeholder: 'Remark',
                rules:  [{ required: true, message: 'Please input' }],
                prefixIcon: <FileTextOutlined />
            },
            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.getDetails(id, MenuNames.outbound_call.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuNames.outbound_call.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.outbound_call_mode.lower, outbound_call_modeFilterParams)
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.outbound_call_status.lower, outbound_call_statusFilterParams)
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.outbound_call_category.lower, outbound_call_categoryFilterParams)
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.outbound_change_request.lower, outbound_change_requestFilterParams)
    }

    componentDidMount() {
        if(this.props.edit){
            SettingsBloc.instance.setCurrentPageTitle("OutboundCall Update");
        }else {
            SettingsBloc.instance.setCurrentPageTitle("Create new OutboundCall");
            const history = this.props.history;
            this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
                next(x) {
                    if (x){
                        history.push(getRouteList(MenuNames.outbound_call.lower));
                        CrudBloc.instance.clearCreateResponseData();
                    }
                },
                error(err) {
                    console.error('something wrong occurred: ' + err);
                },
                complete() {
                    console.log('done');
                }
            });
        }
        const outlet = new URLSearchParams(this.props.location.search).get("outlet");
        console.log(outlet)
        if (outlet) {
            CrudBloc.instance.getDetails(outlet, MenuNames.outlet.lower);
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //
    //     if(prevProps !== this.props) {
    //         const outlet = new URLSearchParams(this.props.location.search).get("outlet");
    //         const outletPrev = new URLSearchParams(prevProps.location.search).get("outlet");
    //
    //         console.log_sms(outlet, outletPrev)
    //         if (outlet !== outletPrev && outlet) {
    //             CrudBloc.instance.getDetails(outlet, MenuNames.outlet.lower);
    //         }
    //     }
    // }

    isEdit = () => {
        return this.props.edit;
    }

    onFinish = (values) => {
        console.log('Success:', values);
        values['agent_id'] = AuthBloc.instance.user.value.id;
        values['starts_at'] = moment(values['starts_at'], "YYYY-MM-DDTHH:mm:sssZ").format("YYYY-MM-DD HH:mm:ss");
        values['ends_at'] = moment(values['ends_at'], "YYYY-MM-DDTHH:mm:sssZ").format("YYYY-MM-DD HH:mm:ss");
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.outbound_call.lower);
        }else{
            CrudBloc.instance.createNew(values, MenuNames.outbound_call.lower);
        }

    };

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
    }

    render() {
        return (
            <IsLoading name={MenuNames.outbound_call.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.outbound_call}
                    builder = {(snapshot) => {
                        //
                        //console.log_sms(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ?
                            <Form ref={this.formRef}
                                  name="control-ref"
                                  onFinish={this.onFinish}
                                  layout="vertical"
                                  className="create-form-full">

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <BlocBuilder
                                            subject = {CrudBloc.instance.outlet}
                                            builder = {(snapshotOutlet) => {
                                                console.log(snapshotOutlet)
                                                return   <InputText
                                                    disabled={this.state.disableEdit}
                                                    values={this.state.outlet_dms_code}
                                                    value={this.isEdit() && snapshot.data ? snapshot.data[this.state.outlet_dms_code.name] :
                                                        this.isEdit() && snapshotOutlet.data ? snapshotOutlet.data[this.state.outlet_dms_code.name] : undefined}/>
                                            }}/>

                                    </Col>
                                </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="starts_at" label="Starts At">
                                        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="ends_at" label="Ends At">
                                        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.outbound_call_modeList}
                                builder = {(snapshotCallMode) => {
                                    //console.log_sms(snapshotCallMode.data);
                                    return  <InputSelect
                                        values={this.state.outbound_call_mode_id}
                                        options={snapshotCallMode.data ? snapshotCallMode.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.outbound_call_mode_id.name] : undefined}
                                    />
                                }}/>
                                </Col>
                                <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.outbound_call_categoryList}
                                builder = {(snapshotCallCategory) => {
                                    //console.log_sms(snapshotCallCategory.data);
                                    return  <InputSelect
                                        values={this.state.outbound_call_category_id}
                                        options={snapshotCallCategory.data ? snapshotCallCategory.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.outbound_call_category_id.name] : undefined}
                                    />
                                }}/>
                                </Col>
                            </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.outbound_call_statusList}
                                builder = {(snapshotCallStatus) => {
                                    //console.log_sms(snapshotCallStatus.data);
                                    return  <InputSelect
                                        values={this.state.outbound_call_status_id}
                                        options={snapshotCallStatus.data ? snapshotCallStatus.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.outbound_call_status_id.name] : undefined}
                                    />
                                }}/>
                                    </Col>
                                    <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.outbound_change_requestList}
                                builder = {(snapshotChangeRequest) => {
                                    //console.log_sms(snapshotChangeRequest.data);
                                    return  <InputSelect
                                        values={this.state.outbound_change_request_id}
                                        options={snapshotChangeRequest.data ? snapshotChangeRequest.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.outbound_change_request_id.name] : undefined}
                                    />
                                }}/>
                                    </Col>
                                </Row>
                            <InputTextArea
                                disabled={this.state.disableEdit}
                                values={this.state.remark}
                                value={this.isEdit() && snapshot.data ? snapshot.data[this.state.remark.name] : undefined}/>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log_sms(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}