import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {program_top_gunDetailsAttributes, program_top_gunDetailsDataAttributes} from "./ProgramTopGunConstants";
import {MenuNames} from "../../utils/Constants";
import {Col, Row} from "antd";
import {Box} from "../shared/Box";
import {TextX} from "../shared/TextX";
import {TableList} from "../shared/table/TableList";

export class ProgramTopGunDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("ProgramTopGun Details")
        CrudBloc.instance.getDetails(id, MenuNames.program_top_gun.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.program_top_gun}
            builder = {(snapshot) => {
                
                return  <Row type={"flex"} gutter={32} style={{width: "95%"}}>
                    <Col span={8}>
                        <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={snapshot.data ? snapshot.data : null}
                    isFetching={false}
                    isFailed={false}
                    attributes={program_top_gunDetailsAttributes}
                    name={MenuNames.program_top_gun.lower}
                /></Col>
                    <Col span={8}>
                        <Box y={16}/>
                        <TextX text={"Payout Details"} size={20}/>
                        <Box y={16}/>
                        <TableList
                            history={this.props.history}
                            location={this.props.location}
                            list={snapshot.data ?  snapshot.data.data: []}
                            attributes={program_top_gunDetailsDataAttributes}
                            pagination={false}
                        /></Col>

                </Row>
            }}/>
    }
}

