import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const outbound_callQueryFilterParams = () => outbound_callFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const outbound_callFilterParams = [
  FilterInputItems.id,
  FilterInputItems.outlet_dms_code,
  FilterInputItems.outbound_call_category_id,
  FilterInputItems.outbound_call_mode_id,
  FilterInputItems.outbound_call_status_id,
  FilterInputItems.outbound_change_request_id,
  FilterInputItems.status,
];

export const outbound_callListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Remark',
    field: 'remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Category',
    field: 'outbound_call_category_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mode',
    field: 'outbound_call_mode_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Call Status',
    field: 'outbound_call_status_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Change Request',
    field: 'outbound_change_request_title',
    type: tableContentType.TEXT,
  },
  // {
  //   title: 'Status',
  //   field: 'status',
  //   type: tableContentType.STATUS,
  // },
  {
    title: 'Starts At',
    field: 'starts_at',
    type: tableContentType.TEXT,
  },
  {
    title: 'Ends At',
    field: 'ends_at',
    type: tableContentType.TEXT,
  },
  {
    title: 'Agent',
    field: 'agent',
    type: tableContentType.TEXT,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const outbound_callDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Remark',
    field: 'remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Category',
    field: 'outbound_call_category_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mode',
    field: 'outbound_call_mode_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Call Status',
    field: 'outbound_call_status_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Change Request',
    field: 'outbound_change_request_title',
    type: tableContentType.TEXT,
  },
  // {
  //   title: 'Status',
  //   field: 'status',
  //   type: tableContentType.STATUS,
  // },
  {
    title: 'Starts At',
    field: 'starts_at',
    type: tableContentType.TEXT,
  },
  {
    title: 'Ends At',
    field: 'ends_at',
    type: tableContentType.TEXT,
  },
  {
    title: 'Agent',
    field: 'agent',
    type: tableContentType.TEXT,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

