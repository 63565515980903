import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Row} from "antd";
import FilterPopup from "../shared/Filter/FilterPopup";
import {
    program_top_gunFilterParams,
    program_top_gunListAttributes,
    program_top_gunQueryFilterParams
} from "./ProgramTopGunConstants";
import {Box} from "../shared/Box";
import {MenuNames} from "../../utils/Constants";
import {ButtonExport} from "../shared/ButtonExport";

export class ProgramTopGunListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("ProgramTopGun List")
        CrudBloc.instance.getList(this.props.location.search, MenuNames.program_top_gun.lower, program_top_gunFilterParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(this.props.location.search, MenuNames.program_top_gun.lower, program_top_gunFilterParams);
        }
    }

    render() {
        return <div>
            <Row type="flex" justify={"space-between"}>
                {/*<ButtonCreateNew to={getRouteCreate(MenuNames.program_top_gun.lower)}/>*/}

                <ButtonExport search={this.props.location.search} name={MenuNames.program_top_gun.lower} param={program_top_gunFilterParams}/>

                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {program_top_gunFilterParams}
                    queryFilterParams = {program_top_gunQueryFilterParams()}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.program_top_gunList}
            builder = {(snapshot) => {
                
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={program_top_gunListAttributes}
                    name={MenuNames.program_top_gun.lower}
                />
            }}/>
        </div>
    }
}

