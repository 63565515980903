import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Card, Col, DatePicker, Descriptions, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {FormSkeleton} from "../shared/FormSkeleton";
import {regionFilterParams} from "../region/RegionConstants";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import {townFilterParams} from "../town/TownConstants";
import Utils, {getId, getIdCall, getIdRootCause, getTown} from "../../utils/Utils";
import {IsLoading} from "../shared/IsLoading";
import {thanaFilterParams} from "../thana/ThanaConstants";
import {call_typeFilterParams} from "../call_type/CallTypeConstants";
import {complain_typeFilterParams} from "../complain_type/ComplainTypeConstants";
import {store_typeFilterParams} from "../store_type/StoreTypeConstants";
import {categoryFilterParams} from "../category/CategoryConstants";
import {brandFilterParams} from "../brand/BrandConstants";
import {InputTextArea} from "../shared/FormComponent/InputTextArea";
import {product_skuFilterParams} from "../product_sku/ProductSKUConstants";
import moment from "moment";
import ComplaintBloc from "../../bloc/ComplaintBloc";
import {root_cause_typeFilterParams} from "../root_cause_type/RootCauseTypeConstants";
import {root_causeFilterParams} from "../root_cause/RootCauseConstants";
import {program_ownerFilterParams} from "../program_owner/ProgramOwnerConstants";
export class ComplaintCreate extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            call_record_id: InputFields.call_record_id,
            outlet_dms_code: InputFields.outlet_dms_code,
            name: InputFields.name,
            outlet_name: InputFields.outlet_name,
            address: InputFields.address,
            remark: InputFields.remark,
            tm_remark: InputFields.tm_remark,
            root_cause_type_id: InputFields.root_cause_type_id,
            root_cause_id: InputFields.root_cause_id,
            mobile: InputFields.mobile,
            mobile_2: InputFields.mobile_2,
            mobile_3: InputFields.mobile_3,
            region_id: InputFields.region_id,
            area_id: InputFields.area_id,
            territory_id: InputFields.territory_id,
            town_id: InputFields.town_id,
            town_dms_code: InputFields.town_dms_code,
            thana_id: InputFields.thana_id,
            distributor_id: InputFields.distributor_id,
            territory_manager_id: InputFields.territory_manager_id,
            distributor_type_id: InputFields.distributor_type_id,
            call_type_id: InputFields.call_type_id,
            complain_type_id: InputFields.complain_type_id,
            store_type_id: InputFields.store_type_id,
            brand_ids: InputFields.brand_ids,
            sku_ids: InputFields.sku_ids,
            category_ids: InputFields.category_ids,
            dsr_name: InputFields.dsr_name,
            complaint_status: InputFields.complaint_status,
            sticker_status: InputFields.sticker_status,
            expires_at: InputFields.expires_at,
            program_owner_id: InputFields.program_owner_id,
            program_owner_2_id: InputFields.program_owner_2_id,
            program_owner_3_id: InputFields.program_owner_3_id,
            disableEdit : AuthBloc.instance.isTM()
        };

        if(this.props.edit){
            this.prepareEdit()
        }else if(this.props.copy){
            this.prepareCopy()
        } else{
            this.prepareCreate()
        }

        ComplaintBloc.instance.complaintCurrentTM.next(null);
        CrudBloc.instance.clearLists([
            MenuNames.area.lower, MenuNames.territory.lower, MenuNames.town.lower, MenuNames.thana.lower,
            MenuNames.distributor.lower,
            MenuNames.call_type.lower, MenuNames.complain_type.lower, MenuNames.store_type.lower,
            MenuNames.category.lower, MenuNames.brand.lower, MenuNames.product_sku.lower, MenuNames.program_owner.lower
        ]);


        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 300}, MenuNames.territory.lower, territoryFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.call_type.lower, call_typeFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.store_type.lower, store_typeFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.category.lower, categoryFilterParams);
        //CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.distributor.lower, distributorFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.root_cause_type.lower, root_cause_typeFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.program_owner.lower, program_ownerFilterParams);


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            const complaint = new URLSearchParams(this.props.location.search).get("complaint");
            const complaintPrev = new URLSearchParams(prevProps.location.search).get("complaint");

            if(complaintPrev !== complaint && complaint){
                CrudBloc.instance.getDetails(complaint, MenuNames.complaint.lower);
            }

            const outlet = new URLSearchParams(this.props.location.search).get("outlet");
            const outletPrev = new URLSearchParams(prevProps.location.search).get("outlet");

            if(outlet !== outletPrev && outlet){
                CrudBloc.instance.getDetails(outlet, MenuNames.outlet.lower);
            }
        }
    }


    prepareEdit = () => {
        const {id} = this.props.match.params;
        const outlet = new URLSearchParams(this.props.location.search).get("outlet")
        const complaint = new URLSearchParams(this.props.location.search).get("complaint")
        SettingsBloc.instance.setCurrentPageTitle("Complaint Update");
        let edit  = this.props.edit;
        this.complaintSubcription = CrudBloc.instance.complaint.subscribe({
            next(x) {
                if (x){
                    //CrudBloc.instance.clearList(MenuNames.area.lower);
                    //CrudBloc.instance.getList({region_id: getId(x, InputFields.region_id.name ), status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                    //CrudBloc.instance.getList({area_id: getId(x, InputFields.area_id.name ), status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                    CrudBloc.instance.getList({territory_id: getId(x, InputFields.territory_id.name ), status: 1, limit: 100}, MenuNames.town.lower, townFilterParams);
                    CrudBloc.instance.getList({town_id: getId(x, InputFields.town_id.name ), status: 1, limit: 100}, MenuNames.thana.lower, thanaFilterParams);
                    CrudBloc.instance.getList({call_type_id: getIdCall(x, InputFields.call_type_id.name ), status: 1, limit: 100}, MenuNames.complain_type.lower, complain_typeFilterParams);

                    CrudBloc.instance.getList({category_ids: x.category_ids, status: 1, limit: 100}, MenuNames.brand.lower, brandFilterParams);
                    CrudBloc.instance.getList({brand_ids: x.brand_ids, status: 1, limit: 100}, MenuNames.product_sku.lower, product_skuFilterParams);
                    if(edit) CrudBloc.instance.getList({root_cause_type_id: getIdRootCause(x,InputFields.root_cause_type_id.name ), status: 1, limit: 100}, MenuNames.root_cause.lower, root_causeFilterParams);

                    ComplaintBloc.instance.complaintCurrentTM.next(x.territory_manager);
                    ComplaintBloc.instance.complaintCurrentDistributor.next(x?.town?.distributor);
                    ComplaintBloc.instance.complaintCurrentStatus.next(`${x.status}`)
                }
            },
        });
        this.outletSubcription = CrudBloc.instance.outlet.subscribe({
            next(x) {
                if (x){
                    //CrudBloc.instance.clearList(MenuNames.area.lower);
                    //CrudBloc.instance.getList({region_id: getId(x, InputFields.region_id.name ), status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                    //CrudBloc.instance.getList({area_id: getId(x, InputFields.area_id.name ), status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                    CrudBloc.instance.getList({territory_id: getId(x, InputFields.territory_id.name ), status: 1, limit: 100}, MenuNames.town.lower, townFilterParams);
                    CrudBloc.instance.getList({town_id: getId(x, InputFields.town_id.name ), status: 1, limit: 100}, MenuNames.thana.lower, thanaFilterParams);
                    x?.town?.territory?.territory_manager?.forEach(tm => {
                        if(tm.territory_manager && tm.territory_manager.status === 1){
                            ComplaintBloc.instance.complaintCurrentTM.next(tm.territory_manager);
                        }
                    })
                    ComplaintBloc.instance.complaintCurrentDistributor.next(x?.town?.distributor);
                }
            },
        });
        if(outlet){
            CrudBloc.instance.getDetails(outlet, MenuNames.outlet.lower);
        }else if(id || complaint){
            CrudBloc.instance.getDetails(id ?? complaint, MenuNames.complaint.lower);
        }
        const history = this.props.history;
        this.UpdateResponseSubscription = CrudBloc.instance.updateResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.complaint.lower));
                    CrudBloc.instance.clearUpdateResponseData();
                }
            },
        });
    }
    prepareCopy = () => {
        this.prepareEdit();
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.complaint.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }

    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.complaint.lower);
        CrudBloc.instance.clearDetails(MenuNames.outlet.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new Complaint");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.complaint.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }

    componentDidMount() {

        // this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
        //     next(x) {
        //         if (x){
        //             CrudBloc.instance.clearList(MenuNames.area.lower);
        //             CrudBloc.instance.clearList(MenuNames.territory.lower);
        //             CrudBloc.instance.clearList(MenuNames.town.lower);
        //             CrudBloc.instance.clearList(MenuNames.thana.lower);
        //             CrudBloc.instance.clearList(MenuNames.distributor.lower);
        //             CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams)
        //         }
        //     },
        // });
        // this.selectAreaSubscription = LocationBloc.instance.selectedArea.subscribe({
        //     next(x) {
        //         if (x){
        //             CrudBloc.instance.clearList(MenuNames.territory.lower);
        //             CrudBloc.instance.clearList(MenuNames.town.lower);
        //             CrudBloc.instance.clearList(MenuNames.thana.lower);
        //             CrudBloc.instance.clearList(MenuNames.distributor.lower);
        //             CrudBloc.instance.getList({area_id: x, status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams)
        //         }
        //     },
        // });
        this.selectTerritorySubscription = LocationBloc.instance.selectedTerritory.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.clearList(MenuNames.thana.lower);
                    CrudBloc.instance.clearList(MenuNames.distributor.lower);
                    CrudBloc.instance.getList({territory_id: x, status: 1, limit: 100}, MenuNames.town.lower, townFilterParams)
                    //CrudBloc.instance.getList({territory_id: x, status: 1, limit: 1}, MenuNames.territory_manager.lower, territory_managerFilterParams)
                }
            },
        });
        this.selectTownSubscription = LocationBloc.instance.selectedTown.subscribe({
            next(x) {
                if (x){

                    CrudBloc.instance.clearList(MenuNames.thana.lower);
                    CrudBloc.instance.clearList(MenuNames.distributor.lower);

                }
            },
        });
        this.selectCalltypeSubscription = LocationBloc.instance.selectedCallType.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.complain_type.lower);
                    CrudBloc.instance.getList({call_type_id: x, status: 1, limit: 100}, MenuNames.complain_type.lower, complain_typeFilterParams)
                }
            },
        });
        this.selectCategorySubscription = LocationBloc.instance.selectedCategory.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.brand.lower);
                    CrudBloc.instance.getList({category_id: x, status: 1, limit: 100}, MenuNames.brand.lower, brandFilterParams)
                }
            },
        });
        this.selectBrandSubscription = LocationBloc.instance.selectedBrand.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.product_sku.lower);
                    CrudBloc.instance.getList({brand_id: x, status: 1, limit: 100}, MenuNames.product_sku.lower, product_skuFilterParams)
                }
            },
        });

    }
    componentWillUnmount() {
        this.complaintSubcription?.unsubscribe();
        this.outletSubcription?.unsubscribe();
        this.createResponseSubscription?.unsubscribe();
        this.UpdateResponseSubscription?.unsubscribe();
        this.selectTerritorySubscription?.unsubscribe();
        this.selectTownSubscription.unsubscribe();
        this.selectCalltypeSubscription.unsubscribe();
        this.selectCategorySubscription.unsubscribe();
        this.selectBrandSubscription.unsubscribe();
    }

    isEditOrCopy = () => {
        return (this.props.copy || this.props.edit);
    }
    isEdit = () => {
        return this.props.edit;
    }

    buildView = (snapshot, snapshotOutlet) => {
        return  <Form ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onFinish}
                      layout="vertical"
                      className="create-form-full">
            <Card title="Caller's Information">
                <Row gutter={16}>
                    <Col span={6}>
                        <InputText
                            disabled={this.state.disableEdit}
                            values={this.state.call_record_id}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.call_record_id.name] :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data[this.state.call_record_id.name] : undefined}/>
                    </Col>
                    <Col span={6}>
                        <InputText
                            disabled={this.state.disableEdit}
                            values={this.state.outlet_dms_code}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.outlet_dms_code.name] :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data[this.state.outlet_dms_code.name] : undefined}/>
                    </Col>
                    <Col span={6}>
                        <InputText
                            disabled={this.state.disableEdit}
                            values={this.state.name}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.name.name] :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.owner_name : undefined}/>
                    </Col>
                    <Col span={6}>
                        <InputText
                            disabled={this.state.disableEdit}
                            values={this.state.outlet_name}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.outlet_name.name] :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.name : undefined}/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <InputText
                            disabled={this.state.disableEdit}
                            values={this.state.mobile}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.mobile.name] :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.mobile : undefined}/>
                    </Col><Col span={8}>
                    <InputText
                        disabled={this.state.disableEdit}
                        values={this.state.mobile_2}
                        value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.mobile_2.name] :
                            this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.mobile_2 : undefined}/>
                </Col><Col span={8}>
                    <InputText
                        disabled={this.state.disableEdit}
                        values={this.state.mobile_3}
                        value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.mobile_3.name] :
                            this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.mobile_3 : undefined}/>
                </Col></Row>
                <InputTextArea
                    disabled={this.state.disableEdit}
                    values={this.state.address}
                    value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.address.name] :
                        this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.address : undefined}/>
            </Card>
            <Box y={10}/>
            <Card title="Location Information">
                <Row gutter={16}>
                    {/*<Col span={8}>*/}
                    {/*    <BlocBuilder*/}
                    {/*        subject={CrudBloc.instance.regionList}*/}
                    {/*        builder={(snapshotRegion) => {*/}
                    {/*            return <InputSelect*/}
                    {/*                menuName={MenuNames.region.lower}*/}
                    {/*                onSelect={(id) => {*/}
                    {/*                    LocationBloc.instance.onSelectLocation(MenuNames.region.upper, id);*/}
                    {/*                    this.formRef.current?.setFieldsValue({area_id: undefined});*/}
                    {/*                    this.formRef.current?.setFieldsValue({territory_id: undefined});*/}
                    {/*                    this.formRef.current?.setFieldsValue({town_id: undefined});*/}
                    {/*                    this.formRef.current?.setFieldsValue({thana_id: undefined});*/}
                    {/*                }*/}
                    {/*                }*/}
                    {/*                value={this.isEditOrCopy() && snapshot.data ? getId(snapshot.data, this.state.region_id.name) :*/}
                    {/*                    this.isEditOrCopy() && snapshotOutlet.data ? getId(snapshotOutlet.data, this.state.region_id.name) : undefined}*/}
                    {/*                values={this.state.region_id}*/}
                    {/*                options={snapshotRegion.data ? snapshotRegion.data.list : []}*/}
                    {/*            />*/}
                    {/*        }}/>*/}
                    {/*</Col>*/}
                    {/*<Col span={8}>*/}
                    {/*    <BlocBuilder*/}
                    {/*        subject={CrudBloc.instance.areaList}*/}
                    {/*        builder={(snapshotArea) => {*/}
                    {/*            return <InputSelect*/}
                    {/*                menuName={MenuNames.area.lower}*/}
                    {/*                onSelect={(id) => {*/}
                    {/*                    LocationBloc.instance.onSelectLocation(MenuNames.area.upper, id);*/}
                    {/*                    this.formRef.current?.setFieldsValue({territory_id: undefined});*/}
                    {/*                    this.formRef.current?.setFieldsValue({town_id: undefined});*/}
                    {/*                    this.formRef.current?.setFieldsValue({thana_id: undefined});*/}
                    {/*                }*/}
                    {/*                }*/}
                    {/*                values={this.state.area_id}*/}
                    {/*                options={snapshotArea.data ? snapshotArea.data.list : []}*/}
                    {/*                value={this.isEditOrCopy() && snapshot.data ? getId(snapshot.data, this.state.area_id.name) :*/}
                    {/*                    this.isEditOrCopy() && snapshotOutlet.data ? getId(snapshotOutlet.data, this.state.area_id.name) : undefined}*/}
                    {/*            />*/}
                    {/*        }}/>*/}
                    {/*</Col>*/}
                    <Col span={16}>
                        <BlocBuilder
                            subject={CrudBloc.instance.territoryList}
                            builder={(snapshotTerritory) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.territory.lower}
                                    onSelect={(id) => {
                                        LocationBloc.instance.onSelectLocation(MenuNames.territory.upper, id);
                                        this.formRef.current?.setFieldsValue({town_id: undefined});
                                        this.formRef.current?.setFieldsValue({thana_id: undefined});


                                        snapshotTerritory.data.list.forEach(e => {

                                            if (`${e.id}` === id) {
                                                e.territory_manager.forEach(tm => {
                                                    if(tm.territory_manager && tm.territory_manager?.status === 1 && `${tm.territory_id}` === `${e.id}`){
                                                        ComplaintBloc.instance.complaintCurrentTM.next(tm.territory_manager);
                                                    }
                                                })

                                            }
                                        })

                                    }}
                                    values={this.state.territory_id}
                                    options={snapshotTerritory.data ? Utils.prepareDropdownOptionsTerritory(snapshotTerritory.data.list) : []}
                                    value={this.isEditOrCopy() && snapshot.data ? getId(snapshot.data, this.state.territory_id.name) :
                                        this.isEditOrCopy() && snapshotOutlet.data ? getId(snapshotOutlet.data, this.state.territory_id.name) : undefined}
                                />
                            }}/>
                    </Col>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.townList}
                            builder={(snapshotTown) => {

                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.town.lower}
                                    onSelect={(id) => {
                                        LocationBloc.instance.onSelectLocation(MenuNames.town.upper, id);
                                        let town = getTown(id, snapshotTown.data ? snapshotTown.data.list : [])
                                        ComplaintBloc.instance.complaintCurrentDistributor.next(town.distributor);
                                        this.formRef.current?.setFieldsValue({town_dms_code: town.town_dms_code});
                                        this.formRef.current?.setFieldsValue({thana_id: undefined});
                                        this.formRef.current?.setFieldsValue({distributor_id: undefined});
                                        this.formRef.current?.setFieldsValue({distributor_type_id: `${town.distributor_type_id}`});
                                        CrudBloc.instance.clearList(MenuNames.distributor.lower);
                                        CrudBloc.instance.getList({town_id: id, status: 1, limit: 100}, MenuNames.thana.lower, thanaFilterParams);
                                        // CrudBloc.instance.getList({
                                        //     town_dms_code: town.town_dms_code,
                                        //     status: 1,
                                        //     limit: 100
                                        // }, MenuNames.distributor.lower, distributorFilterParams);

                                    }}
                                    values={this.state.town_id}
                                    options={snapshotTown.data ? snapshotTown.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? getId(snapshot.data, this.state.town_id.name) :
                                        this.isEditOrCopy() && snapshotOutlet.data ? getId(snapshotOutlet.data, this.state.town_id.name) : undefined}
                                />
                            }}/>
                    </Col>
                </Row>

                <Row gutter={16}>

                    <Col span={8}>
                        <InputText
                            disabled={true}
                            values={this.state.town_dms_code}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data.town_dms_code :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.town_dms_code : undefined}/>
                    </Col>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.thanaList}
                            builder={(snapshotThana) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    values={this.state.thana_id}
                                    options={snapshotThana.data ? snapshotThana.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? getId(snapshot.data, this.state.thana_id.name) : undefined}
                                />
                            }}/>
                    </Col>
                    {/*<Col span={8}>*/}
                    {/*    <BlocBuilder*/}
                    {/*        subject={CrudBloc.instance.territory_managerList}*/}
                    {/*        builder={(snapshot) => {*/}
                    {/*            return snapshot.data && snapshot.data.total > 0 ?*/}
                    {/*                <div>*/}
                    {/*                    <TextX text={"Territory Manager"} size={16}/>*/}
                    {/*                    <TextX text={snapshot.data.list[0].name} size={16}/>*/}
                    {/*                </div>*/}
                    {/*                : <div/>*/}
                    {/*        }}/>*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <InputSelect
                            disabled={this.state.disableEdit}
                            values={this.state.distributor_type_id}
                            options={InputFieldOptions.distributor_type_id}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.distributor_type_id.name] :
                                this.isEditOrCopy() && snapshotOutlet.data ? snapshotOutlet.data.details.distributor_type_id: 1}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    {/*<Col span={12}>*/}
                    {/*    <BlocBuilder*/}
                    {/*        subject={CrudBloc.instance.distributorList}*/}
                    {/*        builder={(snapshotDistributor) => {*/}
                    {/*            return <InputSelect*/}
                    {/*                values={this.state.distributor_id}*/}
                    {/*                options={snapshotDistributor.data ? snapshotDistributor.data.list : []}*/}
                    {/*                value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.distributor_id.name] : undefined}*/}
                    {/*            />*/}
                    {/*        }}/>*/}
                    {/*</Col>*/}

                </Row>
                <BlocBuilder
                    subject={ComplaintBloc.instance.complaintCurrentDistributor}
                    builder={(snapshotDistributor) => {
                        return snapshotDistributor.data ?
                            <Descriptions title="Distributor">
                                <Descriptions.Item label="Name">{snapshotDistributor.data.name}</Descriptions.Item>
                                <Descriptions.Item label="Owner Name">{snapshotDistributor.data.owner_name}</Descriptions.Item>
                                <Descriptions.Item label="Address">{snapshotDistributor.data.address}</Descriptions.Item>
                                <Descriptions.Item label="Contact">{snapshotDistributor.data.contact}</Descriptions.Item>

                            </Descriptions>

                            : <div/>

                    }}/>

            </Card>
            <Box y={10}/>
            <Card title="Call Information">
                <InputTextArea
                    disabled={this.state.disableEdit}
                    values={this.state.remark}
                    value={this.isEdit() && snapshot.data ? snapshot.data[this.state.remark.name] : undefined}/>
                <Row gutter={16}>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.call_typeList}
                            builder={(snapshotCallType) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.call_type.lower}
                                    onSelect={(id) => {
                                        LocationBloc.instance.onSelectLocation(MenuNames.call_type.upper, id);
                                        this.formRef.current?.setFieldsValue({complain_type_id: undefined});
                                    }}
                                    values={this.state.call_type_id}
                                    options={snapshotCallType.data ? snapshotCallType.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? getIdCall(snapshot.data, this.state.call_type_id.name) : undefined}
                                />
                            }}/>
                    </Col>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.complain_typeList}
                            builder={(snapshotComplainType) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.complain_type.lower}
                                    values={this.state.complain_type_id}
                                    options={snapshotComplainType.data ? snapshotComplainType.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.complain_type_id.name] : undefined}
                                />
                            }}/>
                    </Col>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.store_typeList}
                            builder={(snapshotStoreType) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.store_type.lower}
                                    values={this.state.store_type_id}
                                    options={snapshotStoreType.data ? snapshotStoreType.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.store_type_id.name] : undefined}
                                />
                            }}/>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <BlocBuilder
                            subject={CrudBloc.instance.categoryList}
                            builder={(snapshotCategory) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.category.lower}
                                    multiple={true}
                                    onSelect={(id) => {

                                        let value = this.formRef.current?.getFieldValue('category_ids');

                                        if (value.length > 0)
                                            LocationBloc.instance.onSelectLocation(MenuNames.category.upper, value);
                                        //this.formRef.current?.setFieldsValue({brand_ids: undefined});
                                    }}
                                    values={this.state.category_ids}
                                    options={snapshotCategory.data ? snapshotCategory.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.category_ids.name] : undefined}
                                />
                            }}/>
                    </Col>
                    <Col span={12}>
                        <BlocBuilder
                            subject={CrudBloc.instance.brandList}
                            builder={(snapshotBrand) => {
                                return <InputSelect
                                    disabled={this.state.disableEdit}
                                    menuName={MenuNames.brand.lower}
                                    multiple={true}
                                    onSelect={(id) => {

                                        let value = this.formRef.current?.getFieldValue('brand_ids');
                                        if (value.length > 0)
                                            LocationBloc.instance.onSelectLocation(MenuNames.brand.upper, value);
                                        //this.formRef.current?.setFieldsValue({brand_ids: undefined});
                                    }}
                                    values={this.state.brand_ids}
                                    options={snapshotBrand.data ? snapshotBrand.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.brand_ids.name] : undefined}
                                />
                            }}/>
                    </Col>
                </Row>
                <BlocBuilder
                    subject={CrudBloc.instance.product_skuList}
                    builder={(snapshotSKU) => {
                        return <InputSelect
                            disabled={this.state.disableEdit}
                            menuName={MenuNames.product_sku.lower}
                            multiple={true}
                            onSelect={(id) => {

                                let value = this.formRef.current?.getFieldValue('sku_ids');
                                if (value.length > 0)
                                    LocationBloc.instance.onSelectLocation(MenuNames.product_sku.upper, value);
                                //this.formRef.current?.setFieldsValue({brand_ids: undefined});
                            }}
                            values={this.state.sku_ids}
                            options={snapshotSKU.data ? snapshotSKU.data.list : []}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.sku_ids.name] : undefined}
                        />
                    }}/>
                <Row gutter={16}>
                    <Col span={8}>
                        <InputSelect
                            values={this.state.complaint_status}
                            options={InputFieldOptions.complaint_status}
                            onSelect={(id) => {
                                ComplaintBloc.instance.complaintCurrentStatus.next(id);
                            }}
                            value={this.isEdit() && snapshot.data ? snapshot.data[this.state.complaint_status.name] : 1}
                        />
                    </Col>
                    <Col span={8}>
                        <InputSelect
                            values={this.state.sticker_status}
                            options={InputFieldOptions.sticker_status}
                            onSelect={(id) => {
                                ComplaintBloc.instance.complaintCurrentStickerStatus.next(id);
                            }}
                            value={this.isEdit() && snapshot.data ? snapshot.data[this.state.sticker_status.name] : 2}
                        />
                    </Col>
                    <Col span={8}>
                        <InputText
                            disabled={this.state.disableEdit}
                            values={this.state.dsr_name}
                            value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.dsr_name.name] : undefined}/>
                    </Col>

                </Row>
                <BlocBuilder
                    subject={ComplaintBloc.instance.complaintCurrentStatus}
                    builder={(snapshotStatus) =>{
                        return snapshotStatus.data === '5' && <Row gutter={24}>
                        <Col span={8}>
                        <BlocBuilder
                        subject={CrudBloc.instance.program_ownerList}
                        builder={(snapshotPO) => {
                        return <InputSelect

                            //menuName={MenuNames.program_owner.lower}
                        //multiple={true}
                        values={this.state.program_owner_id}
                        options={snapshotPO.data ? snapshotPO.data.list : []}
                        value={this.isEdit() && snapshot.data ? snapshot.data[this.state.program_owner_id.name] : undefined}
                        />
                    }}/>
                        </Col>
                        <Col span={8}>
                        <BlocBuilder
                        subject={CrudBloc.instance.program_ownerList}
                        builder={(snapshotPO) => {
                        return <InputSelect
                            //menuName={MenuNames.program_owner.lower}
                        //multiple={true}
                        values={this.state.program_owner_2_id}
                        options={snapshotPO.data ? snapshotPO.data.list : []}
                        value={this.isEdit() && snapshot.data ? snapshot.data[this.state.program_owner_2_id.name] : undefined}
                        />
                    }}/>
                        </Col>
                        <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.program_ownerList}
                        builder={(snapshotPO) => {
                        return <InputSelect
                        //menuName={MenuNames.program_owner.lower}
                        //multiple={true}
                        values={this.state.program_owner_3_id}
                        options={snapshotPO.data ? snapshotPO.data.list : []}
                        value={this.isEdit() && snapshot.data ? snapshot.data[this.state.program_owner_3_id.name] : undefined}
                        />
                    }}/>
                        </Col>
                        </Row>}
                    }
                    />

            </Card>
            <Box y={10}/>
            <Card title="Territory Manager Information">


                <Row gutter={16}>
                    <Col span={16}>
                        <BlocBuilder
                            subject={ComplaintBloc.instance.complaintCurrentTM}
                            builder={(snapshotTM) => {
                                return snapshotTM.data ?
                                    <Descriptions title="Territory Manager">
                                        <Descriptions.Item label="Name">{snapshotTM.data.name}</Descriptions.Item>
                                        <Descriptions.Item label="Mobile">{snapshotTM.data.mobile}</Descriptions.Item>
                                        <Descriptions.Item label="Email">{snapshotTM.data.email}</Descriptions.Item>

                                    </Descriptions>

                                   : <div/>

                            }}/>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="expires_at" label="Expires At" {...config}

                                   initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data['expires_at'] , 'YYYY/MM/DD'): moment()}>
                            <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                        </Form.Item>
                    </Col>
                </Row>

            </Card>
            <Box y={10}/>
            {this.isEdit() && <Card title="Follow-up by TM">
                <InputTextArea
                    values={this.state.tm_remark}
                    value={this.isEditOrCopy() && snapshot.data ? snapshot.data[this.state.tm_remark.name] : undefined}/>
                <Row gutter={16}>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.root_cause_typeList}
                            builder={(snapshotRootCauseType) => {
                                return <InputSelect
                                    menuName={MenuNames.root_cause_type.lower}
                                    onSelect={(id) => {
                                        LocationBloc.instance.onSelectLocation(MenuNames.root_cause_type.upper, id);
                                        this.formRef.current?.setFieldsValue({root_cause_id: undefined});
                                        CrudBloc.instance.clearList(MenuNames.root_cause.lower);
                                        CrudBloc.instance.getList({root_cause_type_id: id, status: 1, limit: 100}, MenuNames.root_cause.lower, root_causeFilterParams)
                                    }
                                    }
                                    values={this.state.root_cause_type_id}
                                    options={snapshotRootCauseType.data ? snapshotRootCauseType.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? getIdRootCause(snapshot.data, this.state.root_cause_type_id.name) : undefined}
                                />
                            }}/>
                    </Col>
                    <Col span={8}>
                        <BlocBuilder
                            subject={CrudBloc.instance.root_causeList}
                            builder={(snapshotRootCause) => {
                                return <InputSelect
                                    menuName={MenuNames.root_cause.lower}
                                    values={this.state.root_cause_id}
                                    options={snapshotRootCause.data ? snapshotRootCause.data.list : []}
                                    value={this.isEditOrCopy() && snapshot.data ? getIdRootCause(snapshot.data, this.state.root_cause_id.name) : undefined}
                                />
                            }}/>
                    </Col>
                </Row>
            </Card>}
            <Box y={20}/>
            <Form.Item>
                <ButtonX
                    htmlType="submit"
                    name="submit"
                    text="Submit" className={"button-default-accent"}/>
            </Form.Item>
            <BlocBuilder
                subject={AuthBloc.instance.errorText}
                builder={(snapshotError) => {
                    return <TextX text={snapshotError.data} color={Colors.water_blue}/>
                }}/>
        </Form>
    }

    onFinish = (values) => {
        delete values["region_id"];
        delete values["area_id"];
        delete values["region_id"];
        delete values["territory_id"];

        if(this.props.edit){
            delete values['agent_id'];
        } else {
            values['agent_id'] = AuthBloc.instance.user.value.id;
        }
        values['category_ids'] = (values['category_ids'] ?? "" ).toString();
        values['brand_ids'] = (values['brand_ids'] ?? "" ).toString();
        values['sku_ids'] = (values['sku_ids'] ?? "").toString();
        values['territory_manager_id'] = ComplaintBloc.instance.complaintCurrentTM.value?.id;
        values['distributor_id'] = ComplaintBloc.instance.complaintCurrentDistributor.value?.id;
        values['expires_at'] = moment(values['expires_at'], "YYYY-MM-DDTHH:mm:sssZ").format("YYYY-MM-DD 10:00:00");
        if(values['status'] === "3"){
            values['closed_at'] = moment(moment.now() ).format("YYYY-MM-DD HH:mm:ss");
            values['from_app'] = "web";
            values['closed_by'] = AuthBloc.instance.user.value.email;
        }
        if(!values['program_owner_id']){
            values['program_owner_id'] = null;
        }
        if(!values['program_owner_2_id']){
            values['program_owner_2_id'] = null;
        }
        if(!values['program_owner_3_id']){
            values['program_owner_3_id'] = null;
        }
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.updatePost(id, values, MenuNames.complaint.lower);
        }else{

            CrudBloc.instance.createNew(values, MenuNames.complaint.lower);
        }
    };
    render() {
        const complaint = new URLSearchParams(this.props.location.search).get("complaint");
        const outlet = new URLSearchParams(this.props.location.search).get("outlet");
        const {id} = this.props.match.params;
        return (
            <IsLoading name={MenuNames.complaint.lower}>
                <IsLoading name={MenuNames.outlet.lower}>
                    <BlocBuilder
                        subject={CrudBloc.instance.complaint}
                        builder={(snapshot) => {
                            return <BlocBuilder
                                subject={CrudBloc.instance.outlet}
                                builder={(snapshotOutlet) => {
                                    if(!outlet && !complaint && !id){
                                        return this.buildView(snapshot, snapshotOutlet )
                                    }else{
                                        if((id || complaint) && snapshot.data){
                                            return this.buildView(snapshot, snapshotOutlet )
                                        }else if(outlet && snapshotOutlet.data){
                                            return this.buildView(snapshot, snapshotOutlet )
                                        }else{
                                            return <FormSkeleton line={2}/>;
                                        }
                                    }

                                }}/>
                        }}/>
                </IsLoading>
            </IsLoading>
        );
    }
}
//<FormSkeleton line={2}/>
//!this.isEditOrCopy() || snapshot.data || !complaint ?
const config = {
    rules: [{ type: 'object', required: true, message: 'Please select date!' }],
};