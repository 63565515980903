import React, {Component} from "react"
import {Route, Switch} from "react-router-dom"
import PropTypes from 'prop-types'
import {
    getRouteCreate,
    getRouteDetailsPath,
    getRouteList,
    getRouteUpdatePath,
    routeHome
} from "../../utils/RouterUtils";
import {HomePage} from "../home/HomePage";
import {RegionListPage} from "../region/RegionListPage";
import {RegionDetailsPage} from "../region/RegionDetailsPage";
import {AreaDetailsPage} from "../area/AreaDetailsPage";
import {AreaListPage} from "../area/AreaListPage";
import {TownListPage} from "../town/TownListPage";
import {TownDetailsPage} from "../town/TownDetailsPage";
import {UserListPage} from "../user/UserListPage";
import {UserDetailsPage} from "../user/UserDetailsPage";
import {RegionCreatePage} from "../region/RegionCreatePage";
import {AreaCreatePage} from "../area/AreaCreatePage";
import {TownCreatePage} from "../town/TownCreatePage";
import {UserCreatePage} from "../user/UserCreatePage";
import {MenuNames} from "../../utils/Constants";
import {BrandListPage} from "../brand/BrandListPage";
import {BrandCreatePage} from "../brand/BrandCreatePage";
import {BrandDetailsPage} from "../brand/BrandDetailsPage";
import {CallTypeListPage} from "../call_type/CallTypeListPage";
import {CallTypeCreatePage} from "../call_type/CallTypeCreatePage";
import {CallTypeDetailsPage} from "../call_type/CallTypeDetailsPage";
import {CategoryListPage} from "../category/CategoryListPage";
import {CategoryCreatePage} from "../category/CategoryCreatePage";
import {CategoryDetailsPage} from "../category/CategoryDetailsPage";
import {MarketChannelListPage} from "../market_channel/MarketChannelListPage";
import {MarketChannelCreatePage} from "../market_channel/MarketChannelCreatePage";
import {MarketChannelDetailsPage} from "../market_channel/MarketChannelDetailsPage";
import {RootCauseTypeListPage} from "../root_cause_type/RootCauseTypeListPage";
import {RootCauseTypeCreatePage} from "../root_cause_type/RootCauseTypeCreatePage";
import {RootCauseTypeDetailsPage} from "../root_cause_type/RootCauseTypeDetailsPage";
import {StoreTypeListPage} from "../store_type/StoreTypeListPage";
import {StoreTypeCreatePage} from "../store_type/StoreTypeCreatePage";
import {StoreTypeDetailsPage} from "../store_type/StoreTypeDetailsPage";
import {UserGroupListPage} from "../user_group/UserGroupListPage";
import {UserGroupCreatePage} from "../user_group/UserGroupCreatePage";
import {UserGroupDetailsPage} from "../user_group/UserGroupDetailsPage";
import {ThanaListPage} from "../thana/ThanaListPage";
import {ThanaCreatePage} from "../thana/ThanaCreatePage";
import {ThanaDetailsPage} from "../thana/ThanaDetailsPage";
import {TerritoryListPage} from "../territory/TerritoryListPage";
import {TerritoryCreatePage} from "../territory/TerritoryCreatePage";
import {TerritoryDetailsPage} from "../territory/TerritoryDetailsPage";
import {ComplainTypeListPage} from "../complain_type/ComplainTypeListPage";
import {ComplainTypeCreatePage} from "../complain_type/ComplainTypeCreatePage";
import {ComplainTypeDetailsPage} from "../complain_type/ComplainTypeDetailsPage";
import {RootCauseListPage} from "../root_cause/RootCauseListPage";
import {RootCauseCreatePage} from "../root_cause/RootCauseCreatePage";
import {RootCauseDetailsPage} from "../root_cause/RootCauseDetailsPage";
import {RegionManagerListPage} from "../region_manager/RegionManagerListPage";
import {RegionManagerCreatePage} from "../region_manager/RegionManagerCreatePage";
import {RegionManagerDetailsPage} from "../region_manager/RegionManagerDetailsPage";
import {AreaManagerListPage} from "../area_manager/AreaManagerListPage";
import {AreaManagerCreatePage} from "../area_manager/AreaManagerCreatePage";
import {AreaManagerDetailsPage} from "../area_manager/AreaManagerDetailsPage";
import {TerritoryManagerListPage} from "../territory_manager/TerritoryManagerListPage";
import {TerritoryManagerCreatePage} from "../territory_manager/TerritoryManagerCreatePage";
import {TerritoryManagerDetailsPage} from "../territory_manager/TerritoryManagerDetailsPage";
import {ProgramOwnerListPage} from "../program_owner/ProgramOwnerListPage";
import {ProgramOwnerCreatePage} from "../program_owner/ProgramOwnerCreatePage";
import {ProgramOwnerDetailsPage} from "../program_owner/ProgramOwnerDetailsPage";
import {ProgramDostiListPage} from "../program_dosti/ProgramDostiListPage";
import {ProgramDostiCreatePage} from "../program_dosti/ProgramDostiCreatePage";
import {ProgramDostiDetailsPage} from "../program_dosti/ProgramDostiDetailsPage";
import {ProgramTopGunListPage} from "../program_top_gun/ProgramTopGunListPage";
import {ProgramTopGunCreatePage} from "../program_top_gun/ProgramTopGunCreatePage";
import {ProgramTopGunDetailsPage} from "../program_top_gun/ProgramTopGunDetailsPage";
import {ProgramPerfectStoreListPage} from "../program_perfect_store/ProgramPerfectStoreListPage";
import {ProgramPerfectStoreCreatePage} from "../program_perfect_store/ProgramPerfectStoreCreatePage";
import {ProgramPerfectStoreDetailsPage} from "../program_perfect_store/ProgramPerfectStoreDetailsPage";
import {DistributorListPage} from "../distributor/DistributorListPage";
import {DistributorCreatePage} from "../distributor/DistributorCreatePage";
import {DistributorDetailsPage} from "../distributor/DistributorDetailsPage";
import {ProgramTimelineListPage} from "../program_timeline/ProgramTimelineListPage";
import {ProgramTimelineCreatePage} from "../program_timeline/ProgramTimelineCreatePage";
import {ProgramTimelineDetailsPage} from "../program_timeline/ProgramTimelineDetailsPage";
import {OutletListPage} from "../outlet/OutletListPage";
import {OutletCreatePage} from "../outlet/OutletCreatePage";
import {OutletDetailsPage} from "../outlet/OutletDetailsPage";
import {ComplaintListPage} from "../complaint/ComplaintListPage";
import {ComplaintCreatePage} from "../complaint/ComplaintCreatePage";
import {ComplaintDetailsPage} from "../complaint/ComplaintDetailsPage";
import {ComplaintHomePage} from "../complaint/ComplaintHomePage";
import {ProductSKUListPage} from "../product_sku/ProductSKUListPage";
import {ProductSKUCreatePage} from "../product_sku/ProductSKUCreatePage";
import {ProductSKUDetailsPage} from "../product_sku/ProductSKUDetailsPage";
import {ComplaintCreate} from "../complaint/ComplaintCreate";
import {OutboundCallListPage} from "../outbound_call/OutboundCallListPage";
import {OutboundCallCreatePage} from "../outbound_call/OutboundCallCreatePage";
import {OutboundCallDetailsPage} from "../outbound_call/OutboundCallDetailsPage";
import TerritoryManagerLocationUpdate from "../territory_manager/TerritoryManagerLocationUpdate";
import {SMSLogListPage} from "../log_sms/SMSLogListPage";
import {NotificationLogListPage} from "../log_notification/NotificationLogListPage";

class MainWindow extends Component {
    render() {
        const location = this.props.location
        return (
           <div style={{overflowY:"auto", height:"100%"}}>
               <Switch>
                   <Route location={location} path={routeHome} component={HomePage} />

                   <Route location={location} path={"/home/:mobile"} component={ComplaintHomePage} />
                   <Route location={location} path={"/home/"} component={ComplaintHomePage} />


                   <Route location={location} path={getRouteList(MenuNames.region.lower)} component={RegionListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.region.lower)} component={RegionCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.region.lower)} render = {(props) => <RegionCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.region.lower)} component={RegionDetailsPage}/>


                   <Route location={location} path={getRouteList(MenuNames.area.lower)} component={AreaListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.area.lower)} component={AreaCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.area.lower)} render = {(props) => <AreaCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.area.lower)} component={AreaDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.town.lower)} component={TownListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.town.lower)} component={TownCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.town.lower)} render = {(props) => <TownCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.town.lower)} component={TownDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.user.lower)} component={UserListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.user.lower)} component={UserCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.user.lower)} render = {(props) => <UserCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.user.lower)} component={UserDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.user_group.lower)} component={UserGroupListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.user_group.lower)} component={UserGroupCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.user_group.lower)} render = {(props) => <UserGroupCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.user_group.lower)} component={UserGroupDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.brand.lower)} component={BrandListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.brand.lower)} component={BrandCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.brand.lower)} render = {(props) => <BrandCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.brand.lower)} component={BrandDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.call_type.lower)} component={CallTypeListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.call_type.lower)} component={CallTypeCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.call_type.lower)} render = {(props) => <CallTypeCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.call_type.lower)} component={CallTypeDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.category.lower)} component={CategoryListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.category.lower)} component={CategoryCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.category.lower)} render = {(props) => <CategoryCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.category.lower)} component={CategoryDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.market_channel.lower)} component={MarketChannelListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.market_channel.lower)} component={MarketChannelCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.market_channel.lower)} render = {(props) => <MarketChannelCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.market_channel.lower)} component={MarketChannelDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.root_cause_type.lower)} component={RootCauseTypeListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.root_cause_type.lower)} component={RootCauseTypeCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.root_cause_type.lower)} render = {(props) => <RootCauseTypeCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.root_cause_type.lower)} component={RootCauseTypeDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.store_type.lower)} component={StoreTypeListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.store_type.lower)} component={StoreTypeCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.store_type.lower)} render = {(props) => <StoreTypeCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.store_type.lower)} component={StoreTypeDetailsPage}/>


                   <Route location={location} path={getRouteList(MenuNames.town.lower)} component={TownListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.town.lower)} component={TownCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.town.lower)} render = {(props) => <TownCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.town.lower)} component={TownDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.thana.lower)} component={ThanaListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.thana.lower)} component={ThanaCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.thana.lower)} render = {(props) => <ThanaCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.thana.lower)} component={ThanaDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.territory.lower)} component={TerritoryListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.territory.lower)} component={TerritoryCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.territory.lower)} render = {(props) => <TerritoryCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.territory.lower)} component={TerritoryDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.complain_type.lower)} component={ComplainTypeListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.complain_type.lower)} component={ComplainTypeCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.complain_type.lower)} render = {(props) => <ComplainTypeCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.complain_type.lower)} component={ComplainTypeDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.root_cause.lower)} component={RootCauseListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.root_cause.lower)} component={RootCauseCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.root_cause.lower)} render = {(props) => <RootCauseCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.root_cause.lower)} component={RootCauseDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.region_manager.lower)} component={RegionManagerListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.region_manager.lower)} component={RegionManagerCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.region_manager.lower)} render = {(props) => <RegionManagerCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.region_manager.lower)} component={RegionManagerDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.area_manager.lower)} component={AreaManagerListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.area_manager.lower)} component={AreaManagerCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.area_manager.lower)} render = {(props) => <AreaManagerCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.area_manager.lower)} component={AreaManagerDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.territory_manager.lower)} component={TerritoryManagerListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.territory_manager.lower)} component={TerritoryManagerCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.territory_manager.lower)} render = {(props) => <TerritoryManagerCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.territory_manager.lower)} component={TerritoryManagerDetailsPage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.territory_manager_location.lower)} component={TerritoryManagerLocationUpdate}/>

                   <Route location={location} path={getRouteList(MenuNames.program_owner.lower)} component={ProgramOwnerListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.program_owner.lower)} component={ProgramOwnerCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.program_owner.lower)} render = {(props) => <ProgramOwnerCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.program_owner.lower)} component={ProgramOwnerDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.program_dosti.lower)} component={ProgramDostiListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.program_dosti.lower)} component={ProgramDostiCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.program_dosti.lower)} render = {(props) => <ProgramDostiCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.program_dosti.lower)} component={ProgramDostiDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.program_top_gun.lower)} component={ProgramTopGunListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.program_top_gun.lower)} component={ProgramTopGunCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.program_top_gun.lower)} render = {(props) => <ProgramTopGunCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.program_top_gun.lower)} component={ProgramTopGunDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.program_perfect_store.lower)} component={ProgramPerfectStoreListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.program_perfect_store.lower)} component={ProgramPerfectStoreCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.program_perfect_store.lower)} render = {(props) => <ProgramPerfectStoreCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.program_perfect_store.lower)} component={ProgramPerfectStoreDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.distributor.lower)} component={DistributorListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.distributor.lower)} component={DistributorCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.distributor.lower)} render = {(props) => <DistributorCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.distributor.lower)} component={DistributorDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.program_timeline.lower)} component={ProgramTimelineListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.program_timeline.lower)} component={ProgramTimelineCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.program_timeline.lower)} render = {(props) => <ProgramTimelineCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.program_timeline.lower)} component={ProgramTimelineDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.outlet.lower)} component={OutletListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.outlet.lower)} component={OutletCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.outlet.lower)} render = {(props) => <OutletCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.outlet.lower)} component={OutletDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.complaint.lower)} component={ComplaintListPage} />
                   <Route key={"complaint-create"} location={location} path={"/complaint-create"} component={ComplaintCreatePage}/>
                   <Route key={"complaint-create-list"} location={location} path={getRouteCreate(MenuNames.complaint.lower)} component={ComplaintCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.complaint.lower)} render = {(props) => <ComplaintCreate edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.complaint.lower)} component={ComplaintDetailsPage}/>
                   <Route location={location} path={getRouteDetailsPath(MenuNames.complaint.lower)} component={ComplaintDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.product_sku.lower)} component={ProductSKUListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.product_sku.lower)} component={ProductSKUCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.product_sku.lower)} render = {(props) => <ProductSKUCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.product_sku.lower)} component={ProductSKUDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.outbound_call.lower)} component={OutboundCallListPage} />
                   <Route location={location} path={getRouteCreate(MenuNames.outbound_call.lower)} component={OutboundCallCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuNames.outbound_call.lower)} render = {(props) => <OutboundCallCreatePage edit {...props}  />} />
                   <Route location={location} path={getRouteDetailsPath(MenuNames.outbound_call.lower)} component={OutboundCallDetailsPage}/>

                   <Route location={location} path={getRouteList(MenuNames.sms_log.lower)} component={SMSLogListPage} />
                   <Route location={location} path={getRouteList(MenuNames.notification_log.lower)} component={NotificationLogListPage} />

                   {/*//_add_from_here*/}
                   <Route location={location} component={HomePage} />
               </Switch>
           </div>

        )
    }
}

MainWindow.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default MainWindow