import React from "react";
import {Form, Select} from "antd";
import "./FormComponent.css";
import {IsLoading} from "../IsLoading";
import {v4 as uuidv4} from 'uuid';

const {Option} = Select;

export class InputSelect extends React.Component {

  render() {

    const {value, isFetching, options, onSelect, isFilter, onClear, multiple = false, menuName = "", disabled = false} = this.props;
    const {name, label, placeholder, rules} = this.props.values;

    let multipleValues = [];
    if(multiple){
        if(value !== "NULL" && value !== undefined && value !== null && value !== "")
        multipleValues =  value.split(",");
    }
    return (
        <IsLoading name={menuName}>
          <Form.Item
              label={label}
              name={name}
              initialValue={value === undefined || value === null ? undefined : multiple ? multipleValues : `${value}`}
              rules={isFilter ? null : rules}>
              <Select
                  autoComplete="none"
                  mode={multiple ? "multiple": null}
                  showSearch
                  allowClear={true}
                  placeholder={placeholder}
                  size='large'
                  loading={isFetching}
                  onSelect={onSelect}
                  onDeselect={onSelect}
                  onClear={onClear ?? null}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled  = {disabled}
                  >
                {options.map((e, _i)=><Option key={e.id+ uuidv4()} value={e.id ? `${e.id}` : ""}>{e.title ? e.title : e.name ? e.name : 'Invalid Data' }</Option>)}
              </Select>
          </Form.Item>
        </IsLoading>
    );
  }
}
