import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const program_dostiQueryFilterParams = () => program_dostiFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const program_dostiFilterParams = [
  FilterInputItems.outlet_dms_code,
  FilterInputItems.year,
  FilterInputItems.timeline
];

export const program_dostiListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Outlet DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Target',
    field: 'target',
    type: tableContentType.TEXT,
  },
  {
    title: 'Achievement',
    field: 'achievement',
    type: tableContentType.TEXT,
  },
  {
    title: '%',
    field: 'percentage',
    type: tableContentType.PERCENTAGE,
  },
  {
    title: 'Timeline',
    field: 'time',
    type: tableContentType.TEXT,
  },
  {
    title: 'year',
    field: 'year',
    type: tableContentType.TEXT,
  },
  {
    title: 'Last Updated',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const program_dostiDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Target',
    field: 'target',
    type: tableContentType.TEXT,
  },
  {
    title: 'Achievement',
    field: 'achievement',
    type: tableContentType.TEXT,
  },
  {
    title: '%',
    field: 'percentage',
    type: tableContentType.PERCENTAGE,
  },
  {
    title: 'Timeline',
    field: 'time',
    type: tableContentType.TEXT,
  },
  {
    title: 'year',
    field: 'year',
    type: tableContentType.TEXT,
  },
  {
    title: 'Last Updated',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

export const program_dostiDetailsDataAttributes = [
  {
    title: 'Month',
    field: 'Month',
    type: tableContentType.TEXT,
  },
  {
    title: 'Target',
    field: 'Target',
    type: tableContentType.TEXT,
  },
  {
    title: 'Achievement',
    field: 'Achievement',
    type: tableContentType.TEXT,
  },
  {
    title: '%',
    field: 'percentage',
    type: tableContentType.PERCENTAGE,
  },

];

