import React, { useEffect, useState } from "react";
import {Button, Col, Form, message, Row, Select} from "antd";
import BlocBuilder from "bloc-builder-react/src";
import CrudBloc from "../../bloc/CrudBloc";
import {BASEURL} from "../../utils/Constants";
import {v4 as uuidv4} from "uuid";
import axiosWrapper from "../../utils/AxiosWrapper";


const {Option} = Select;

const TerritoryManagerLocation = (props) => {
    const [form] = Form.useForm();
    const [areaList, setAreaList] = useState([]);
    const [territoryList, setTerritoryList] = useState([]);

    const onFinish = (values) => {

        console.log('Received values of form:', values);
        console.log({territory_manager_id: props.tm.id, territory_id: values.territory_id });

        axiosWrapper
            .post(BASEURL+ `/territory_manager_location/store`, {territory_manager_id: props.tm.id, territory_id: values.territory_id })
            .then((response) => {
                console.log(response.data.data);
                form.resetFields();
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
                message.error('Territory already assigned to another territory manager');
            });

    };

    useEffect(() => {

        console.log(areaList);

    },)


    const getAreaList = async (id) => {
        const response = await axiosWrapper.get(BASEURL + `/area/index?skip=0&limit=100&region_id=${id}&status=1`);
        setAreaList(response.data.data);
    }

    const getTerritoryList= async (id) => {
        const response = await axiosWrapper.get(BASEURL+ `/territory/index?skip=0&limit=100&area_id=${id}&status=1`);
        setTerritoryList(response.data.data);
    }

    return (
        <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            className="create-form-full"
        >
            <Row gutter={16}>
                <Col span={7}>
                    <BlocBuilder
                        subject = {CrudBloc.instance.regionList}
                        builder = {(snapshotRegion) => {

                            const region = (snapshotRegion.data ? snapshotRegion.data.list : []).map((e, i)=><Option key={e.id+ uuidv4()} value={e.id}>{e.title}</Option>)
                            return <Form.Item
                                // initialValue={initialLocation[`${name}_region`]}
                                name={'region_id'}
                                label="Region"
                                rules={[{ required: true, message: 'Missing region' }]}>
                                <Select
                                    placeholder={"Select"}
                                    allowClear={true}
                                    onChange={(id)=>{
                                        getAreaList(id).catch(console.error)
                                        //setRegion(id)
                                        //getAreaList(name, id)
                                        //const data = form.getFieldValue('location');
                                        form.setFieldValue('area_id', null);
                                        form.setFieldValue('territory_id', null);
                                        //data = { ...data, ['area_id']: null };
                                        //data[key] = { ...data[key], ['territory_id']: null };
                                        //form.setFieldValue('location', data);

                                    }}> {region}</Select>
                            </Form.Item>
                        }}/>
                </Col>
                <Col span={7}>
                    <Form.Item
                        // initialValue={initialLocation[`${name}_area`]}
                        name={'area_id'}
                        label="Area"
                        rules={[{ required: true, message: 'Missing area' }]}>
                        <Select
                            placeholder={"Select"}
                            allowClear={true}
                            onChange={(id)=>{
                                // console.log_sms(name, id);
                                getTerritoryList(id).catch(console.error);
                                form.setFieldValue('territory_id', null)
                                // const data = form.getFieldValue('location');
                                // data[key] = { ...data[key], ['territory_id']: null };
                                // form.setFieldValue('location', data);

                            }}> {(areaList ?? []).map((e, i)=><Option key={e.id+ uuidv4()} value={e.id}>{e.title} </Option>) } </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        // initialValue={initialLocation[`${name}_territory`]}
                        name={'territory_id'}
                        label="Territory"
                        rules={[{ required: true, message: 'Missing area' }]}>
                        <Select
                            placeholder={"Select"}
                            allowClear={true}
                            onChange={(id)=>{

                            }}> {(territoryList ?? []).map((e, i)=><Option key={e.id+ uuidv4()} value={e.id}>{e.title} </Option>) }</Select>
                    </Form.Item>
                </Col>

            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default TerritoryManagerLocation;