import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {areaFilterParams} from "../area/AreaConstants";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {FormSkeleton} from "../shared/FormSkeleton";
import {regionFilterParams} from "../region/RegionConstants";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import {townFilterParams} from "../town/TownConstants";
import {getId, getTownDMSCode} from "../../utils/Utils";
import {IsLoading} from "../shared/IsLoading";
import {InputTextArea} from "../shared/FormComponent/InputTextArea";

export class DistributorCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            name: InputFields.name,
            address: InputFields.address,
            contact: InputFields.contact,
            owner_name: InputFields.owner_name,
            region_id: InputFields.region_id,
            area_id: InputFields.area_id,
            territory_id: InputFields.territory_id,
            town_id: InputFields.town_id,
            distributor_type_id: InputFields.distributor_type_id,
            town_dms_code: InputFields.town_dms_code,
            status: InputFields.status
        };

        if(this.props.edit){
            this.prepareEdit()
        }else{
            this.prepareCreate()
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
        CrudBloc.instance.clearList(MenuNames.area.lower);
        CrudBloc.instance.clearList(MenuNames.territory.lower);
        CrudBloc.instance.clearList(MenuNames.town.lower);

    }

    prepareEdit = () => {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("Distributor Update");
        this.distributorSubcription = CrudBloc.instance.distributor.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.getList({region_id: getId(x, InputFields.region_id.name ), status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                    CrudBloc.instance.getList({area_id: getId(x, InputFields.area_id.name ), status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                    CrudBloc.instance.getList({territory_id: getId(x, InputFields.territory_id.name ), status: 1, limit: 100}, MenuNames.town.lower, townFilterParams);
                    // LocationBloc.instance.onSelectLocation(MenuNames.region.upper, x.region_id);
                    // LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.area_id);
                    // LocationBloc.instance.onSelectLocation(MenuNames.territory.upper, x.territory_id);
                    // LocationBloc.instance.onSelectLocation(MenuNames.town.upper, x.town_id);

                }
            },
        });
        CrudBloc.instance.getDetails(id, MenuNames.distributor.lower);
        const history = this.props.history;
        this.UpdateResponseSubscription = CrudBloc.instance.updateResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.distributor.lower));
                    CrudBloc.instance.clearUpdateResponseData();
                }
            },
        });
    }

    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.distributor.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new Distributor");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.distributor.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }

    componentDidMount() {

        this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams)
                }
            },
        });
        this.selectAreaSubscription = LocationBloc.instance.selectedArea.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.getList({area_id: x, status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams)
                }
            },
        });
        this.selectTerritorySubscription = LocationBloc.instance.selectedTerritory.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.getList({territory_id: x, status: 1, limit: 100}, MenuNames.town.lower, townFilterParams)
                }
            },
        });

    }


    componentWillUnmount() {
        
        this.distributorSubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectRegionSubscription?.unsubscribe();
        this.selectAreaSubscription?.unsubscribe();
        this.selectTerritorySubscription?.unsubscribe();
        this.UpdateResponseSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        delete values["region_id"];
        delete values["area_id"];
        delete values["territory_id"];
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.distributor.lower);
        }else{
            CrudBloc.instance.createNew(values, MenuNames.distributor.lower);
        }

    };

    render() {
        return (
            <IsLoading name={MenuNames.distributor.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.distributor}
                    builder = {(snapshot) => {
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form-large">
                            <Row gutter={16}>
                                <Col span={12}>
                                <InputText
                                    values={this.state.name}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : undefined}/></Col>
                                <Col span={12}>
                                <InputText
                                    values={this.state.owner_name}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.owner_name.name] : undefined}/></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                            <InputTextArea
                                values={this.state.address}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.address.name] : undefined}/></Col><Col span={12}>
                            <InputTextArea
                                values={this.state.contact}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.contact.name] : undefined}/></Col></Row>
                            <Row gutter={16}>
                                <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.regionList}
                                builder = {(snapshotRegion) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                            LocationBloc.instance.onSelectLocation(MenuNames.region.upper, id);
                                            this.formRef.current?.setFieldsValue({ area_id: undefined});
                                            this.formRef.current?.setFieldsValue({ territory_id: undefined});
                                            this.formRef.current?.setFieldsValue({ town_id: undefined});
                                        }
                                        }
                                        value={this.props.edit && snapshot.data ? getId(snapshot.data, this.state.region_id.name) : undefined}
                                        values={this.state.region_id}
                                        options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                    />
                                }}/></Col><Col span={12}>

                            <BlocBuilder
                                subject = {CrudBloc.instance.areaList}
                                builder = {(snapshotArea) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                            LocationBloc.instance.onSelectLocation(MenuNames.area.upper, id);
                                            this.formRef.current?.setFieldsValue({ territory_id: undefined});
                                            this.formRef.current?.setFieldsValue({ town_id: undefined});
                                        }
                                        }
                                        values={this.state.area_id}
                                        options={snapshotArea.data ? snapshotArea.data.list : []}
                                        value={this.props.edit && snapshot.data ? getId(snapshot.data, this.state.area_id.name) : undefined}
                                    />
                                }}/></Col></Row>
                            <Row gutter={16}>
                                <Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.territoryList}
                                builder = {(snapshotTerritory) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                            LocationBloc.instance.onSelectLocation(MenuNames.territory.upper, id);
                                            this.formRef.current?.setFieldsValue({ town_id: undefined});
                                        }
                                        }
                                        values={this.state.territory_id}
                                        options={snapshotTerritory.data ? snapshotTerritory.data.list : []}
                                        value={this.props.edit && snapshot.data ? getId(snapshot.data, this.state.territory_id.name) : undefined}
                                    />
                                }}/>
                                </Col><Col span={12}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.townList}
                                builder = {(snapshotTown) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                            this.formRef.current?.setFieldsValue({ town_dms_code: getTownDMSCode(id, snapshotTown.data ? snapshotTown.data.list : [])});
                                        }}
                                        values={this.state.town_id}
                                        options={snapshotTown.data ? snapshotTown.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data?.town?.id : undefined}
                                    />
                                }}/>
                            </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <InputSelect
                                        values={this.state.status}
                                        options={InputFieldOptions.status}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                                    />
                                </Col>
                                <Col>
                                    <InputSelect
                                        values={this.state.distributor_type_id}
                                        options={InputFieldOptions.distributor_type_id}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.distributor_type_id.name] : 1}
                                    />
                                </Col>
                                <Col>
                                    <InputText
                                        disabled = {true}
                                        values={this.state.town_dms_code}
                                        value={this.props.edit && snapshot.data ? snapshot.data.town_dms_code : undefined}/>
                                </Col>
                            </Row>
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log_sms(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}