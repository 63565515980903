import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {MenuNames} from "../../utils/Constants";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {regionFilterParams} from "../region/RegionConstants";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import LocationBloc from "../../bloc/LocationBloc";
import {areaFilterParams} from "../area/AreaConstants";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import TerritoryManagerLocation from "./TerritoryManagerLocation";
import {territory_managerLocationAttributes} from "./TerritoryManagerConstants";
import {TableList} from "../shared/table/TableList";

export class TerritoryManagerCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        //console.log_sms(props);

        this.state = {
            name: InputFields.name,
            email: InputFields.email,
            mobile: InputFields.mobile,
            //region_id: InputFields.region_id,
            //area_id: InputFields.area_id,
            //territory_id: InputFields.territory_id,
            status: InputFields.status
        };

        if(this.props.edit){
            this.prepareEdit()
        }else{
            this.prepareCreate()
        }

        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.getDetails(id, MenuNames.territory_manager.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuNames.territory_manager.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
        CrudBloc.instance.clearList(MenuNames.area.lower);
        CrudBloc.instance.clearList(MenuNames.territory.lower);

    }

    prepareEdit = () => {
        //const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("Territory Manager Update");
        this.territoryManagerSubcription = CrudBloc.instance.territory_manager.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    //CrudBloc.instance.getList({region_id: x.territory?.area?.region_id, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);

                    LocationBloc.instance.onSelectLocation(MenuNames.region.upper, x.territory?.area?.region_id);
                    LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.territory?.area_id);
                    LocationBloc.instance.onSelectLocation(MenuNames.territory.upper, x.territory_id);

                }
            },
        });
    }
    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.territory_manager.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new Territory Manager");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.territory_manager.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }
    componentDidMount() {
        this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                }
            },
        });
        this.selectAreaSubscription = LocationBloc.instance.selectedArea.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.getList({area_id: x, status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                }
            },
        });

    }
    componentWillUnmount() {
        
        this.territoryManagerSubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectRegionSubscription?.unsubscribe();
        this.selectAreaSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.territory_manager.lower);
        }else{
            CrudBloc.instance.createNew(values, MenuNames.territory_manager.lower);
        }

    };


    render() {
        return (
            <IsLoading name={MenuNames.territory_manager.lower}>

                        <BlocBuilder
                            subject = {CrudBloc.instance.territory_manager}
                            builder = {(snapshot) => {
                                
                                //console.log_sms(snapshot.data ? snapshot.data[this.state.title.name] : null);

                                let locations = CrudBloc.instance.prepareList(snapshot.data?.territories, MenuNames.territory_manager_location.lower,["Edit", "Delete"])
                                return   !this.props.edit ||  snapshot.data ?
                                    <Row>
                                        <Col span={8}>

                                            <Form ref={this.formRef}
                                              name="control-ref"
                                              onFinish={this.onFinish}
                                              layout="vertical"
                                              className="create-form-full">
                                        <InputText
                                            values={this.state.name}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : undefined}/>

                                        <InputText
                                            values={this.state.email}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.email.name] : undefined}/>
                                        <InputText
                                            values={this.state.mobile}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>

                                        <InputSelect
                                            values={this.state.status}
                                            options={InputFieldOptions.status}
                                            value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                                        />
                                        <Box y={10}/>
                                        <Form.Item>
                                            <ButtonX
                                                htmlType="submit"
                                                name="submit"
                                                text="Submit" className={"button-default-accent"}/>
                                        </Form.Item>
                                        <BlocBuilder
                                            subject = {AuthBloc.instance.errorText}
                                            builder = {(snapshotError) => {
                                                //console.log_sms(snapshotError.data);
                                                return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                            }}/>
                                    </Form>
                                        </Col>
                                        <Col span={16}>
                                            <Row>
                                                <TableList
                                                    history={this.props.history}
                                                    location={this.props.location}
                                                    total = {locations?.length ?? 0}
                                                    list={locations ?? []}
                                                    isFetching={false}
                                                    isFailed={false}
                                                    pagination={false}
                                                    attributes={territory_managerLocationAttributes}
                                                    name={MenuNames.territory_manager.lower}
                                                />
                                            </Row>
                                            <Row>
                                                <TerritoryManagerLocation tm={snapshot.data}/>
                                            </Row>
                                        </Col>
                                    </Row>

                                    : <FormSkeleton line={2}/>
                            }}/>


            </IsLoading>
        );
    }
}