import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import {Col, Input} from "antd";
import {TextX} from "../shared/TextX";
import {Box} from "../shared/Box";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getApiSearchByMobileCode} from "../../utils/RouterUtils";
import {TableList} from "../shared/table/TableList";
import {complaintShortListAttributes} from "./ComplaintConstants";
import {outletShortListAttributes} from "../outlet/OutletConstants";
import {v4 as uuidv4} from 'uuid';

const { Search } = Input;

export class SearchComplaint extends React.Component{

    search = (search) => {
        if(search) {
            CrudBloc.instance.searchByMobileCode(MenuNames.complaint.lower, getApiSearchByMobileCode(MenuNames.complaint.lower, search));
            CrudBloc.instance.searchByMobileCode(MenuNames.outlet.lower, getApiSearchByMobileCode('outlet_details', search));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            const search = new URLSearchParams(this.props.location.search).get("search")
            const previousSearch = new URLSearchParams(prevProps.location.search).get("search")

            const uuid1 = new URLSearchParams(this.props.location.search).get("uuid")
            const uuid2 = new URLSearchParams(prevProps.location.search).get("uuid")
            if(search !== previousSearch || uuid1 !== uuid2)
                this.search(search);
        }
    }

    render() {
        const search = new URLSearchParams(this.props.location.search).get("search");
        return <div>
            <Search
                placeholder="Mobile / DMS Code"
                allowClear
                enterButton="Search"
                size="middle"
                defaultValue={search}

                onSearch={(value) => {
                    const {history } = this.props;
                    let pathname = this.props.location.pathname;
                    let searchParams = new URLSearchParams(this.props.location.search);
                    searchParams.set("search", value);
                    searchParams.set("uuid", uuidv4());
                    history.push({
                        pathname: pathname,
                        search: searchParams.toString(),
                    });

                }}
            />
            <Box y={20}/>
            <BlocBuilder
                subject={CrudBloc.instance.complaintList}
                builder={(snapshot) => {
                    
                    return snapshot.data && snapshot.data.total > 0 ? <Col>
                        <TextX text={"Complaints"} size={20}/>
                        <TableList
                        history={this.props.history}
                        location={this.props.location}
                        total={snapshot.data ? snapshot.data.total : 0}
                        list={snapshot.data ? snapshot.data.list : []}
                        isFetching={false}
                        isFailed={false}
                        attributes={complaintShortListAttributes}
                        name={MenuNames.complaint.lower}

                    /></Col> : <div/>
                }}/>
            <BlocBuilder
                subject={CrudBloc.instance.outletList}
                builder={(snapshot) => {
                    
                    return snapshot.data && snapshot.data.total > 0 ? <Col>
                        <TextX text={"Outlets"} size={20}/>
                        <TableList
                        history={this.props.history}
                        location={this.props.location}
                        total={snapshot.data ? snapshot.data.total : 0}
                        list={snapshot.data ? snapshot.data.list : []}
                        isFetching={false}
                        isFailed={false}
                        attributes={outletShortListAttributes}
                        name={MenuNames.outlet.lower}
                        pagination={false}
                    /></Col> : <div/>
                }}/>
        </div>
    }
}