import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {program_perfect_storeDetailsAttributes} from "./ProgramPerfectStoreConstants";
import {MenuNames} from "../../utils/Constants";

export class ProgramPerfectStoreDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("ProgramPerfectStore Details")
        CrudBloc.instance.getDetails(id, MenuNames.program_perfect_store.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.program_perfect_store}
            builder = {(snapshot) => {
                
                return  <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={snapshot.data ? snapshot.data : null}
                    isFetching={false}
                    isFailed={false}
                    attributes={program_perfect_storeDetailsAttributes}
                    name={MenuNames.program_perfect_store.lower}
                />
            }}/>
    }
}

