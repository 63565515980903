import React from "react";
import {Input, Row, Tabs} from 'antd';
import SettingsBloc from "../../bloc/SettingsBloc";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {complaintListAttributes} from "./ComplaintConstants";
import {getApiSearchByMobileCode, getRouteCreate} from "../../utils/RouterUtils";
import {TableList} from "../shared/table/TableList";
import BlocBuilder from "bloc-builder-react/src";
import {outletListAttributes} from "../outlet/OutletConstants";
import {ButtonCreateNew} from "../shared/ButtonCreateNew";
import './Complaint.css'
import {Box} from "../shared/Box";

const { Search } = Input;
const { TabPane } = Tabs;
export class ComplaintHomePage extends React.Component{

    componentDidMount() {

        SettingsBloc.instance.setCurrentPageTitle("Search Complaint / Outlet");
        this.search();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            this.search();
        }
    }

    search = () => {
        const {mobile} = this.props.match.params;

        CrudBloc.instance.searchByMobileCode(MenuNames.complaint.lower, getApiSearchByMobileCode(MenuNames.complaint.lower, mobile));
        CrudBloc.instance.searchByMobileCode(MenuNames.outlet.lower, getApiSearchByMobileCode('outlet_details', mobile));

    }

    render() {
        return <div>
            <Row type="flex" justify={"start"}>
                <ButtonCreateNew to={getRouteCreate(MenuNames.outlet.lower)} text={"Create New Outlet"} width={200}/>
                <Box x={16}/>
                <Search
                    placeholder="Mobile / DMS Code"
                    allowClear
                    enterButton="Search"
                    size="middle"
                    onSearch={(value)=>{
                        if(value)
                        this.props.history.push(`/home/${value}`);
                    }}
                />

            </Row>
            <BlocBuilder
                subject = {CrudBloc.instance.complaintList}
                builder = {(snapshot) => {
                    return  <TableList
                        history={this.props.history}
                        location={this.props.location}
                        total = {snapshot.data ? snapshot.data.total: 0}
                        list={snapshot.data ? snapshot.data.list : []}
                        isFetching={false}
                        isFailed={false}
                        attributes={complaintListAttributes}
                        name={MenuNames.complaint.lower}
                    />
                }}/>
            <BlocBuilder
                subject = {CrudBloc.instance.outletList}
                builder = {(snapshot) => {
                    return  <TableList
                        history={this.props.history}
                        location={this.props.location}
                        total = {snapshot.data ? snapshot.data.total: 0}
                        list={snapshot.data ? snapshot.data.list : []}
                        isFetching={false}
                        isFailed={false}
                        attributes={outletListAttributes}
                        name={MenuNames.outlet.lower}
                    />
                }}/>
        </div>
    }

}