import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Col, Row} from "antd";
import FilterPopup from "../shared/Filter/FilterPopup";
import {Box} from "../shared/Box";
import {
    complaintFilterParams, complaintFilterParamsAreaManager,
    complaintFilterParamsManager, complaintFilterParamsRegionManager, complaintFilterParamsTerritoryManager,
    complaintListAttributes,
    complaintQueryFilterParams
} from "./ComplaintConstants";
import {MenuNames} from "../../utils/Constants";
import {ButtonCreateNew} from "../shared/ButtonCreateNew";
import {getRouteCreate} from "../../utils/RouterUtils";
import {ButtonExport} from "../shared/ButtonExport";
import {RangeDateTimePicker} from "../shared/DateTime/RangeDateTimePicker";
import Utils from "../../utils/Utils";
import AuthBloc from "../../bloc/AuthBloc";
import {regionFilterParams} from "../region/RegionConstants";
import {areaFilterParams} from "../area/AreaConstants";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import {thanaFilterParams} from "../thana/ThanaConstants";

export class ComplaintListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("Complaint List")
        const query = new URLSearchParams(this.props.location.search);

        const from = query.get('from');
        const to = query.get('to');
        let dateRange = Utils.getDefaultDateRange(from, to)
        if(AuthBloc.instance.isTM()){
            query.set('territory_id', AuthBloc.instance.getUser().territory_manager.territories[0].territory_id);
            CrudBloc.instance.getList({status: 1, limit: 100, territory_id: AuthBloc.instance.getUser().territory_manager.territories[0].territory_id}, MenuNames.thana.lower, thanaFilterParams);
        }
        if(AuthBloc.instance.isRM()){
            query.set('region_id', AuthBloc.instance.getUser().region_manager.region.id);
            CrudBloc.instance.getList({status: 1, limit: 100, region_id: AuthBloc.instance.getUser().region_manager.region.id}, MenuNames.area.lower, areaFilterParams);
        }
        if(AuthBloc.instance.isAM()){
            query.set('area_id', AuthBloc.instance.getUser().area_manager.area.id);
            CrudBloc.instance.getList({status: 1, limit: 100, area_id: AuthBloc.instance.getUser().area_manager.area.id}, MenuNames.territory.lower, territoryFilterParams);
        }
        if(AuthBloc.instance.isPO()){
            if(AuthBloc.instance.getUser().program_owner.root_cause_type_id != null){
                query.set('root_cause_type_id', AuthBloc.instance.getUser().program_owner.root_cause_type_id);
            }else{
                query.set('program_owner_id', AuthBloc.instance.getUser().program_owner.id);
            }

        }
        CrudBloc.instance.getList(query, MenuNames.complaint.lower, complaintFilterParams, dateRange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        const query = new URLSearchParams(this.props.location.search);
        const from = query.get('from');
        const to = query.get('to');
        let dateRange = Utils.getDefaultDateRange(from, to)

        if(AuthBloc.instance.isTM()){
            query.set('territory_id', AuthBloc.instance.getUser().territory_manager.territories[0].territory_id);
        }
        if(AuthBloc.instance.isRM()){
            query.set('region_id', AuthBloc.instance.getUser().region_manager.region.id);
        }
        if(AuthBloc.instance.isAM()){
            query.set('area_id', AuthBloc.instance.getUser().area_manager.area.id);
        }
        if(AuthBloc.instance.isPO()){
            if(AuthBloc.instance.getUser().program_owner.root_cause_type_id != null){
                query.set('root_cause_type_id', AuthBloc.instance.getUser().program_owner.root_cause_type_id);
            }else{
                query.set('program_owner_id', AuthBloc.instance.getUser().program_owner.id);
            }

        }

        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(query, MenuNames.complaint.lower, complaintFilterParams, dateRange);
        }
    }

    onDateChanged = range => {
        const between = {from: range.from, to: range.to};
        const {history} = this.props;
        let pathName = this.props.location.pathname;
        let searchParams = new URLSearchParams(this.props.location.search);

        searchParams.set("from", between.from);
        searchParams.set("to", between.to);

        history.push({
            pathName: pathName,
            search: searchParams.toString()
        });
    };

    render() {

        return <div>
            <Row type="flex" justify={"space-between"}>
                <ButtonCreateNew to={getRouteCreate(MenuNames.complaint.lower)}/>
                <ButtonExport search={this.props.location.search} name={MenuNames.complaint.lower} param={complaintFilterParams}/>
                <Col span={6}>
                    <RangeDateTimePicker style={{height: 36}}
                                         onDateChanged={this.onDateChanged}
                                         defaultInterval={30}
                                         location={this.props.location}
                                         history={this.props.history}/>
                </Col>
                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {this.getParam()}
                    queryFilterParams = {complaintQueryFilterParams()}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.complaintList}
            builder = {(snapshot) => {
                
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={complaintListAttributes}
                    name={MenuNames.complaint.lower}
                />
            }}/>
        </div>
    }

    getParam() {

        if(AuthBloc.instance.isRM()){
            return complaintFilterParamsRegionManager;
        }else if(AuthBloc.instance.isAM()){
            return complaintFilterParamsAreaManager;
        }else if(AuthBloc.instance.isTM()){
            return complaintFilterParamsTerritoryManager;
        }else if(AuthBloc.instance.isPO()){
            return complaintFilterParamsManager;
        }else {
            return complaintFilterParams
        }
    }
}

