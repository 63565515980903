import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {MenuNames} from "../../utils/Constants";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {regionFilterParams} from "../region/RegionConstants";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import LocationBloc from "../../bloc/LocationBloc";
import {getId} from "../../utils/Utils";
import {areaFilterParams} from "../area/AreaConstants";

export class AreaManagerCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        //console.log_sms(props);

        this.state = {
            name: InputFields.name,
            email: InputFields.email,
            mobile: InputFields.mobile,
            region_id: InputFields.region_id,
            area_id: InputFields.area_id,
            status: InputFields.status
        };

        if(this.props.edit){
            this.prepareEdit()
        }else{
            this.prepareCreate()
        }

        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.getDetails(id, MenuNames.area_manager.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuNames.area_manager.lower);
        }
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams)

    }

    prepareEdit = () => {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("Area Manager Update");
        this.areaManagerSubcription = CrudBloc.instance.area_manager.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.getList({region_id: getId(x, InputFields.region_id.name), limit: 100}, MenuNames.area.lower, areaFilterParams)
                    LocationBloc.instance.onSelectLocation(MenuNames.region.upper, getId(x, InputFields.region_id.name));
                    LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.area_id);
                }
            },
        });
    }

    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.area_manager.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new Area Manager");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.area_manager.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }
    componentDidMount() {
        this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams)
                }
            },
        });
    }

    componentWillUnmount() {
        this.areaManagerSubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectRegionSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.area_manager.lower);
        }else{
            delete values['region_id'];
            CrudBloc.instance.createNew(values, MenuNames.area_manager.lower);
        }

    };

    render() {
        return (
            <IsLoading name={MenuNames.area_manager.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.area_manager}
                    builder = {(snapshot) => {
                        
                        //console.log_sms(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText
                                values={this.state.name}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : undefined}/>

                            <InputText
                                values={this.state.email}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.email.name] : undefined}/>
                            <InputText
                                values={this.state.mobile}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>

                            <BlocBuilder
                                subject = {CrudBloc.instance.regionList}
                                builder = {(snapshotRegion) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                            LocationBloc.instance.onSelectLocation(MenuNames.region.upper, id);
                                            this.formRef.current?.setFieldsValue({ area_id: undefined});
                                        }
                                        }
                                        values={this.state.region_id}
                                        value={this.props.edit && snapshot.data ?  getId(snapshot.data, this.state.region_id.name) : undefined}
                                        options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                    />
                                }}/>

                            <BlocBuilder
                                subject = {CrudBloc.instance.areaList}
                                builder = {(snapshotArea) => {
                                    return  <InputSelect
                                        values={this.state.area_id}
                                        options={snapshotArea.data ? snapshotArea.data.list : []}
                                        value={this.props.edit && snapshot.data ?  getId(snapshot.data, this.state.area_id.name) : undefined}
                                    />
                                }}/>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log_sms(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}