import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const outletQueryFilterParams = () => outletFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const outletFilterParams = [
  FilterInputItems.outlet_dms_code,
  FilterInputItems.name,
  FilterInputItems.mobile,
  // FilterInputItems.region_id,
  // FilterInputItems.area_id,
  // FilterInputItems.territory_id,
  // FilterInputItems.town_id,
  // FilterInputItems.status,
];
export const outletShortListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Distributor Type',
    field: 'distributor_type_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer Name',
    field: 'owner_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile',
    field: 'mobile',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const outletListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name (In Bengali)',
    field: 'bangla_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer Name',
    field: 'owner_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Channel Name',
    field: 'channel_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile (Primary)',
    field: 'mobile',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile (Secondary)',
    field: 'mobile_2',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile #3',
    field: 'mobile_3',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Is Verified',
    field: 'is_verified',
    type: tableContentType.STATUS,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const outletDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name (In Bengali)',
    field: 'bangla_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer Name',
    field: 'owner_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Channel Name',
    field: 'channel_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile (Primary)',
    field: 'mobile',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile (Secondary)',
    field: 'mobile_2',
    type: tableContentType.TEXT,
  },
  {
    title: 'Mobile #3',
    field: 'mobile_3',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Is Verified',
    field: 'is_verified',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

