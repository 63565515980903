import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthBloc from "./bloc/AuthBloc";
import {AdminRoute} from "./AdminRoute";
import LoginPage from "./components/auth/LoginPage";
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

AuthBloc.instance.isLoggedIn();
const root = ReactDOM.createRoot(document.getElementById('root'));
AuthBloc.instance.authToken.subscribe({
    next(x) {
        root.render(
            <React.StrictMode>
                <Router>
                    <AdminRoute path="*" exact component={App} isAuthenticated={!!x}/>
                         <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
                            <Route
                                path="/login"
                                exact
                                component={LoginPage}
                            />
                         </DevSupport>
                </Router>,
            </React.StrictMode>
        );
    },
    error(err) {
        console.error('something wrong occurred: ' + err);
    },
    complete() {
        console.log('done');
    }
});
// If you want to start measuring performance in your app, pass a function
// to log_sms results (for example: reportWebVitals(console.log_sms))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
