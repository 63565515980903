import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Row} from "antd";
import FilterPopup from "../shared/Filter/FilterPopup";
import {Box} from "../shared/Box";
import {
    outbound_callFilterParams,
    outbound_callListAttributes,
    outbound_callQueryFilterParams
} from "./OutboundCallConstants";
import {ButtonCreateNew} from "../shared/ButtonCreateNew";
import {getRouteCreate} from "../../utils/RouterUtils";
import {MenuNames} from "../../utils/Constants";
import {ButtonExport} from "../shared/ButtonExport";

export class OutboundCallListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("OutboundCall List")
        CrudBloc.instance.getList({...this.props.location.search, status:1}, MenuNames.outbound_call.lower, outbound_callFilterParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(this.props.location.search, MenuNames.outbound_call.lower, outbound_callFilterParams);
        }
    }

    render() {
        return <div>
            <Row type="flex" justify={"space-between"}>
                <ButtonCreateNew to={getRouteCreate(MenuNames.outbound_call.lower)}/>
                <ButtonExport search={this.props.location.search} name={MenuNames.outbound_call.lower} param={outbound_callFilterParams}/>
                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {outbound_callFilterParams}
                    queryFilterParams = {outbound_callQueryFilterParams()}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.outbound_callList}
            builder = {(snapshot) => {
                //
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={outbound_callListAttributes}
                    name={MenuNames.outbound_call.lower}
                />
            }}/>
        </div>
    }
}

