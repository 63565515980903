import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {store_typeDetailsAttributes} from "./StoreTypeConstants";
import {MenuNames} from "../../utils/Constants";

export class StoreTypeDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("StoreType Details")
        CrudBloc.instance.getDetails(id, MenuNames.store_type.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.store_type}
            builder = {(snapshot) => {
                
                return  <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={snapshot.data ? snapshot.data : null}
                    isFetching={false}
                    isFailed={false}
                    attributes={store_typeDetailsAttributes}
                    name={MenuNames.store_type.lower}
                />
            }}/>
    }
}

