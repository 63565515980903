import React from "react";
import {Link} from "react-router-dom";
import "./itemAction.css";
import {Dropdown, Popconfirm} from 'antd';
import {FileExcelOutlined, MoreOutlined} from "@ant-design/icons";
import axiosWrapper from "../../../utils/AxiosWrapper";
import {BASEURL} from "../../../utils/Constants";



export function TableRowAction ({actions}) {

  const items = [];
  actions.forEach( action => items.push
      ({
        key: action.title,
        label: (action.title === "Delete" ?
            <Popconfirm title="Sure to delete?" onConfirm={() =>  {
                console.log(action);
                axiosWrapper
                    .delete(BASEURL+ `${action.url}`)
                    .then((response) => {
                        console.log(response)
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }}>
                <a>Delete</a>
            </Popconfirm> : <Link key={action.title} to={action.url}> {action.icon ? <FileExcelOutlined /> : null} {action.title}</Link>)
      })
  )

    // const handleMenuClick = (e) => {
    //     console.log_sms('click', e.domEvent.target.href);
    //     if(e.key === "Delete"){
    //
    //     }
    //
    // };

    // const menuProps = {
    //     items,
    //     onClick: handleMenuClick,
    // };

  return (
      actions.length === 0 ? <div/> :

          <Dropdown menu={{items}} placement="bottomLeft">
            <a><MoreOutlined/></a>
          </Dropdown>
  );
}
