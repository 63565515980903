import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const program_timelineQueryFilterParams = () => program_timelineFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const program_timelineFilterParams = [
  FilterInputItems.id,
  FilterInputItems.title,
  FilterInputItems.program,
  FilterInputItems.year,
  FilterInputItems.status
];

export const program_timelineListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Title',
    field: 'title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Value',
    field: 'value',
    type: tableContentType.TEXT,
  },
  {
    title: 'Program',
    field: 'program',
    type: tableContentType.TEXT,
  },
  {
    title: 'Year',
    field: 'year',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const program_timelineDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Title',
    field: 'title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Value',
    field: 'value',
    type: tableContentType.TEXT,
  },
  {
    title: 'Program',
    field: 'program',
    type: tableContentType.TEXT,
  },
  {
    title: 'Year',
    field: 'year',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

