import React, { useEffect, useState } from "react";
import {Button, Col, Form, Row, Select, message} from "antd";
import BlocBuilder from "bloc-builder-react/src";
import CrudBloc from "../../bloc/CrudBloc";
import {BASEURL, MenuNames} from "../../utils/Constants";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from "uuid";
import axiosWrapper from "../../utils/AxiosWrapper";
import { useParams } from "react-router-dom";
import {regionFilterParams} from "../region/RegionConstants";
import _ from 'lodash';
import {getRouteList} from "../../utils/RouterUtils";


const {Option} = Select;

const TerritoryManagerLocationUpdate = () => {
    const [form] = Form.useForm();
    const [areaList, setAreaList] = useState([]);
    const [territoryList, setTerritoryList] = useState([]);
    const [initialLocation, setInitialLocation] = useState({})
    const { id } = useParams();


    const onFinish = (values) => {
        console.log('Received values of form:', values);
        axiosWrapper
            .put(BASEURL+ `/territory_manager_location/update/${id}`, {territory_manager_id: initialLocation[`tm_id`], territory_id: values['territory_id'] })
            .then((response) => {
                console.log(response.data.data)
                this.props.history.push(`territory_manager/update/${initialLocation[`tm_id`]}`);
            })
            .catch((error) => {
                console.log(error)
                message.error('Territory already assigned to another territory manager');
            });
    };

    useEffect(() => {
        getLocationInfo();
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
    },[id])

    function getLocationInfo(){
        console.log(id)
        axiosWrapper
            .get(BASEURL+ `/territory_manager_location/${id}`)
            .then((response) => {
                console.log(response.data.data)
                const e = response.data.data;
                //setAreaList([])
                //setAreaList(response.data.data)
                //console.log_sms(areaList)

                initialLocation[`region_id`] = e.territory.area.region_id
                initialLocation[`area_id`] = e.territory.area_id
                initialLocation[`territory_id`] = e.territory_id
                initialLocation[`tm_id`] = e.territory_manager_id

                setInitialLocation({})
                setInitialLocation(initialLocation)

                getAreaList(e.territory.area.region_id )
                getTerritoryList(e.territory.area_id)

                console.log(initialLocation)


            })
            .catch((error) => {

            });
    }


    function getAreaList(id) {
        axiosWrapper
            .get(BASEURL+ `/area/index?skip=0&limit=100&region_id=${id}&status=1`)
            .then((response) => {

                setAreaList([])
                setAreaList(response.data.data)
                console.log(areaList)
            })
            .catch((error) => {

            });
    }

    function getTerritoryList(id) {
        axiosWrapper
            .get(BASEURL+ `/territory/index?skip=0&limit=100&area_id=${id}&status=1`)
            .then((response) => {

                setTerritoryList([])
                setTerritoryList(response.data.data)

                console.log(territoryList);
            })
            .catch((error) => {

            });
    }

    return (
        _.isEmpty(initialLocation) === false && <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            className="create-form-full"
        >
            <Row gutter={16}>
                <Col span={7}>
                    <BlocBuilder
                        subject = {CrudBloc.instance.regionList}
                        builder = {(snapshotRegion) => {

                            const region = (snapshotRegion.data ? snapshotRegion.data.list : []).map((e, i)=><Option key={e.id+ uuidv4()} value={e.id}>{e.title}</Option>)
                            return <Form.Item
                                initialValue={initialLocation[`region_id`]}
                                name={'region_id'}
                                label="Region"
                                rules={[{ required: true, message: 'Missing region' }]}>
                                <Select
                                    placeholder={"Select"}
                                    allowClear={true}
                                    onChange={(id)=>{
                                        console.log(id);
                                        getAreaList(id)
                                        form.setFieldValue('area_id', null);
                                        form.setFieldValue('territory_id', null);

                                    }}> {region}</Select>
                            </Form.Item>
                        }}/>
                </Col>
                <Col span={7}>
                    <Form.Item
                        initialValue={initialLocation[`area_id`]}
                        name={'area_id'}
                        label="Area"
                        rules={[{ required: true, message: 'Missing area' }]}>
                        <Select
                            placeholder={"Select"}
                            allowClear={true}
                            onChange={(id)=>{
                                console.log(id);
                                getTerritoryList(id)
                                form.setFieldValue('territory_id', null);

                            }}> {(areaList ?? []).map((e, i)=><Option key={e.id+ uuidv4()} value={e.id}>{e.title} </Option>) } </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        initialValue={initialLocation[`territory_id`]}
                        name={'territory_id'}
                        label="Territory"
                        rules={[{ required: true, message: 'Missing area' }]}>
                        <Select
                            placeholder={"Select"}
                            allowClear={true}
                            onChange={(id)=>{

                            }}> {(territoryList ?? []).map((e, i)=><Option key={e.id+ uuidv4()} value={e.id}>{e.title} </Option>) }</Select>
                    </Form.Item>
                </Col>

            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default TerritoryManagerLocationUpdate;