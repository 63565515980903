import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Row} from "antd";
import FilterPopup from "../shared/Filter/FilterPopup";
import {
    program_perfect_storeFilterParams,
    program_perfect_storeListAttributes,
    program_perfect_storeQueryFilterParams
} from "./ProgramPerfectStoreConstants";
import {Box} from "../shared/Box";
import {MenuNames} from "../../utils/Constants";
import {ButtonExport} from "../shared/ButtonExport";

export class ProgramPerfectStoreListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("ProgramPerfectStore List")
        CrudBloc.instance.getList(this.props.location.search, MenuNames.program_perfect_store.lower, program_perfect_storeFilterParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(this.props.location.search, MenuNames.program_perfect_store.lower, program_perfect_storeFilterParams);
        }
    }

    render() {
        return <div>
            <Row type="flex" justify={"space-between"}>
                {/*<ButtonCreateNew to={getRouteCreate(MenuNames.program_perfect_store.lower)}/>*/}

                <ButtonExport search={this.props.location.search} name={MenuNames.program_perfect_store.lower} param={program_perfect_storeFilterParams}/>

                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {program_perfect_storeFilterParams}
                    queryFilterParams = {program_perfect_storeQueryFilterParams()}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.program_perfect_storeList}
            builder = {(snapshot) => {
                
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={program_perfect_storeListAttributes}
                    name={MenuNames.program_perfect_store.lower}
                />
            }}/>
        </div>
    }
}

