import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const program_perfect_storeQueryFilterParams = () => program_perfect_storeFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const program_perfect_storeFilterParams = [
  FilterInputItems.outlet_dms_code,
  FilterInputItems.year,
  FilterInputItems.timeline
];

export const program_perfect_storeListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Outlet DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Payout',
    field: 'payout',
    type: tableContentType.TEXT,
  },
  {
    title: 'Timeline',
    field: 'time',
    type: tableContentType.TEXT,
  },
  {
    title: 'year',
    field: 'year',
    type: tableContentType.TEXT,
  },
  {
    title: 'Last Updated',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const program_perfect_storeDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Face',
    field: 'face',
    type: tableContentType.TEXT,
  },
  {
    title: 'Hair',
    field: 'hair',
    type: tableContentType.TEXT,
  },
  {
    title: 'Oral',
    field: 'oral',
    type: tableContentType.TEXT,
  },
  {
    title: 'Menz',
    field: 'menz',
    type: tableContentType.TEXT,
  },
  {
    title: 'Payout',
    field: 'payout',
    type: tableContentType.TEXT,
  },
  {
    title: 'Timeline',
    field: 'time',
    type: tableContentType.TEXT,
  },
  {
    title: 'year',
    field: 'year',
    type: tableContentType.TEXT,
  },
  {
    title: 'Last Updated',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

