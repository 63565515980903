import React from 'react';
import {Column} from '@ant-design/charts';
import {Card, Col, Tag} from "antd";
import {Box} from "./Box";
import RedirectBloc from "../../bloc/RedirectBloc";

export class BarChartStackedComplaintType extends React.Component {
    render() {
        const {header, data} = this.props;



        let x = []
        if(data){
                data.value.forEach(value => {
                    x.push({link: value.link, title: value.title, value: parseInt(value.escalated), type: 'Escalated'});
                    x.push({link: value.link, title: value.title, value: parseInt(value.pending), type: 'Pending'});
                    x.push({link: value.link, title: value.title, value: parseInt(value.re_open), type: 'Re open'});
                    x.push({link: value.link, title: value.title, value: parseInt(value.exceeded), type: 'Exceeded'});
                    x.push({link: value.link, title: value.title, value: parseInt(value.solved), type: 'Resolved'});

                })
        }

        let config = {
            data: x,
            isStack: true,
            xField: 'title',
            yField: 'value',
            seriesField: 'type',
            label: {

                position: 'middle',
                // 'left', 'middle', 'right'
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
            },
            xAxis: {
                label: {
                    autoRotate: true,
                    autoHide: false,
                    autoEllipsis: false,
                },
            },
            onReady: (plot) => {
                plot.on('element:click', (...args) => {
                    RedirectBloc.instance.redirectTo(args[0].data.data.link);
                });
            }
        };

        return <Card><Col>
            <Tag className={"chart-tag"} color="green">{header}</Tag>
            <Box y={32}/>
            <Column {...config} />
        </Col></Card>;
    }
}

