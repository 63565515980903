import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const complaintQueryFilterParams = () => complaintFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const complaintFilterParams = [
  FilterInputItems.id,
  FilterInputItems.call_record_id,
  FilterInputItems.call_type_id,
  FilterInputItems.complain_type_id,
  FilterInputItems.region_id,
  FilterInputItems.area_id,
  FilterInputItems.territory_id,
  FilterInputItems.town_id,
  FilterInputItems.root_cause_type_id,
  FilterInputItems.complaint_status,
  FilterInputItems.sticker_status

];
export const complaintFilterParamsManager = [
  FilterInputItems.id,
  FilterInputItems.call_record_id,
  FilterInputItems.call_type_id,
  FilterInputItems.complain_type_id,
  FilterInputItems.complaint_status,
  FilterInputItems.sticker_status
];

export const complaintFilterParamsRegionManager = [
  FilterInputItems.id,
  FilterInputItems.call_record_id,
  FilterInputItems.call_type_id,
  FilterInputItems.complain_type_id,
  FilterInputItems.area_id,
  FilterInputItems.territory_id,
  FilterInputItems.town_id,
  FilterInputItems.complaint_status,
  FilterInputItems.sticker_status
];

export const complaintFilterParamsAreaManager = [
  FilterInputItems.id,
  FilterInputItems.call_record_id,
  FilterInputItems.call_type_id,
  FilterInputItems.complain_type_id,
  FilterInputItems.territory_id,
  FilterInputItems.town_id,
  FilterInputItems.complaint_status,
  FilterInputItems.sticker_status
];

export const complaintFilterParamsTerritoryManager = [
  FilterInputItems.id,
  FilterInputItems.call_record_id,
  FilterInputItems.call_type_id,
  FilterInputItems.complain_type_id,
  FilterInputItems.town_id,
  FilterInputItems.complaint_status,
  FilterInputItems.sticker_status
];

export const complaintShortListAttributes = [
  {
    title: 'Complaint ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Call Record ID',
    field: 'call_record_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Complaint Register Date',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name',
    field: 'outlet_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer\'s Verbatim',
    field: 'remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.COMPLAINT_STATUS,
  },
  {
    title: 'TCL Sticker Status',
    field: 'sticker_status',
    type: tableContentType.STICKER_STATUS,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const complaintListAttributes = [
  {
    title: 'Complaint ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Call Record ID',
    field: 'call_record_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Complaint Register Date',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name',
    field: 'outlet_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer\'s Verbatim',
    field: 'remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Action of TM',
    field: 'tm_remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.COMPLAINT_STATUS,
  },
  {
    title: 'TCL Sticker Status',
    field: 'sticker_status',
    type: tableContentType.STICKER_STATUS,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const complaintDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Call Record ID',
    field: 'call_record_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Complaint Register Date',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'DMS Code',
    field: 'outlet_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Outlet Name',
    field: 'outlet_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Address',
    field: 'address',
    type: tableContentType.TEXT,
  },
  {
    title: 'Contact',
    field: 'mobile',
    type: tableContentType.TEXT,
  },
  {
    title: 'Contact#2',
    field: 'mobile_2',
    type: tableContentType.TEXT,
  },
  {
    title: 'Contact#3',
    field: 'mobile_3',
    type: tableContentType.TEXT,
  },
  {
    title: 'Retailer\'s Verbatim',
    field: 'remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory Manager',
    field: 'tm_name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Action of TM',
    field: 'tm_remark',
    type: tableContentType.TEXT,
  },
  {
    title: 'Complaint Type',
    field: 'complain_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Call Type',
    field: 'call_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Root Cause Type',
    field: 'root_cause_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Root Cause',
    field: 'root_cause_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Distributor Type',
    field: 'distributor_type',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Town',
    field: 'town_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Thana',
    field: 'thana_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.COMPLAINT_STATUS,
  },
  {
    title: 'TCL Sticker Status',
    field: 'sticker_status',
    type: tableContentType.STICKER_STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

