import axiosWrapper from "../utils/AxiosWrapper";
import {BASEURL} from "../utils/Constants";
import ErrorBloc from "./ErrorBloc";
import * as rxjs from "rxjs";
import FilterInputItems from "../components/shared/Filter/FilterInputItems";

export default class ComplaintBloc{

    static instance = new ComplaintBloc();

    constructor(){

        this.complaintCount = new rxjs.BehaviorSubject(null);

        this.complaintCountTerritoryYTD = new rxjs.BehaviorSubject(null);
        this.complaintCountTerritoryMTD = new rxjs.BehaviorSubject(null);

        this.complaintCountRegionYTD = new rxjs.BehaviorSubject(null);
        this.complaintCountRegionMTD = new rxjs.BehaviorSubject(null);

        this.complaintCountAreaYTD = new rxjs.BehaviorSubject(null);
        this.complaintCountAreaMTD = new rxjs.BehaviorSubject(null);

        this.complaintCountProgramOwnerYTD = new rxjs.BehaviorSubject(null);
        this.complaintCountProgramOwnerMTD = new rxjs.BehaviorSubject(null);

        this.complaintCountAllRegion = new rxjs.BehaviorSubject(null);
        this.complaintCountAllArea = new rxjs.BehaviorSubject(null);

        this.complaintCountCallType = new rxjs.BehaviorSubject(null);

        this.complaintCurrentTM = new rxjs.BehaviorSubject(null);
        this.complaintCurrentDistributor = new rxjs.BehaviorSubject(null);

        this.complaintCurrentStatus = new rxjs.BehaviorSubject(null);
        this.complaintCurrentStickerStatus = new rxjs.BehaviorSubject(null);

        this.complaintTownByAMy = new rxjs.BehaviorSubject(null);
        this.complaintTownByRMy = new rxjs.BehaviorSubject(null);

        this.complaintTownByAMm = new rxjs.BehaviorSubject(null);
        this.complaintTownByRMm = new rxjs.BehaviorSubject(null);

        this.complaintTownByTMy = new rxjs.BehaviorSubject(null);
        this.complaintTownByTMm = new rxjs.BehaviorSubject(null);

        this.complaintByComplaintsTypeYTD = new rxjs.BehaviorSubject(null);
        this.complaintByComplaintsTypeMTD = new rxjs.BehaviorSubject(null);


    }



    getComplaintStatisticsByRegion(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_rm?region_id=${id}&from=${from}&to=${to}`)
            .then((response) => {
                if(isYTD) {
                    this.complaintCountRegionYTD.next(response.data)
                }else{
                    this.complaintCountRegionMTD.next(response.data)
                }
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsTownByRegion(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_rm_town?region_id=${id}&from=${from}&to=${to}`)
            .then((response) => {

                if(isYTD) {
                    this.complaintTownByRMy.next(response.data)
                }else{
                    this.complaintTownByRMm.next(response.data)
                }
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsByComplaintsType(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complain_type?${id}&from=${from}&to=${to}`)
            .then((response) => {

                if(isYTD) {
                    this.complaintByComplaintsTypeYTD.next(response.data)
                }else{
                    this.complaintByComplaintsTypeMTD.next(response.data)
                }
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
            });
    }

    getComplaintStatisticsByArea(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_am?area_id=${id}&from=${from}&to=${to}`)
            .then((response) => {

                if(isYTD) {
                    this.complaintCountAreaYTD.next(response.data)
                }else{
                    this.complaintCountAreaMTD.next(response.data)
                }
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }

    getComplaintStatisticsTownByArea(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_am_town?area_id=${id}&from=${from}&to=${to}`)
            .then((response) => {

                if(isYTD) {
                    this.complaintTownByAMy.next(response.data)
                }else{
                    this.complaintTownByAMm.next(response.data)
                }
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsByTerritory(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_territory?territory_id=${id}&from=${from}&to=${to}`)
            .then((response) => {
                if(isYTD){
                    this.complaintCountTerritoryYTD.next(response.data)
                }else{
                    this.complaintCountTerritoryMTD.next(response.data)
                }

                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }

    getComplaintStatisticsTownByTerritory(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_tm_town?territory_id=${id}&from=${from}&to=${to}`)
            .then((response) => {
                if(isYTD){
                    this.complaintTownByTMy.next(response.data)
                }else{
                    this.complaintTownByTMm.next(response.data)
                }

                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsByProgramOwner(id, from, to, isYTD){

        axiosWrapper
            .get(BASEURL+ `/statistics/complaint_po?po_id=${id}&from=${from}&to=${to}`)
            .then((response) => {
                if(isYTD){
                    this.complaintCountProgramOwnerYTD.next(response.data)
                }else{
                    this.complaintCountProgramOwnerMTD.next(response.data)
                }
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }

    getComplaintStatistics(){
        //LoadingBloc.instance.start(name);
        axiosWrapper
            .get(BASEURL+ "/statistics/complaint")
            .then((response) => {
                this.complaintCount.next(response.data)
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsRegion(){
        //LoadingBloc.instance.start(name);
        axiosWrapper
            .get(BASEURL+ "/statistics/complaint_region")
            .then((response) => {
                this.complaintCountAllRegion.next(response.data)
                //this.complaintCount.next(response.data)
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsArea(){
        //LoadingBloc.instance.start(name);
        axiosWrapper
            .get(BASEURL+ "/statistics/complaint_area")
            .then((response) => {
                this.complaintCountAllArea.next(response.data)
                //this.complaintCount.next(response.data)
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }
    getComplaintStatisticsCallType(){
        //LoadingBloc.instance.start(name);
        axiosWrapper
            .get(BASEURL+ "/statistics/complain_call_type")
            .then((response) => {
                this.complaintCountCallType.next(response.data)
                //this.complaintCount.next(response.data)
                //LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                //LoadingBloc.instance.end(name);
            });
    }

}
export const filterParams = [
    FilterInputItems.id,
    FilterInputItems.title,
    FilterInputItems.status
];