import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {areaFilterParams} from "../area/AreaConstants";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {FormSkeleton} from "../shared/FormSkeleton";
import {IsLoading} from "../shared/IsLoading";
import {regionFilterParams} from "../region/RegionConstants";
import {getId} from "../../utils/Utils";

export class TerritoryCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            title: InputFields.title,
            region_id: InputFields.region_id,
            area_id: InputFields.area_id,
            status: InputFields.status
        };

        if(this.props.edit){
            this.prepareEdit()
        }else{
            this.prepareCreate()
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
        CrudBloc.instance.clearList(MenuNames.area.lower);

    }

    prepareEdit = () => {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("Territory Update");
        this.territorySubcription = CrudBloc.instance.territory.subscribe({
            next(x) {
                console.log(x)
                if (x){
                    console.log("territory details")
                    CrudBloc.instance.getList({region_id: x.region_id, limit: 100}, MenuNames.area.lower, areaFilterParams)
                    LocationBloc.instance.onSelectLocation(MenuNames.region.upper, x.area?.region_id);
                    LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.area_id);
                }
            },
        });
        CrudBloc.instance.getDetails(id, MenuNames.territory.lower);
    }

    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.territory.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new Territory");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.territory.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }

    componentDidMount() {
        this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams)
                }
            },
        });

    }
    componentWillUnmount() {
        this.territorySubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectRegionSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        delete values["region_id"];
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.territory.lower);
        }else{
            CrudBloc.instance.createNew(values, MenuNames.territory.lower);
        }

    };

    render() {
        return (
            <IsLoading name={MenuNames.territory.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.territory}
                    builder = {(snapshot) => {
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText
                                values={this.state.title}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : undefined}/>
                            <BlocBuilder
                                subject = {CrudBloc.instance.regionList}
                                builder = {(snapshotRegion) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                                LocationBloc.instance.onSelectLocation(MenuNames.region.upper, id);
                                                this.formRef.current?.setFieldsValue({ area_id: undefined});
                                            }
                                        }
                                        values={this.state.region_id}
                                        value={this.props.edit && snapshot.data ?  getId(snapshot.data, this.state.region_id.name) : undefined}
                                        options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                    />
                                }}/>

                            <BlocBuilder
                                subject = {CrudBloc.instance.areaList}
                                builder = {(snapshotArea) => {
                                    return  <InputSelect
                                        values={this.state.area_id}
                                        options={snapshotArea.data ? snapshotArea.data.list : []}
                                        value={this.props.edit && snapshot.data ?  getId(snapshot.data, this.state.area_id.name) : undefined}
                                    />
                                }}/>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log_sms(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}