export const BASEURL = process.env.REACT_APP_BASE_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const APP_NAME = process.env.REACT_APP_NAME;
const statusList = [ 'Disabled', 'Enabled'];
export const statusDropdownOptions = statusList.map((e,i) => ({key: e, text: e, value: i}));
export const getStatusTitle = (i) => statusList[i];

export const SKIP = 10;
export const LIMIT = 10;

export const MenuNames = {
    home: { lower: "dashboard", upper: "Home"},
    region: { lower: "region", upper: "Region"},
    area: { lower: "area", upper: "Area"},
    town: { lower: "town", upper: "Town"},
    user: { lower: "user", upper: "User"},
    user_group: { lower: "user_group", upper: "UserGroup"},
    brand: {lower: "brand", upper: "Brand"},
    call_type: {lower: "call_type", upper: "CallType"},
    category: {lower: "category", upper: "Category"},
    market_channel: {lower: "market_channel", upper: "MarketChannel"},
    root_cause_type: {lower: "root_cause_type", upper: "RootCauseType"},
    store_type: {lower: "store_type", upper: "StoreType"},
    thana: {lower: "thana", upper: "Thana"},
    territory: {lower: "territory", upper: "Territory"},
    complain_type: {lower: "complain_type", upper: "ComplainType"},
    root_cause: {lower: "root_cause", upper: "RootCause"},
    region_manager: {lower: "region_manager", upper: "RegionManager"},
    area_manager: {lower: "area_manager", upper: "AreaManager"},
    territory_manager: {lower: "territory_manager", upper: "TerritoryManager"},
    territory_manager_location: {lower: "territory_manager_location", upper: "TerritoryManagerLocation"},
    program_owner: {lower: "program_owner", upper: "ProgramOwner"},
    program_dosti: {lower: "program_dosti", upper: "ProgramDosti"},
    program_top_gun: {lower: "program_top_gun", upper: "ProgramTopGun"},
    program_perfect_store: {lower: "program_perfect_store", upper: "ProgramPerfectStore"},
    distributor: {lower: "distributor", upper: "Distributor"},
    program_timeline: {lower: "program_timeline", upper: "ProgramTimeline"},
    outlet: {lower: "outlet", upper: "Outlet"},
    complaint: {lower: "complaint", upper: "Complaint"},
    product_sku: {lower: "product_sku", upper: "ProductSKU"},
    outbound_call: {lower: "outbound_call", upper: "OutboundCall"},
    outbound_call_mode: {lower: "outbound_call_mode", upper: "OutboundCallMode"},
    outbound_call_category: {lower: "outbound_call_category", upper: "OutboundCallCategory"},
    outbound_change_request: {lower: "outbound_change_request", upper: "OutboundChangeRequests"},
    outbound_call_status: {lower: "outbound_call_status", upper: "OutboundCallStatus"},
    sms_log: {lower: "sms_log", upper: "SMSLog"},
    notification_log: {lower: "notification_log", upper: "NotificationLog"},
}

