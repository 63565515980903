import React from "react";
import BlocBuilder from "bloc-builder-react/src";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {areaFilterParams} from "../area/AreaConstants";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {regionFilterParams} from "../region/RegionConstants";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import {townFilterParams} from "../town/TownConstants";
import {getId, getTownDMSCode} from "../../utils/Utils";
import {IsLoading} from "../shared/IsLoading";
import {InputTextArea} from "../shared/FormComponent/InputTextArea";
import {market_channelFilterParams} from "../market_channel/MarketChannelConstants";

export class OutletUpdatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            outlet_dms_code: InputFields.outlet_dms_code,
            name: InputFields.name,
            bangla_name: InputFields.bangla_name,
            owner_name: InputFields.owner_name,
            address: InputFields.address,
            mobile: InputFields.mobile,
            mobile_2: InputFields.mobile_2,
            mobile_3: InputFields.mobile_3,
            region_id: InputFields.region_id,
            area_id: InputFields.area_id,
            territory_id: InputFields.territory_id,
            town_id: InputFields.town_id,
            town_dms_code: InputFields.town_dms_code,
            channel_id: InputFields.channel_id,
            distributor_type_id: InputFields.distributor_type_id,
            status: InputFields.status,
            is_verified: InputFields.is_verified
        };

        this.prepareEdit()

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.region.lower, regionFilterParams);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.market_channel.lower, market_channelFilterParams);
        CrudBloc.instance.clearList(MenuNames.area.lower);
        CrudBloc.instance.clearList(MenuNames.territory.lower);
        CrudBloc.instance.clearList(MenuNames.town.lower);

    }

    prepareEdit = () => {
        const outlet = new URLSearchParams(this.props.location.search).get("outlet")

        this.outletSubcription = CrudBloc.instance.outlet.subscribe({
            next(x) {
                if (x){

                    CrudBloc.instance.getList({region_id: getId(x, InputFields.region_id.name ), status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                    CrudBloc.instance.getList({area_id: getId(x, InputFields.area_id.name ), status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                    CrudBloc.instance.getList({territory_id: getId(x, InputFields.territory_id.name ), status: 1, limit: 100}, MenuNames.town.lower, townFilterParams);
                    // LocationBloc.instance.onSelectLocation(MenuNames.region.upper, x.region_id);
                    // LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.area_id);
                    // LocationBloc.instance.onSelectLocation(MenuNames.territory.upper, x.area_id);
                    // LocationBloc.instance.onSelectLocation(MenuNames.town.upper, x.area_id);

                }
            },
        });
        if(outlet)
        CrudBloc.instance.getDetails(outlet, MenuNames.outlet.lower);
    }


    componentDidMount() {

        this.selectRegionSubscription = LocationBloc.instance.selectedRegion.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.getList({region_id: x, status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams)
                }
            },
        });
        this.selectAreaSubscription = LocationBloc.instance.selectedArea.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.territory.lower);
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.getList({area_id: x, status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams)
                }
            },
        });
        this.selectTerritorySubscription = LocationBloc.instance.selectedTerritory.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.town.lower);
                    CrudBloc.instance.getList({territory_id: x, status: 1, limit: 100}, MenuNames.town.lower, townFilterParams)
                }
            },
        });

    }


    componentWillUnmount() {
        
        this.outletSubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectRegionSubscription?.unsubscribe();
        this.selectAreaSubscription?.unsubscribe();
        this.selectTerritorySubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        delete values["region_id"];
        delete values["area_id"];
        delete values["territory_id"];
        const outlet = new URLSearchParams(this.props.location.search).get("outlet")
        CrudBloc.instance.update(outlet, values, MenuNames.outlet.lower);

    };

    render() {
        return (
            <IsLoading name={MenuNames.outlet.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.outlet}
                    builder = {(snapshot) => {
                        return   snapshot.data ?
                            <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form-full">
                            <Row gutter={16}>
                                <Col span={8}>
                                <InputText
                                    values={this.state.name}
                                    value={ snapshot.data ? snapshot.data[this.state.name.name] : undefined}/>
                            </Col>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.bangla_name}
                                        value={ snapshot.data ? snapshot.data[this.state.bangla_name.name] : undefined}/>
                                </Col>
                                <Col span={8}>
                                <InputText
                                    values={this.state.owner_name}
                                    value={ snapshot.data ? snapshot.data[this.state.owner_name.name] : undefined}/>
                            </Col></Row>
                            <InputTextArea
                                values={this.state.address}
                                value={ snapshot.data ? snapshot.data[this.state.address.name] : undefined}/>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <InputText
                                        disabled = {true}
                                        values={this.state.outlet_dms_code}
                                        value={ snapshot.data ? snapshot.data[this.state.outlet_dms_code.name] : undefined}/>
                                </Col>
                                <Col span={6}>
                                    <InputText
                                        values={this.state.mobile}
                                        value={ snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>
                                </Col><Col span={6}>
                                <InputText
                                    values={this.state.mobile_2}
                                    value={ snapshot.data ? snapshot.data[this.state.mobile_2.name] : undefined}/>
                            </Col><Col span={6}>
                                <InputText
                                    values={this.state.mobile_3}
                                    value={ snapshot.data ? snapshot.data[this.state.mobile_3.name] : undefined}/>
                            </Col></Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                <BlocBuilder
                                    subject = {CrudBloc.instance.regionList}
                                    builder = {(snapshotRegion) => {
                                        return  <InputSelect
                                            onSelect={(id)=> {
                                                LocationBloc.instance.onSelectLocation(MenuNames.region.upper, id);
                                                this.formRef.current?.setFieldsValue({ area_id: undefined});
                                                this.formRef.current?.setFieldsValue({ territory_id: undefined});
                                                this.formRef.current?.setFieldsValue({ town_id: undefined});
                                            }
                                            }
                                            value={ snapshot.data ? getId(snapshot.data, this.state.region_id.name) : undefined}
                                            values={this.state.region_id}
                                            options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                        />
                                    }}/>
                                </Col><Col span={8}>
                                <BlocBuilder
                                    subject = {CrudBloc.instance.areaList}
                                    builder = {(snapshotArea) => {
                                        return  <InputSelect
                                            onSelect={(id)=> {
                                                LocationBloc.instance.onSelectLocation(MenuNames.area.upper, id);
                                                this.formRef.current?.setFieldsValue({ territory_id: undefined});
                                            }
                                            }
                                            values={this.state.area_id}
                                            options={snapshotArea.data ? snapshotArea.data.list : []}
                                            value={ snapshot.data ? getId(snapshot.data, this.state.area_id.name) : undefined}
                                        />
                                    }}/>
                                </Col><Col span={8}>
                                <BlocBuilder
                                    subject = {CrudBloc.instance.territoryList}
                                    builder = {(snapshotTerritory) => {
                                        return  <InputSelect
                                            onSelect={(id)=> {
                                                LocationBloc.instance.onSelectLocation(MenuNames.territory.upper, id);
                                                this.formRef.current?.setFieldsValue({ town_id: undefined});
                                            }}
                                            values={this.state.territory_id}
                                            options={snapshotTerritory.data ? snapshotTerritory.data.list : []}
                                            value={ snapshot.data ? getId(snapshot.data, this.state.territory_id.name) : undefined}
                                        />
                                    }}/>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                <BlocBuilder
                                    subject = {CrudBloc.instance.townList}
                                    builder = {(snapshotTown) => {
                                        return  <InputSelect
                                            onSelect={(id)=> {

                                                this.formRef.current?.setFieldsValue({ town_dms_code: getTownDMSCode(id, snapshotTown.data ? snapshotTown.data.list : [])});
                                            }}
                                            values={this.state.town_id}
                                            options={snapshotTown.data ? snapshotTown.data.list : []}
                                            value={ snapshot.data ? snapshot.data.town?.id : undefined}
                                        />
                                    }}/>
                                </Col><Col span={8}>
                            <InputText
                                disabled = {true}
                                values={this.state.town_dms_code}
                                value={ snapshot.data ? snapshot.data.town_dms_code : undefined}/>
                            </Col><Col span={8}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.market_channelList}
                                builder = {(snapshotMarketChannel) => {
                                    return  <InputSelect
                                        values={this.state.channel_id}
                                        options={snapshotMarketChannel.data ? snapshotMarketChannel.data.list : []}
                                        value={ snapshot.data ? getId(snapshot.data, this.state.channel_id.name) : undefined}
                                    />
                                }}/>
                            </Col></Row>
                            <Row gutter={16}>
                                <Col span={8}>
                            <InputSelect
                                values={this.state.distributor_type_id}
                                options={InputFieldOptions.distributor_type_id}
                                value={ snapshot.data ? snapshot.data[this.state.distributor_type_id.name] : 1}
                            />
                                </Col><Col span={8}>
                            {/*<InputSelect*/}
                            {/*    values={this.state.is_verified}*/}
                            {/*    options={InputFieldOptions.is_action_taken}*/}
                            {/*    value={ snapshot.data ? snapshot.data[this.state.is_verified.name] : 1}*/}
                            {/*/>*/}
                            </Col><Col span={8}>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={ snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            </Col></Row>
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log_sms(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> :
                            <TextX text={"Select a outlet to update"} size={20}/>
                    }}/>
            </IsLoading>
        );
    }
}