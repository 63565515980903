import {InputSelect} from "../components/shared/FormComponent/InputSelect";
import BlocBuilder from "bloc-builder-react/src";

export function ListSelect({config, value, onSelect, stream, name, isFilter, onClear}){
    return <BlocBuilder
        subject = {stream}
        builder = {(isLoading) => {
            return <BlocBuilder
                subject = {stream}
                builder = {(snapshot) => {
                return <InputSelect
                    menuName={name}
                    onClear={onClear}
                    isFilter={isFilter}
                    isFetching={isLoading.data && name && isLoading.data[name]}
                    values={config}
                    options={snapshot.data ? snapshot.data.list : []}
                    value={ value}
                    onSelect={onSelect}
                />
            }}/>
        }}/>
}

