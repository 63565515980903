import React from "react";
import {ButtonX} from "./ButtonX";
import {DownloadOutlined} from "@ant-design/icons";
import AuthBloc from "../../bloc/AuthBloc";
import {BASEURL} from "../../utils/Constants";
import {getApiListExport} from "../../utils/RouterUtils";
import Utils, {prepareFilterParamsWithDate, prepareUrlWithFilter} from "../../utils/Utils";
import {getToken} from "../../utils/AxiosWrapper";
import JsFileDownloader from 'js-file-downloader';
import moment from "moment";

export class ButtonExport extends React.Component {

    render() {
        const {search, name, param} = this.props;

        const query = new URLSearchParams(search);

        const from = query.get('from');
        const to = query.get('to');
        let dateRange = Utils.getDefaultDateRange(from, to)

        //console.log_sms(to)

        let filter = prepareUrlWithFilter(search, param)
        if(AuthBloc.instance.isTM()){
            filter = filter + "territory_id=" +  AuthBloc.instance.getUser().territory_manager.territories[0].territory_id;
        }
        if(dateRange){
            filter = prepareFilterParamsWithDate(filter, dateRange);
        }

        return AuthBloc.instance.isAdmin() || AuthBloc.instance.isTM() ? (
            <div style={{width:120}}>
                {/*<a href={BASEURL+ getApiListExport(name, filter)}>*/}
                    <ButtonX
                        onClick={()=>{

                            new JsFileDownloader({
                                url: BASEURL+ getApiListExport(name, filter),
                                headers: [
                                    { name: 'Authorization', value: `Bearer ${getToken()}` }
                                ],
                                nameCallback: function(text) {
                                    return name +"-"+moment(dateRange.from).format("MM/DD")+"-"+
                                        moment(dateRange.to).format("MM/DD") + ".csv";
                                }
                            })
                                .then(function () {
                                    // Called when download ended
                                })
                                .catch(function (error) {
                                    // Called when an error occurred
                                });

                        }}
                        iconRight={<DownloadOutlined />}
                        name="create"
                        text="Export"
                        className={"button-default-accent"}/>
                {/*</a>*/}
            </div>
        ): <div/>;
    }
}