import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const program_ownerQueryFilterParams = () => program_ownerFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const program_ownerFilterParams = [
  FilterInputItems.id,
  FilterInputItems.name,
  FilterInputItems.email,
  FilterInputItems.root_cause_type_id,
  FilterInputItems.status,
];

export const program_ownerListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Role',
    field: 'role',
    type: tableContentType.TEXT,
  },
  {
    title: 'email',
    field: 'email',
    type: tableContentType.TEXT,
  },
  {
    title: 'Root Cause Type',
    field: 'root_cause_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Call Type',
    field: 'call_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Complain Type',
    field: 'complain_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Category',
    field: 'category_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const program_ownerDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Role',
    field: 'role',
    type: tableContentType.TEXT,
  },
  {
    title: 'email',
    field: 'email',
    type: tableContentType.TEXT,
  },
  {
    title: 'Root Cause Type',
    field: 'root_cause_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Call Type',
    field: 'call_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Complain Type',
    field: 'complain_type_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Category',
    field: 'category_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

