import React from 'react';
import {Column} from '@ant-design/charts';
import {Card, Col, Tag} from "antd";
import {Box} from "./Box";
import RedirectBloc from "../../bloc/RedirectBloc";

export class BarChartStacked extends React.Component {
    render() {
        const {header, data} = this.props;

        let x = []
        if(data){

                data.territories.forEach(territory => {

                    territory.towns.forEach(town => {
                        x.push({link: town.link, territory: territory.territory.title, town: town.town.title, value: parseInt(town.escalated), type: 'Escalated'});
                        x.push({link: town.link, territory: territory.territory.title, town: town.town.title, value: parseInt(town.pending), type: 'Pending'});
                        x.push({link: town.link, territory: territory.territory.title, town: town.town.title, value: parseInt(town.re_open), type: 'Re open'});
                        x.push({link: town.link, territory: territory.territory.title, town: town.town.title, value: parseInt(town.exceeded), type: 'Exceeded'});
                        x.push({link: town.link, territory: territory.territory.title, town: town.town.title, value: parseInt(town.solved), type: 'Resolved'});


                    })

                })
        }

        let config = {
            data: x,
            isStack: true,
            isGroup: true,
            xField: 'town',
            yField: 'value',
            seriesField: 'type',
            groupField: 'territory',

            label: {

                position: 'middle',
                // 'left', 'middle', 'right'
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
            },
            xAxis: {
                label: {
                    autoRotate: true,
                    autoHide: false,
                    autoEllipsis: false,
                },
            },
            onReady: (plot) => {
                plot.on('element:click', (...args) => {
                    RedirectBloc.instance.redirectTo(args[0].data.data.link);
                });
            }
        };

        return <Card><Col>
            <Tag className={"chart-tag"} color="green">{header}</Tag>
            <Box y={32}/>
            <Column {...config} />
        </Col></Card>;
    }
}

