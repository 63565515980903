import React from 'react';
import {Form, Input, InputNumber} from 'antd';
import "./FormComponent.css";

export class InputText extends React.Component {
    render() {

        const {value, disabled = false} = this.props;
        const {name, label, placeholder, rules, prefixIcon, type = "text"} = this.props.values;
        
        return (
            <Form.Item
                label={label}
                name={name}
                rules={rules}
                initialValue={value === "NULL" ? undefined : value}
            >
                {type === 'number' ?<InputNumber style={{ width: '100%' }} autoComplete="none" prefix={prefixIcon} placeholder={placeholder} disabled  = {disabled}/>:
                <Input autoComplete="none" prefix={prefixIcon} placeholder={placeholder} type={type} disabled  = {disabled}/>}
            </Form.Item>
        );
    }
}
