import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {territory_managerDetailsAttributes} from "./TerritoryManagerConstants";
import {MenuNames} from "../../utils/Constants";

export class TerritoryManagerDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("TerritoryManager Details")
        CrudBloc.instance.getDetails(id, MenuNames.territory_manager.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.territory_manager}
            builder = {(snapshot) => {
                
                let details = snapshot.data;
                return  <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={details ?? null}
                    isFetching={false}
                    isFailed={false}
                    attributes={territory_managerDetailsAttributes}
                    name={MenuNames.territory_manager.lower}
                />
            }}/>
    }
}

