import React from "react";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Col, Row, Skeleton} from "antd";
import {ComplaintCount} from "./ComplaintCount";
import {TextX} from "../shared/TextX";
import {Box} from "../shared/Box";
import BlocBuilder from "bloc-builder-react/src";
import ComplaintBloc from "../../bloc/ComplaintBloc";
import {PieChart} from "../shared/PieChart";
import {BarChart} from "../shared/BarChart";
import AuthBloc from "../../bloc/AuthBloc";
import {Link} from "react-router-dom";
import {BarChartStacked} from "../shared/BarChartStacked";
import {BarChartStackedComplaintType} from "../shared/BarChartStackedComplaintType";
import RedirectBloc from "../../bloc/RedirectBloc";

export class HomePage extends React.Component {

    selectedYear = new Date().getFullYear().toString();
    today = new Date();
    lastDayOfMonth = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
    monthStartDate = `${this.selectedYear}-${this.today.getMonth() + 1}-1T00:00:00Z`;
    monthEndDate = `${this.selectedYear}-${this.today.getMonth() + 1}-${this.lastDayOfMonth.getDate()}T23:59:59Z`;

    componentWillUnmount() {
        this.redirection?.unsubscribe();
    }

    componentDidMount() {

        SettingsBloc.instance.setCurrentPageTitle("Home");
        const history = this.props.history;
        this.redirection = RedirectBloc.instance.link.subscribe({

            next(x) {
                if (x){
                    history.push(x);
                    RedirectBloc.instance.clear();

                }
            },
            error(err) {
                console.error('something wrong occurred: ' + err);
            },
            complete() {
                console.log('done');
            }
        });



        if(AuthBloc.instance.isRM()) {
            ComplaintBloc.instance.getComplaintStatisticsByRegion(
                AuthBloc.instance.getUser().region_manager.region_id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );

            ComplaintBloc.instance.getComplaintStatisticsByRegion(
                AuthBloc.instance.getUser().region_manager.region_id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );


            ComplaintBloc.instance.getComplaintStatisticsTownByRegion(
                AuthBloc.instance.getUser().region_manager.region_id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsTownByRegion(
                AuthBloc.instance.getUser().region_manager.region_id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );

            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `region_id=${AuthBloc.instance.getUser().region_manager.region_id}`,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `region_id=${AuthBloc.instance.getUser().region_manager.region_id}`,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
        }
        if(AuthBloc.instance.isAM()) {
            ComplaintBloc.instance.getComplaintStatisticsByArea(
                AuthBloc.instance.getUser().area_manager.area_id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByArea(
                AuthBloc.instance.getUser().area_manager.area_id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
            ComplaintBloc.instance.getComplaintStatisticsTownByArea(
                AuthBloc.instance.getUser().area_manager.area_id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsTownByArea(
                AuthBloc.instance.getUser().area_manager.area_id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );

            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `area_id=${AuthBloc.instance.getUser().area_manager.area_id}`,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `area_id=${AuthBloc.instance.getUser().area_manager.area_id}`,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
        }
        if(AuthBloc.instance.isTM()) {
            ComplaintBloc.instance.getComplaintStatisticsByTerritory(
                AuthBloc.instance.getUser().territory_manager.territories[0].territory_id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByTerritory(
                AuthBloc.instance.getUser().territory_manager.territories[0].territory_id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
            ComplaintBloc.instance.getComplaintStatisticsTownByTerritory(
                AuthBloc.instance.getUser().territory_manager.territories[0].territory_id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsTownByTerritory(
                AuthBloc.instance.getUser().territory_manager.territories[0].territory_id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );

            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `territory_id=${AuthBloc.instance.getUser().territory_manager.territories[0].territory_id}`,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `territory_id=${AuthBloc.instance.getUser().territory_manager.territories[0].territory_id}`,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
        }
        if(AuthBloc.instance.isPO()) {
            ComplaintBloc.instance.getComplaintStatisticsByProgramOwner(
                AuthBloc.instance.getUser().program_owner.id,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByProgramOwner(
                AuthBloc.instance.getUser().program_owner.id,
                this.monthStartDate,
                this.monthEndDate,
                false
            );

            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `program_owner_id=${AuthBloc.instance.getUser().program_owner.id}`,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                `program_owner_id=${AuthBloc.instance.getUser().program_owner.id}`,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
        }
        if(AuthBloc.instance.isEditor()) {
            ComplaintBloc.instance.getComplaintStatistics();
            ComplaintBloc.instance.getComplaintStatisticsRegion();
            ComplaintBloc.instance.getComplaintStatisticsArea();
            ComplaintBloc.instance.getComplaintStatisticsCallType();

            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                ``,
                `${this.selectedYear}-1-1T00:00:00Z`,
                `${this.selectedYear}-12-31T23:59:59Z`,
                true
            );
            ComplaintBloc.instance.getComplaintStatisticsByComplaintsType(
                ``,
                this.monthStartDate,
                this.monthEndDate,
                false
            );
        }

    }

    render() {
        return <div style={{width: "99%"}}>

            {AuthBloc.instance.isEditor() &&
                <BlocBuilder
                subject={ComplaintBloc.instance.complaintCount}
                builder={(snapshot) => {
                    let year;
                    let month;
                    let day;
                    if (snapshot.data) {
                        year = snapshot.data.data.year.value;
                        month = snapshot.data.data.month.value;
                        day = snapshot.data.data.day.value;
                    }

                    return snapshot.data != null ? <div>

                        <CountsRow title={`All Complaints (YTD ${this.selectedYear})`} data={year}/>
                        <CountsRow title={`All Complaints (MTD ${this.selectedYear})`} data={month}/>
                        <CountsRow title={`All Complaints (Last 3 Days)`} data={day}/>

                    </div> : <Skeleton/>
                }}/>
            }
            {AuthBloc.instance.isTM() &&
                <div>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountTerritoryYTD}
                        builder={(snapshot) => {
                            let year;

                            if (snapshot.data) {
                                year = snapshot.data.data;

                            }

                            return snapshot.data != null ? <div>

                                <CountsRow title={`All Complaints (YTD ${this.selectedYear})`} data={year}/>


                            </div> : <Skeleton/>
                        }}/>
                    <Box y={32}/>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountTerritoryMTD}
                        builder={(snapshot) => {
                            let year;

                            if (snapshot.data) {
                                year = snapshot.data.data;

                            }

                            return snapshot.data != null ? <div>

                                <CountsRow title={`All Complaints (MTD ${monthNames[this.today.getMonth()]})`} data={year}/>


                            </div> : <Skeleton/>
                        }}/>

                    <Box y={32}/>
                    <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Towns (YTD ${this.selectedYear})`} size={20}/></Row>
                    <Box y={32}/>
                    <Row type={'flex'} gutter={16}>
                        <Col span={24}>
                            <BlocBuilder
                                subject={ComplaintBloc.instance.complaintTownByTMy}
                                builder={(snapshot) => {
                                    return snapshot?.data?.data != null ? <div>
                                            <BarChartStacked  data={ {territories: snapshot?.data?.data} }/>
                                        </div>
                                        : <Skeleton/>
                                }}/></Col>
                    </Row>
                    <Box y={32}/>
                    <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Towns (MTD ${monthNames[this.today.getMonth()]})`} size={20}/></Row>
                    <Box y={32}/>
                    <Row type={'flex'} gutter={16}>
                        <Col span={24}>
                            <BlocBuilder
                                subject={ComplaintBloc.instance.complaintTownByTMm}
                                builder={(snapshot) => {
                                    return snapshot?.data?.data != null ? <div>
                                            <BarChartStacked  data={ {territories: snapshot?.data?.data} }/>
                                        </div>
                                        : <Skeleton/>
                                }}/></Col>
                    </Row>

                </div>

            }
            {AuthBloc.instance.isRM() &&
                <div>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountRegionYTD}
                        builder={(snapshot) => {

                            return snapshot.data != null ? <div>
                                <Row type={'flex'} justify={'center'}><TextX text={`All Complaints (YTD ${this.selectedYear})`} size={20}/></Row>
                                <Box y={32}/>
                                {snapshot.data.data.map((e) => <CountsRow title={e.area_manager.area.title} data={e}/> )}


                            </div> : <Skeleton/>
                        }}/>
                    <Box y={32}/>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountRegionMTD}
                        builder={(snapshot) => {

                            return snapshot.data != null ? <div>
                                <Row type={'flex'} justify={'center'}><TextX text={`All Complaints (MTD ${monthNames[this.today.getMonth()]})`} size={20}/></Row>
                                <Box y={32}/>
                                {snapshot.data.data.map((e) => <CountsRow title={e.area_manager.area.title} data={e}/> )}


                            </div> : <Skeleton/>
                        }}/>
                    <Box y={32}/>
                    <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Towns (YTD ${this.selectedYear})`} size={20}/></Row>
                    <Box y={32}/>
                    <Row type={'flex'} gutter={16}>
                        <Col span={24}>
                            <BlocBuilder
                                subject={ComplaintBloc.instance.complaintTownByRMy}
                                builder={(snapshot) => {
                                    return snapshot?.data?.data != null ? <div>
                                            {snapshot?.data?.data.map((area) => <BarChartStacked header={`${area.area.title}`} data={area}/>)}
                                        </div>
                                        : <Skeleton/>
                                }}/></Col>
                    </Row>
                    <Box y={32}/>
                    <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Towns (MTD ${monthNames[this.today.getMonth()]})`} size={20}/></Row>
                    <Box y={32}/>
                    <Row type={'flex'} gutter={16}>
                        <Col span={24}>
                            <BlocBuilder
                                subject={ComplaintBloc.instance.complaintTownByRMm}
                                builder={(snapshot) => {
                                    return snapshot?.data?.data != null ? <div>
                                            {snapshot?.data?.data.map((area) => <BarChartStacked header={`${area.area.title}`} data={area}/>)}
                                        </div>
                                        : <Skeleton/>
                                }}/></Col>
                    </Row>
                </div>

            }

            {AuthBloc.instance.isAM() &&
                <div>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountAreaYTD}
                        builder={(snapshot) => {

                            return snapshot.data != null ? <div>
                                <Row type={'flex'} justify={'center'}><TextX text={`All Complaints (YTD ${this.selectedYear})`} size={20}/></Row>
                                <Box y={32}/>
                                {snapshot.data.data.map((e) => <CountsRow title={e.territory.title} data={e}/> )}
                            </div> : <Skeleton/>
                        }}/>
                    <Box y={32}/>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountAreaMTD}
                        builder={(snapshot) => {

                            return snapshot.data != null ? <div>
                                <Row type={'flex'} justify={'center'}><TextX text={`All Complaints (MTD ${monthNames[this.today.getMonth()]})`} size={20}/></Row>
                                <Box y={32}/>
                                {snapshot.data.data.map((e) => <CountsRow title={e.territory.title} data={e}/> )}
                            </div> : <Skeleton/>
                        }}/>

                    <Box y={32}/>
                    <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Towns (YTD ${this.selectedYear})`} size={20}/></Row>
                    <Box y={32}/>
                    <Row type={'flex'} gutter={16}>
                        <Col span={24}>
                            <BlocBuilder
                                subject={ComplaintBloc.instance.complaintTownByAMy}
                                builder={(snapshot) => {
                                    return snapshot?.data?.data != null ? <div>
                                             <BarChartStacked  data={ {territories: snapshot?.data?.data} }/>
                                        </div>
                                        : <Skeleton/>
                                }}/></Col>
                    </Row>
                    <Box y={32}/>
                    <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Towns (MTD ${monthNames[this.today.getMonth()]})`} size={20}/></Row>
                    <Box y={32}/>
                    <Row type={'flex'} gutter={16}>
                        <Col span={24}>
                            <BlocBuilder
                                subject={ComplaintBloc.instance.complaintTownByAMm}
                                builder={(snapshot) => {
                                    return snapshot?.data?.data != null ? <div>
                                            <BarChartStacked  data={ {territories: snapshot?.data?.data} }/>
                                        </div>
                                        : <Skeleton/>
                                }}/></Col>
                    </Row>

                </div>

            }
            {AuthBloc.instance.isPO() &&
                <div>
                <BlocBuilder
                    subject={ComplaintBloc.instance.complaintCountProgramOwnerYTD}
                    builder={(snapshot) => {
                        let year;

                        if (snapshot.data) {
                            year = snapshot.data.data;

                        }

                        return snapshot.data != null ? <div>

                            <CountsRow title={`All Complaints (YTD ${this.selectedYear})`} data={year}/>


                        </div> : <Skeleton/>
                    }}/>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintCountProgramOwnerMTD}
                        builder={(snapshot) => {
                            let year;

                            if (snapshot.data) {
                                year = snapshot.data.data;

                            }

                            return snapshot.data != null ? <div>

                                <CountsRow title={`All Complaints (MTD ${monthNames[this.today.getMonth()]})`} data={year}/>


                            </div> : <Skeleton/>
                        }}/>
                </div>
            }

            {AuthBloc.instance.isEditor() && <BlocBuilder
                subject={ComplaintBloc.instance.complaintCount}
                builder={(snapshot) => {
                    let year;
                    let month;
                    let day;
                    if (snapshot.data) {
                        year = snapshot.data.data.year.value;
                        month = snapshot.data.data.month.value;
                        day = snapshot.data.data.day.value;
                    }

                    return snapshot.data != null ? <div>

                        <Row type={'flex'} gutter={16}>
                            <Col span={8}>
                                <PieChart title={`Complaints (YTD ${this.selectedYear})`} counts={year}/>
                            </Col>
                            <Col span={16}>
                                <BlocBuilder
                                    subject={ComplaintBloc.instance.complaintCountAllRegion}
                                    builder={(snapshot) => {
                                        let year;
                                        //let month;
                                        //let day;
                                        if (snapshot.data) {
                                            year = snapshot.data.data.year;
                                            //month = snapshot.data.data.month;
                                            //day = snapshot.data.data.day;
                                        }

                                        return snapshot.data != null ? <div>


                                            <BarChart header={`Complaints by Region (YTD ${this.selectedYear})`} data={year}/>
                                        </div> : <Skeleton/>
                                    }}/></Col>
                        </Row>
                        <Box y={16}/>
                        <Row type={'flex'} gutter={16}>
                            <Col span={8}>
                                <PieChart title={`Complaints (MTD ${this.selectedYear})`} counts={month}/>
                            </Col>
                            <Col span={16}>
                                <BlocBuilder
                                    subject={ComplaintBloc.instance.complaintCountAllRegion}
                                    builder={(snapshot) => {
                                        //let year;
                                        let month;
                                        //let day;
                                        if (snapshot.data) {
                                            //year = snapshot.data.data.year;
                                            month = snapshot.data.data.month;
                                            //day = snapshot.data.data.day;
                                        }

                                        return snapshot.data != null ? <div>

                                            <BarChart header={`Complaints by Region (MTD ${this.selectedYear})`} data={month}/>
                                        </div> : <Skeleton/>
                                    }}/></Col>
                        </Row>
                        <Box y={16}/>
                        <Row type={'flex'} gutter={16}>
                            <Col span={8}>
                                <PieChart title={"Complaints (Last 3 Days)"} counts={day}/>
                            </Col>

                            <Col span={16}>
                                <BlocBuilder
                                    subject={ComplaintBloc.instance.complaintCountAllRegion}
                                    builder={(snapshot) => {
                                        //let year;
                                        //let month;
                                        let day;
                                        if (snapshot.data) {
                                            //year = snapshot.data.data.year;
                                            //month = snapshot.data.data.month;
                                            day = snapshot.data.data.day;
                                        }

                                        return snapshot.data != null ? <div>


                                            <BarChart header={"Complaints by Region (Last 3 Days)"} data={day}/>
                                        </div> : <Skeleton/>
                                    }}/></Col>
                        </Row>
                    </div> : <Skeleton/>
                }}/>}

            {AuthBloc.instance.isEditor() && <BlocBuilder
                subject={ComplaintBloc.instance.complaintCountAllArea}
                builder={(snapshot) => {
                    let year;
                    let month;
                    let day;
                    if (snapshot.data) {
                        year = snapshot.data.data.year;
                        month = snapshot.data.data.month;
                        day = snapshot.data.data.day;
                    }

                    return snapshot.data != null ? <div>

                        <Box y={16}/>
                        <BarChart header={`Complaints by Area (YTD ${this.selectedYear})`} data={year}/>
                        <Box y={16}/>
                        <BarChart header={`Complaints by Area (MTD ${this.selectedYear})`} data={month}/>
                        <Box y={16}/>
                        <BarChart header={"Complaints by Area (Last 3 Days)"} data={day}/>
                    </div> : <Skeleton/>
                }}/>}
            {AuthBloc.instance.isEditor() && <BlocBuilder
                subject={ComplaintBloc.instance.complaintCountCallType}
                builder={(snapshot) => {
                    let year;
                    let month;
                    let day;
                    if (snapshot.data) {
                        year = snapshot.data.data.year;
                        month = snapshot.data.data.month;
                        day = snapshot.data.data.day;
                    }

                    return snapshot.data != null ? <div>

                        <Box y={16}/>
                        <BarChart header={`Complaints by Call Type (YTD ${this.selectedYear})`} data={year}/>
                        <Box y={16}/>
                        <BarChart header={`Complaints by Call Type (MTD ${this.selectedYear})`} data={month}/>
                        <Box y={16}/>
                        <BarChart header={"Complaints by Call Type (Last 3 Days)"} data={day}/>
                    </div> : <Skeleton/>
                }}/>}

            <Box y={32}/>
            <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Complaint Type (YTD ${this.selectedYear})`} size={20}/></Row>
            <Box y={32}/>
            <Row type={'flex'} gutter={16}>
                <Col span={24}>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintByComplaintsTypeYTD}
                        builder={(snapshot) => {
                            return snapshot?.data?.data != null ? <div>
                                    {snapshot?.data?.data.map((a) => <BarChartStackedComplaintType key={a.title} header={`${a.title}`} data={a}/>)}
                                </div>
                                : <Skeleton/>
                        }}/></Col>
            </Row>

            <Box y={32}/>
            <Row type={'flex'} justify={'center'}><TextX text={`Complaints by Complaint Type (MTD ${monthNames[this.today.getMonth()]})`} size={20}/></Row>
            <Box y={32}/>
            <Row type={'flex'} gutter={16}>
                <Col span={24}>
                    <BlocBuilder
                        subject={ComplaintBloc.instance.complaintByComplaintsTypeMTD}
                        builder={(snapshot) => {
                            return snapshot?.data?.data != null ? <div>
                                    {snapshot?.data?.data.map((a) => <BarChartStackedComplaintType key={a.title} header={`${a.title}`} data={a}/>)}
                                </div>
                                : <Skeleton/>
                        }}/></Col>
            </Row>
        </div>
    }
}

function CountsRow ({title, data}) {
    return <div>
        <Row type={'flex'} justify={'center'}><TextX text={title} size={20}/></Row>
        <Box y={16}/>
        <Row type={'flex'} justify={'center'} gutter={16}>
            <Col>
                <Link key={"1-link"} to={"/complaint/list?status=1"}><ComplaintCount status={1} count={(data.pending ?? 0)}/></Link>
            </Col>
            <Col>
                <Link key={"1-link"} to={"/complaint/list?status=2"}><ComplaintCount status={2} count={(data.re_open ?? 0)}/></Link>
            </Col>
            <Col>
                <Link key={"1-link"} to={"/complaint/list?status=4"}><ComplaintCount status={3} count={data.exceeded ?? 0}/></Link>
            </Col>
            <Col>
                <Link key={"1-link"} to={"/complaint/list?status=5"}><ComplaintCount status={4} count={data.escalated ?? 0}/></Link>
            </Col>
            <Col>
                <Link key={"1-link"} to={"/complaint/list?status=3"}><ComplaintCount status={5} count={data.resolved ?? data.solved ?? 0}/></Link>
            </Col>
            <Col>
                <ComplaintCount status={6} count={(data.pending ?? 0) + (data.re_open ?? 0) + (data.exceeded ?? 0) + (data.escalated ?? 0) + (data.resolved ?? data.solved ?? 0)}/>
            </Col>
        </Row>
        <Box y={16}/>
    </div>
}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];