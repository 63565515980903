import React from "react";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuNames} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {areaFilterParams} from "../area/AreaConstants";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {FormSkeleton} from "../shared/FormSkeleton";
import {territoryFilterParams} from "../territory/TerritoryConstants";
import {getId} from "../../utils/Utils";
import {IsLoading} from "../shared/IsLoading";
import {categoryFilterParams} from "../category/CategoryConstants";
import {brandFilterParams} from "../brand/BrandConstants";

export class ProductSKUCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            title: InputFields.title,
            category_id: InputFields.category_id,
            brand_id: InputFields.brand_id,
            status: InputFields.status
        };

        if(this.props.edit){
            this.prepareEdit()
        }else{
            this.prepareCreate()
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuNames.category.lower, categoryFilterParams);
        CrudBloc.instance.clearList(MenuNames.brand.lower);
    }

    prepareEdit = () => {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("ProductSKU Update");
        this.product_skuSubcription = CrudBloc.instance.product_sku.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.area.lower);
                    CrudBloc.instance.getList({region_id: getId(x, InputFields.region_id.name ), status: 1, limit: 100}, MenuNames.area.lower, areaFilterParams);
                    CrudBloc.instance.getList({area_id: getId(x, InputFields.area_id.name ), status: 1, limit: 100}, MenuNames.territory.lower, territoryFilterParams);
                    LocationBloc.instance.onSelectLocation(MenuNames.region.upper, x.region_id);
                    LocationBloc.instance.onSelectLocation(MenuNames.area.upper, x.area_id);

                }
            },
        });
        CrudBloc.instance.getDetails(id, MenuNames.product_sku.lower);
    }

    prepareCreate = () => {
        CrudBloc.instance.clearDetails(MenuNames.product_sku.lower);
        SettingsBloc.instance.setCurrentPageTitle("Create new ProductSKU");
        const history = this.props.history;
        this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
            next(x) {
                if (x){
                    history.push(getRouteList(MenuNames.product_sku.lower));
                    CrudBloc.instance.clearCreateResponseData();
                }
            },
        });
    }

    componentDidMount() {

        this.selectCategorySubscription = LocationBloc.instance.selectedCategory.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuNames.brand.lower);
                    CrudBloc.instance.getList({category_id: x, status: 1, limit: 100}, MenuNames.brand.lower, brandFilterParams)
                }
            },
        });


    }


    componentWillUnmount() {
        
        this.product_skuSubcription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectCategorySubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        delete values["region_id"];
        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, MenuNames.product_sku.lower);
        }else{
            CrudBloc.instance.createNew(values, MenuNames.product_sku.lower);
        }

    };

    render() {
        return (
            <IsLoading name={MenuNames.product_sku.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.product_sku}
                    builder = {(snapshot) => {
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText
                                values={this.state.title}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : null}/>
                            <BlocBuilder
                                subject = {CrudBloc.instance.categoryList}
                                builder = {(snapshotCategory) => {
                                    console.log(snapshotCategory.data);
                                    return  <InputSelect
                                        values={this.state.category_id}
                                        options={snapshotCategory.data ? snapshotCategory.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.category_id.name] : undefined}
                                        onSelect={(id)=> {
                                            LocationBloc.instance.onSelectLocation(MenuNames.category.upper, id);
                                            this.formRef.current?.setFieldsValue({ brand_id: undefined});
                                        }}
                                    />
                                }}/>

                            <BlocBuilder
                                subject = {CrudBloc.instance.brandList}
                                builder = {(snapshotBrand) => {
                                    return  <InputSelect
                                        values={this.state.brand_id}
                                        options={snapshotBrand.data ? snapshotBrand.data.list : []}
                                        value={this.props.edit && snapshot.data ? getId(snapshot.data, this.state.brand_id.name ) : null}
                                    />
                                }}/>

                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : null}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log_sms(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}