import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const townQueryFilterParams = () => townFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const townFilterParams = [
  FilterInputItems.id,
  FilterInputItems.title,
  FilterInputItems.region_id,
  FilterInputItems.area_id,
  FilterInputItems.territory_id,
  FilterInputItems.town_dms_code,
  FilterInputItems.distributor_type_id,
  FilterInputItems.status,
];

export const townListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Title',
    field: 'title',
    type: tableContentType.TEXT,
  },
  {
    title: 'DMS Code',
    field: 'town_dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  // {
  //   title: 'Distributor Type',
  //   field: 'distributor_type_id',
  //   type: tableContentType.TEXT,
  // },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Action',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

export const townDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Title',
    field: 'title',
    type: tableContentType.TEXT,
  },
  {
    title: 'DMS Code',
    field: 'dms_code',
    type: tableContentType.TEXT,
  },
  {
    title: 'Region',
    field: 'region_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Area',
    field: 'area_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Territory',
    field: 'territory_title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Distributor Type',
    field: 'distributor_type',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

