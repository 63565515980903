import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {outletDetailsAttributes} from "./OutletConstants";
import {MenuNames} from "../../utils/Constants";
import {Tabs} from "antd";
import {program_top_gunListAttributes} from "../program_top_gun/ProgramTopGunConstants";
import {TableList} from "../shared/table/TableList";
import {program_dostiListAttributes} from "../program_dosti/ProgramDostiConstants";
import {program_perfect_storeListAttributes} from "../program_perfect_store/ProgramPerfectStoreConstants";
import './outlet.css'
import {complaintListAttributes} from "../complaint/ComplaintConstants";

const { TabPane } = Tabs;

export class OutletDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("Outlet Details")
        CrudBloc.instance.getDetails(id, MenuNames.outlet.lower);
        CrudBloc.instance.getComplaintsBy(id, MenuNames.outlet.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.outlet}
            builder = {(snapshot) => {
                console.log(snapshot?.data?.top_gun);
                let details = snapshot.data;
                return  <div className="card-container">
                    <Tabs type="card">
                        <TabPane tab="Outlet Details" key="1">
                            <div><TableDetails
                                size={"small"}
                                history={this.props.history}
                                location={this.props.location}
                                data={details}
                                isFetching={false}
                                isFailed={false}
                                attributes={outletDetailsAttributes}
                                name={MenuNames.outlet.lower}
                            /></div>
                        </TabPane>
                        <TabPane tab="Top Gun" key="2">
                            <TableList
                                history={this.props.history}
                                location={this.props.location}
                                list={ snapshot?.data?.top_gun ?? []}
                                pagination={false}
                                attributes={program_top_gunListAttributes}
                                // name={MenuNames.program_top_gun.lower}
                            />
                        </TabPane>
                        <TabPane tab="Perfect Store" key="3">
                            <TableList
                                history={this.props.history}
                                location={this.props.location}
                                list={ snapshot?.data?.perfect_store ?? []}
                                pagination={false}
                                attributes={program_perfect_storeListAttributes}
                                // name={MenuNames.program_top_gun.lower}
                            />
                        </TabPane>
                        <TabPane tab="Dosti" key="4">
                            <TableList
                                history={this.props.history}
                                location={this.props.location}
                                list={ snapshot?.data?.dosti ?? []}
                                pagination={false}
                                attributes={program_dostiListAttributes}
                                // name={MenuNames.program_top_gun.lower}
                            />
                        </TabPane>
                        <TabPane tab="Complaint History" key="5">
                            <BlocBuilder
                                subject = {CrudBloc.instance.complaintList}
                                builder = {(snapshotComplaint) => {
                                    console.log(snapshotComplaint)
                                return <TableList
                                    history={this.props.history}
                                    location={this.props.location}
                                    list={ snapshotComplaint?.data?.list ?? []}
                                    pagination={false}
                                    attributes={complaintListAttributes}
                                    name={MenuNames.complaint.lower}
                                />
                                }}/>
                        </TabPane>
                    </Tabs>
                </div>
            }}/>
    }
}

