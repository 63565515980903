import React from 'react';
import {Column} from '@ant-design/charts';
import {Card, Col, Tag} from "antd";
import {Box} from "./Box";
import {Link} from "react-router-dom";
import {getRouteList} from "../../utils/RouterUtils";
import {MenuNames} from "../../utils/Constants";
import SettingsBloc from "../../bloc/SettingsBloc";
import RedirectBloc from "../../bloc/RedirectBloc";

export class BarChart extends React.Component {
    render() {
        const {header, data} = this.props;

        let x = []
        if(data){
            data.forEach(e => {
                x.push({link: e.link ,series: e.title, x: 'Total', y: e.value.pending + e.value.re_open + e.value.escalated + e.value.exceeded + e.value.resolved})
                x.push({link: e.link ,series: e.title, x: 'Closed', y: e.value.resolved})
                x.push({link: e.link ,series: e.title, x: 'Pending', y: e.value.pending + e.value.re_open + e.value.escalated + e.value.exceeded})
            });
        }

        let config = {
            data: x,
            isGroup: true,
            xField: 'series',
            yField: 'y',
            seriesField: 'x',
            label: {
                position: 'top',
                layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                ],
            },
            xAxis: {
                label: {
                    autoRotate: true,
                    autoHide: false,
                    autoEllipsis: false,
                },
            },
            yAxis: {
                label: {
                    autoRotate: true,
                    autoHide: false,
                    autoEllipsis: false,
                },
            },
            onReady: (plot) => {
                plot.on('element:click', (...args) => {
                    RedirectBloc.instance.redirectTo(args[0].data.data.link);
                });
            }
        };

        return <Card><Col>
            <Tag className={"chart-tag"} color="green">{header}</Tag>
            <Box y={32}/>
            <Column {...config} />
        </Col></Card>;
    }
}

