import {Link} from 'react-router-dom'
import React, {Component} from 'react';
import './LeftSideBar.css';
import {getRouteList, routeHome} from '../../../utils/RouterUtils';
import {Layout, Menu} from "antd";
import {HomeOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../../bloc/SettingsBloc";
import AuthBloc from "../../../bloc/AuthBloc";
import {MenuNames} from "../../../utils/Constants";
import Images from "../../../utils/Images";

const { SubMenu } = Menu;
const { Sider } = Layout;


class LeftSideBar extends Component {

    constructor(props) {
        super(props);
        this.setMenuAndRoutes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setMenuAndRoutes();
    }

    setMenuAndRoutes = () => {
        const path = this.props.location.pathname;
        Object.keys(routes).forEach(function(key, idx) {
            if(path.split("/")[1] === (routes[key].key)){
                SettingsBloc.instance.setCurrentRoute(routes[key]);
                SettingsBloc.instance.setCurrentMenu(menus[routes[key].menu]);
            }
        });
    }

    onOpenChange = keys => {
        SettingsBloc.instance.setCurrentMenu(menus[keys[1]])
    };

    render() {
        return (
            <BlocBuilder
                subject={SettingsBloc.instance.sideBarCollapsed}
                builder={(snapshot) => {
                    return <BlocBuilder
                        subject={SettingsBloc.instance.currentRoute}
                        builder={(snapshotRoute) => {
                            return <BlocBuilder
                                subject={SettingsBloc.instance.currentMenu}
                                builder={(snapshotMenu) => {
                                    return <Sider trigger={null} collapsible collapsed={snapshot.data}>
                                        <Link key={"home-link"} to={"/home"}>
                                            <div className="side-logo">

                                                <img
                                                    style={{marginRight: 32, filter: "brightness(0) invert(1)"}}
                                                    width={40}
                                                    src={Images.logoUnilever}
                                                />
                                                <img
                                                    width={80}
                                                    src={Images.logoTCL}
                                                />
                                            </div>
                                        </Link>
                                        <Menu
                                            theme="dark"
                                            mode="inline"
                                            selectedKeys={[snapshotRoute?.data?.key]}
                                            defaultSelectedKeys={[snapshotRoute?.data?.key]}
                                            defaultOpenKeys={[snapshotRoute?.data?.menu]}
                                            openKeys={[snapshotMenu?.data?.key]}
                                            onOpenChange={this.onOpenChange}
                                        >
                                            {this.prepareMenu(routes.home)}
                                            {AuthBloc.instance.isEditor() && this.prepareMenu(routes.complaint_create)}
                                            {this.prepareMenu(routes.complaint)}
                                            {AuthBloc.instance.isEditor() && this.prepareMenuWithSubMenu(menus.payouts)}
                                            {AuthBloc.instance.isEditor() && this.prepareMenu(routes.outlet)}
                                            {AuthBloc.instance.isAdmin() && this.prepareMenuWithSubMenu(menus.users)}
                                            {AuthBloc.instance.isEditor() && this.prepareMenuWithSubMenu(menus.locations)}
                                            {AuthBloc.instance.isEditor() && this.prepareMenuWithSubMenu(menus.managers)}
                                            {AuthBloc.instance.isEditor() && this.prepareMenuWithSubMenu(menus.items)}
                                            {AuthBloc.instance.isAdmin() && this.prepareMenuWithSubMenu(menus.logs)}
                                            {/*{AuthBloc.instance.isEditor() && this.prepareMenu(routes.outbound_call)}*/}

                                            <Menu.Item key={"logout"} icon={<LogoutOutlined/>}
                                                       onClick={() => AuthBloc.instance.logout()}>
                                                {"Logout"}
                                            </Menu.Item>
                                        </Menu>
                                    </Sider>
                                }}/>
                        }}/>
                }}/>
        )
    }

    prepareSubMenu = (item) => {
        return <Menu.Item key={item.key}>
            <Link key={item.key+"-link"} to={item.to}>{item.label}</Link>
        </Menu.Item>
    };

    prepareMenu = (item) => {
        return <Menu.Item key={item.key} icon={item.icon}>
            <Link key={item.key+"-link"} to={item.to}>{item.label}</Link>
        </Menu.Item>
    };

    prepareMenuWithSubMenu = (item) => {
        return  <SubMenu key={item.key} icon={item.icon} title={item.label}>
            {
                item.submenu.map(e => this.prepareSubMenu(e))
            }
        </SubMenu>
    };
}

export default LeftSideBar;
const routes = {
    home: {key: MenuNames.home.lower, label: MenuNames.home.upper, to: routeHome, icon: <HomeOutlined />},

    region: {key: MenuNames.region.lower, label: MenuNames.region.upper, to: getRouteList(MenuNames.region.lower), icon: <HomeOutlined />, menu: "locations"},
    area: {key: MenuNames.area.lower, label: MenuNames.area.upper, to: getRouteList(MenuNames.area.lower), icon: <HomeOutlined />, menu: "locations"},
    town: {key: MenuNames.town.lower, label: MenuNames.town.upper, to: getRouteList(MenuNames.town.lower), icon: <HomeOutlined />, menu: "locations"},
    thana: {key: MenuNames.thana.lower, label: MenuNames.thana.upper, to: getRouteList(MenuNames.thana.lower), icon: <HomeOutlined />, menu: "locations"},
    territory: {key: MenuNames.territory.lower, label: MenuNames.territory.upper, to: getRouteList(MenuNames.territory.lower), icon: <HomeOutlined />, menu: "locations"},

    user: {key: MenuNames.user.lower, label: MenuNames.user.upper, to: getRouteList(MenuNames.user.lower), icon: <HomeOutlined />, menu: "users"},
    user_group: {key: MenuNames.user_group.lower, label: MenuNames.user_group.upper, to: getRouteList(MenuNames.user_group.lower), icon: <HomeOutlined />, menu: "users"},

    brand: {key: MenuNames.brand.lower, label: MenuNames.brand.upper, to: getRouteList(MenuNames.brand.lower), icon: <HomeOutlined />, menu: "items"},
    call_type: {key: MenuNames.call_type.lower, label: MenuNames.call_type.upper, to: getRouteList(MenuNames.call_type.lower), icon: <HomeOutlined />, menu: "items"},
    product_sku: {key: MenuNames.product_sku.lower, label: MenuNames.product_sku.upper, to: getRouteList(MenuNames.product_sku.lower), icon: <HomeOutlined />, menu: "items"},

    category: {key: MenuNames.category.lower, label: MenuNames.category.upper, to: getRouteList(MenuNames.category.lower), icon: <HomeOutlined />, menu: "items"},
    market_channel: {key: MenuNames.market_channel.lower, label: MenuNames.market_channel.upper, to: getRouteList(MenuNames.market_channel.lower), icon: <HomeOutlined />, menu: "items"},
    root_cause_type: {key: MenuNames.root_cause_type.lower, label: MenuNames.root_cause_type.upper, to: getRouteList(MenuNames.root_cause_type.lower), icon: <HomeOutlined />, menu: "items"},
    store_type: {key: MenuNames.store_type.lower, label: MenuNames.store_type.upper, to: getRouteList(MenuNames.store_type.lower), icon: <HomeOutlined />, menu: "items"},
    complain_type: {key: MenuNames.complain_type.lower, label: MenuNames.complain_type.upper, to: getRouteList(MenuNames.complain_type.lower), icon: <HomeOutlined />, menu: "items"},
    root_cause: {key: MenuNames.root_cause.lower, label: MenuNames.root_cause.upper, to: getRouteList(MenuNames.root_cause.lower), icon: <HomeOutlined />, menu: "items"},
    distributor: {key: MenuNames.distributor.lower, label: MenuNames.distributor.upper, to: getRouteList(MenuNames.distributor.lower), icon: <HomeOutlined />, menu: "items"},

    region_manager: {key: MenuNames.region_manager.lower, label: MenuNames.region_manager.upper, to: getRouteList(MenuNames.region_manager.lower), icon: <HomeOutlined />, menu: "managers"},
    area_manager: {key: MenuNames.area_manager.lower, label: MenuNames.area_manager.upper, to: getRouteList(MenuNames.area_manager.lower), icon: <HomeOutlined />, menu: "managers"},
    territory_manager: {key: MenuNames.territory_manager.lower, label: MenuNames.territory_manager.upper, to: getRouteList(MenuNames.territory_manager.lower), icon: <HomeOutlined />, menu: "managers"},
    program_owner: {key: MenuNames.program_owner.lower, label: MenuNames.program_owner.upper, to: getRouteList(MenuNames.program_owner.lower), icon: <HomeOutlined />, menu: "managers"},

    program_dosti: {key: MenuNames.program_dosti.lower, label: MenuNames.program_dosti.upper, to: getRouteList(MenuNames.program_dosti.lower), icon: <HomeOutlined />, menu: "payouts"},
    program_top_gun: {key: MenuNames.program_top_gun.lower, label: MenuNames.program_top_gun.upper, to: getRouteList(MenuNames.program_top_gun.lower), icon: <HomeOutlined />, menu: "payouts"},
    program_perfect_store: {key: MenuNames.program_perfect_store.lower, label: MenuNames.program_perfect_store.upper, to: getRouteList(MenuNames.program_perfect_store.lower), icon: <HomeOutlined />, menu: "payouts"},
    program_timeline: {key: MenuNames.program_timeline.lower, label: MenuNames.program_timeline.upper, to: getRouteList(MenuNames.program_timeline.lower), icon: <HomeOutlined />, menu: "payouts"},

    outlet: {key: MenuNames.outlet.lower, label: MenuNames.outlet.upper, to: getRouteList(MenuNames.outlet.lower), icon: <HomeOutlined />},
    complaint: {key: MenuNames.complaint.lower, label: MenuNames.complaint.upper, to: getRouteList(MenuNames.complaint.lower), icon: <HomeOutlined />},
    complaint_create: {key: "complaint-create", label: "New Complaint", to: "/complaint-create", icon: <HomeOutlined />},
    outbound_call: {key: MenuNames.outbound_call.lower, label: MenuNames.outbound_call.upper, to: getRouteList(MenuNames.outbound_call.lower), icon: <HomeOutlined />},
    sms_log: {key: MenuNames.sms_log.lower, label: MenuNames.sms_log.upper, to: getRouteList(MenuNames.sms_log.lower), icon: <HomeOutlined />},
    notification_log: {key: MenuNames.notification_log.lower, label: MenuNames.notification_log.upper, to: getRouteList(MenuNames.notification_log.lower), icon: <HomeOutlined />},

}
const menus = {
    users: {key: "users", label: "User", icon: <UserOutlined />, submenu: [routes.user, routes.user_group]},
    managers: {key: "managers", label: "Managers", icon: <UserOutlined />, submenu: [routes.region_manager, routes.area_manager, routes.territory_manager, routes.program_owner]},
    locations: {key: "locations", label: "Locations", icon: <UserOutlined />, submenu: [routes.region, routes.area, routes.territory, routes.town, routes.thana]},
    items: {key: "items", label: "Items", icon: <UserOutlined />, submenu: [routes.category, routes.brand, routes.product_sku, routes.call_type, routes.complain_type, routes.store_type, routes.root_cause_type, routes.root_cause, routes.market_channel, routes.distributor ]},
    payouts: {key: "payouts", label: "Payout", icon: <UserOutlined />, submenu: [routes.program_dosti, routes.program_perfect_store, routes.program_top_gun, routes.program_timeline]},
    logs: {key: "logs", label: "Logs", icon: <UserOutlined />, submenu: [routes.sms_log, routes.notification_log]}
}
