import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const notification_logQueryFilterParams = () => notification_logFilterParams.reduce((acm, cur) => [...acm, cur.name], []);

export const notification_logFilterParams = [
  FilterInputItems.id,
  FilterInputItems.title,
  FilterInputItems.status,
];

export const notification_logListAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'Complaint ID',
    field: 'complaint_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Notification Type',
    field: 'type',
    type: tableContentType.TEXT,
  },
  {
    title: 'Created By',
    field: 'created_by',
    type: tableContentType.TEXT,
  },
  {
    title: 'Received By',
    field: 'user_id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Read Status',
    field: 'read_status',
    type: tableContentType.TEXT,
  },
  {
    title: 'Content',
    field: 'description',
    type: tableContentType.TEXT,
  },
  {
    title: 'Sent At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

export const notification_logDetailsAttributes = [
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Title',
    field: 'title',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.STATUS,
  },
  {
    title: 'Created At',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
];

