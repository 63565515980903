import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import SettingsBloc from "../../bloc/SettingsBloc";
import {TableDetails} from "../shared/table/TableDetails";
import {user_groupDetailsAttributes} from "./UserGroupConstants";
import {MenuNames} from "../../utils/Constants";

export class UserGroupDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.match.params;
        SettingsBloc.instance.setCurrentPageTitle("UserGroup Details")
        CrudBloc.instance.getDetails(id, MenuNames.user_group.lower);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance.user_group}
            builder = {(snapshot) => {
                
                return  <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={snapshot.data ? snapshot.data : null}
                    isFetching={false}
                    isFailed={false}
                    attributes={user_groupDetailsAttributes}
                    name={MenuNames.user_group.lower}
                />
            }}/>
    }
}

