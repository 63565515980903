import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";

import {
    notification_logFilterParams, notification_logListAttributes,
} from "./NotificationogConstants";
import {Box} from "../shared/Box";
import {MenuNames} from "../../utils/Constants";

export class NotificationLogListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("SMS Log")
        CrudBloc.instance.getList({...this.props.location.search, status:1}, MenuNames.notification_log.lower, notification_logFilterParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(this.props.location.search, MenuNames.notification_log.lower, notification_logFilterParams);
        }
    }

    render() {
        return <div>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.notification_logList}
            builder = {(snapshot) => {
                
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={notification_logListAttributes}
                    name={MenuNames.sms_log.lower}
                />
            }}/>
        </div>
    }
}

