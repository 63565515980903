import React from "react";
import CrudBloc from "../../bloc/CrudBloc";
import BlocBuilder from "bloc-builder-react/src";
import {TableList} from "../shared/table/TableList";
import SettingsBloc from "../../bloc/SettingsBloc";
import {Row} from "antd";
import FilterPopup from "../shared/Filter/FilterPopup";
import {call_typeFilterParams, call_typeListAttributes, call_typeQueryFilterParams} from "./CallTypeConstants";
import {Box} from "../shared/Box";
import {ButtonCreateNew} from "../shared/ButtonCreateNew";
import {MenuNames} from "../../utils/Constants";
import {getRouteCreate} from "../../utils/RouterUtils";
import {ButtonExport} from "../shared/ButtonExport";

export class CallTypeListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle("CallType List")
        CrudBloc.instance.getList({...this.props.location.search, status:1}, MenuNames.call_type.lower, call_typeFilterParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(this.props.location.search, MenuNames.call_type.lower, call_typeFilterParams);
        }
    }

    render() {
        return <div>
            <Row type="flex" justify={"space-between"}>
                <ButtonCreateNew to={getRouteCreate(MenuNames.call_type.lower)}/>

                <ButtonExport search={this.props.location.search} name={MenuNames.call_type.lower} param={call_typeFilterParams}/>

                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {call_typeFilterParams}
                    queryFilterParams = {call_typeQueryFilterParams()}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance.call_typeList}
            builder = {(snapshot) => {
                
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={call_typeListAttributes}
                    name={MenuNames.call_type.lower}
                />
            }}/>
        </div>
    }
}

