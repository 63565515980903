import * as rxjs from "rxjs";

export default class RedirectBloc {
    static instance = new RedirectBloc();

    constructor(){
        this.link = new rxjs.BehaviorSubject(null);
    }

    redirectTo = (value) => this.link.next(value);

    clear = () => this.link.next(null);
}